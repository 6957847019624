package main.kotlin.models.editor

import main.kotlin.data.*
import main.kotlin.models.AuthScreen
import main.kotlin.ui.BlurbPlacement
import main.kotlin.ui.Button
import main.kotlin.ui.Div
import org.w3c.dom.Document

class ProcedureEditor(document: Document, val handbook: Handbook,screen:AuthScreen) : ContainerPanel
{
    val id = "procedure_editing"
    override val container = Div(document, id, "flex")
    val default = ProcedureDefaultsPanel(document)
    val settings = ProcedureSettingsEditor(document, handbook)
    val step = ProcedureStepEditor(document, handbook)
    val blurb = ProcedureBlurbEditor(document,handbook)
    val requirements = ProcedureRequirementsEditor(document, handbook)
    val images = ProcedureImagesEditor(document,screen)
    val choiceBlurbEditor : ProcedureBlurbChoiceEditor = ProcedureBlurbChoiceEditor(document,"procedure_blurb_choice_editor",handbook)

    val panels : List<ContainerEditorPanel> = listOf(default,settings,step,blurb,requirements,images,choiceBlurbEditor)

    val expandButton = Button(document, "$id-expand_button",tooltip="Expand all Procedure/Checklist steps")
    val collapseButton = Button(document, "$id-collapse_button",tooltip="Collapse all Procedure/Checklist steps")
    val cancelButton = Button(document, "$id-cancel_button",tooltip="Close the Editor")
    val saveButton = Button(document, "$id-save_button",tooltip="Save any changes for this Procedure/Checklist")
    val deleteButton = Button(document, "$id-delete_button",tooltip="Delete Procedure/Checklist")
    val settingsButton = Button(document, "$id-settings_button",tooltip="Procedure/Checklist Settings")
    val addHeaderButton = Button(document, "$id-add_header_button",tooltip="Add a Header to the Procedure/Checklist")
    val addStepButton = Button(document, "$id-add_step_button",tooltip="Add another Step to the Procedure/Checklist")
    val addFooterButton = Button(document, "$id-add_footer_button",tooltip="Add a Footer to the Procedure/Checklist")

    private var _delegate : ProcedureEditorDelegate? = null
    var delegate : ProcedureEditorDelegate?
    set(value)
    {
        _delegate = value
        step.delegate = value
        blurb.delegate = value
        settings.delegate = value
        requirements.delegate = value
        images.delegate = value
        choiceBlurbEditor.delegate = value
    }
    get()
    {
        return _delegate
    }

    private var _options = ProcedureOptions()
    var options : ProcedureOptions
    get()
    {
        return _options
    }
    set(value)
    {
        _options = value
        settings.options = _options
        step.options = _options
        blurb.options = _options
        requirements.options = options
        choiceBlurbEditor.options = options
    }

    fun showDefaults()
    {
        nav.clear()
        nav.add(default.panelType)
        showCurrentPanel()
        step.clear()
        blurb.clear()
        delegate?.reloadData()
    }

    fun toggleSettings()
    {
        val isViewingSettings = nav.contains(settings.panelType)
        nav.clear()
        if (isViewingSettings)
            nav.remove(settings.panelType)
        else
            nav.add(settings.panelType)
        showCurrentPanel()

        step.clear()
        blurb.clear()
        val proc = delegate?.procedure
        if (proc != null)
            settings.reloadRequirements(proc)
        delegate?.reloadData()
    }

    fun toggleStep(data: ProcedureItem, atTop:Boolean, atBottom:Boolean)
    {
        if (data.id == step.current?.id)
        {
            showDefaults()
        }
        else
        {
            editStep(data,atTop,atBottom)
        }
    }

    fun toggleBlurb(data: Blurb, placement: BlurbPlacement, atTop:Boolean, atBottom:Boolean)
    {
        if (data.id == blurb.current?.id)
        {
            showDefaults()
        }
        else
        {
            editBlurb(data,placement,atTop,atBottom)
        }
    }

    fun editStep(data: ProcedureItem, atTop:Boolean, atBottom:Boolean)
    {
        nav.clear()
        nav.add(step.panelType)
        showCurrentPanel()
        step.update(data)
        step.update(atTop,atBottom)
        blurb.clear()
        delegate?.reloadData()
    }

    fun editBlurb(data: Blurb, placement: BlurbPlacement, atTop:Boolean, atBottom:Boolean)
    {
        nav.clear()
        nav.add(blurb.panelType)
        showCurrentPanel()
        blurb.update(data,placement)
        blurb.update(atTop,atBottom)
        step.clear()
        delegate?.reloadData()
    }

    fun reloadRequirements(data:Specificable)
    {
        if (data is Procedure)
            settings.reloadRequirements(data)
        else if (data is ProcedureItem)
            step.reloadRequirements(data)
        else
        {
            if (data == choiceBlurbEditor.current)
                choiceBlurbEditor.reloadRequirements(data)
            else if (data == blurb.current)
                blurb.reloadRequirements(data)
        }
    }

    val currentPanel : ContainerEditorPanelType
    get()
    {
        return nav.lastOrNull() ?: ContainerEditorPanelType.Default
    }

    fun showCurrentPanel()
    {
        val current = currentPanel
        for (panel in panels)
        {
            panel.hidden = panel.panelType != current
        }
    }

    val nav : MutableList<ContainerEditorPanelType> = mutableListOf()

    fun editRequirements(data: Specificable)
    {
        nav.add(requirements.panelType)
        showCurrentPanel()
        requirements.update(data)
        delegate?.reloadData()
    }

    fun closeRequirements()
    {
        nav.remove(requirements.panelType)
        showCurrentPanel()
        delegate?.reloadData()
    }

    fun editImages(screen:AuthScreen,data:Blurb,placement:BlurbPlacement)
    {
        nav.add(images.panelType)
        showCurrentPanel()
        images.update(screen,data,placement)
        delegate?.reloadData()
    }

    fun closeImages()
    {
        nav.remove(images.panelType)
        showCurrentPanel()
        delegate?.reloadData()
    }

    fun editChoiceBlurb(choice:BlurbChoice,data:Blurb,item:ProcedureItem)
    {
        if (nav.contains(ContainerEditorPanelType.ChoiceBlurb))
            nav.remove(ContainerEditorPanelType.ChoiceBlurb)
        nav.add(choiceBlurbEditor.panelType)
        showCurrentPanel()
        choiceBlurbEditor.update(data,BlurbPlacement.Choice(item,choice))
        val atTop = choice.blurbs.indexOfFirst { it.id == data.id } == 0
        val atBottom = choice.blurbs.indexOfFirst { it.id == data.id } == choice.blurbs.lastIndex
        choiceBlurbEditor.update(atTop,atBottom)
        step.clear()
        delegate?.reloadData()
    }

    fun closeChoiceBlurb()
    {
        nav.remove(choiceBlurbEditor.panelType)
        showCurrentPanel()
        choiceBlurbEditor.clear()
        blurb.reloadChoices()
        delegate?.reloadData()
    }
}

sealed class ProcedureEditorButtonBlurbAction
{
    class Delete(val blurb: Blurb, val placement: BlurbPlacement) : ProcedureEditorButtonBlurbAction()
    class Up(val blurb: Blurb, val placement: BlurbPlacement) : ProcedureEditorButtonBlurbAction()
    class Down(val blurb: Blurb, val placement: BlurbPlacement) : ProcedureEditorButtonBlurbAction()
    class Create(val placement: BlurbPlacement) : ProcedureEditorButtonBlurbAction()
    class ConvertToStepHeader(val blurb: Blurb, val placement: BlurbPlacement,val stepheader:Boolean) : ProcedureEditorButtonBlurbAction()
}

sealed class ProcedureEditorButtonStepAction
{
    class Delete(val step: ProcedureItem) : ProcedureEditorButtonStepAction()
    class Up(val step: ProcedureItem) : ProcedureEditorButtonStepAction()
    class Down(val step: ProcedureItem) : ProcedureEditorButtonStepAction()
    class Create : ProcedureEditorButtonStepAction()
}

enum class ProcedureEditorButtonAction(val value:Int)
{
    Delete(0),
    Up(1),
    Down(2)
}

interface ProcedureEditorDelegate
{
    val procedure : Procedure?

    fun reloadData()
    fun changedSettings()
    fun changedBlurb(placement: BlurbPlacement)
    fun changedStep()
    fun changedRequirements(data:Specificable)
    fun clickedBlurbButton(action:ProcedureEditorButtonBlurbAction)
    fun clickedStepButton(action:ProcedureEditorButtonStepAction)
    fun clickedEditRequirements(data:Specificable)
    fun clickedEditImages(blurb:Blurb,placement:BlurbPlacement)
    fun clickedEditChoiceBlurb(choice:BlurbChoice,blurb:Blurb,placement:BlurbPlacement)
}

open class ProcedureEditorButtons(document:Document,containerId:String)
{
    open val deleteTooltip : String get() { return "Delete" }
    open val upTooltip : String get() { return "Move Up" }
    open val downTooltip : String get() { return "Move Down" }

    val deleteButton = Button(document, "$containerId-delete_button",deleteTooltip)
    val upButton = Button(document, "$containerId-up_button",upTooltip)
    val downButton = Button(document, "$containerId-down_button",downTooltip)

    open fun update(atTop:Boolean,atBottom:Boolean)
    {
        upButton.disabled = atTop
        downButton.disabled = atBottom
    }
}