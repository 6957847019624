package main.kotlin.ui

import main.kotlin.data.InstallationCard
import main.kotlin.data.ProcedureType
import main.kotlin.data.UserRole
import org.w3c.dom.Document

val List<InstallationCard>.selectorItems : Array<SelectorItem>
get()
{
    val selectors : MutableList<SelectorItem> = mutableListOf()

    var index = 0
    for (installation in this)
    {
        selectors.add(SelectorItem("installation-${installation.id}",installation.name,index.toString()))
        index += 1
    }

    return selectors.toTypedArray()
}

class InstallationSelector(document:Document, id:String, hint:String,val installations:List<InstallationCard>) : Selector(document,id,hint,installations.selectorItems)
{
    var selected : InstallationCard
    get()
    {
        val s = view.value as String
        return installations[s.toInt()]
    }
    set(value)
    {
        view.value = installations.indexOf(value).toString()
    }
}