package main.kotlin.data

import kotlin.js.Date

typealias UnixTime = Double

fun Date.getDayName() : String
{
    return when(getDay())
    {
        0 -> "Sunday"
        1 -> "Monday"
        2 -> "Tuesday"
        3 -> "Wednesday"
        4 -> "Thursday"
        5 -> "Friday"
        6 -> "Saturday"
        else -> "???"
    }
}

fun Date.getMonthName() : String
{
    return when(getMonth()+1)
    {
        1 -> "January"
        2 -> "February"
        3 -> "March"
        4 -> "April"
        5 -> "May"
        6 -> "June"
        7 -> "July"
        8 -> "August"
        9 -> "September"
        10 -> "October"
        11 -> "November"
        12 -> "December"
        else -> "???"
    }
}

fun Date.getMonthNameShort() : String
{
    return when(getMonth()+1)
    {
        1 -> "Jan"
        2 -> "Feb"
        3 -> "Mar"
        4 -> "Apr"
        5 -> "May"
        6 -> "Jun"
        7 -> "Jul"
        8 -> "Aug"
        9 -> "Sep"
        10 -> "Oct"
        11 -> "Nov"
        12 -> "Dec"
        else -> "???"
    }
}

val UnixTime.display : String
get()
{
    val d = Date(this * 1000)
    val dayName = d.getDayName()
    val day = d.getDate().toString()
    val month = d.getMonthName()
    val year = d.getFullYear()
    val hours = d.getHours().toString().padStart(2, '0')
    val minutes = d.getMinutes().toString().padStart(2, '0')

    return "$dayName $day $month $year $hours:$minutes"
}

val UnixTime.displayShort : String
get()
{
    val d = Date(this * 1000)

    val day = d.getDate().toString()
    val month = (d.getMonth() + 1).toString().padStart(2, '0')
    val year = d.getFullYear()
    val hours = d.getHours().toString().padStart(2, '0')
    val minutes = d.getMinutes().toString().padStart(2, '0')

    return "$day/$month/$year $hours:$minutes"
}

val UnixTime.displayPublished : String
get()
{
    val d = Date(this * 1000)

    val day = d.getDate().toString()
    val month = d.getMonthNameShort()
    val year = d.getFullYear()
    val hours = d.getHours().toString().padStart(2, '0')
    val minutes = d.getMinutes().toString().padStart(2, '0')

    return "$day $month $year at $hours:$minutes"
}

val UnixTime.displayCompleted : String
get()
{
    return displayPublished
}
