package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.ArchivedOperation
import main.kotlin.data.BaseShip
import main.kotlin.data.Handbook
import main.kotlin.data.OperationType
import main.kotlin.ui.Breadcrumb
import main.kotlin.ui.Breadcrumbs
import main.kotlin.ui.Div
import main.kotlin.ui.TopNavBarTab
import org.w3c.dom.Document

open class ShipHistoryScreen(document: Document, private val ship: BaseShip) : TopNavBarScreen(document)
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Ships

    val breadcrumbs = Breadcrumbs(document, "page_breadcrumbs", arrayOf(
            Breadcrumb("Vessels", "ships.html"),
            Breadcrumb(ship.name, "ship.html?id=${ship.id}")
        )
    )

    val grid : Div = Div(document,"grid")

    override fun start()
    {
        super.start()
        refresh()
    }

    open fun refresh()
    {
        loading = true

        Requests.getShipHistory(this,ship.id)
        {
            loading = false
            if (it.error == null)
            {
                refreshed(it.operations)
            }
            else
            {
                content.hidden = true
                error.hidden = false
                error.text = "Failed to load vessel history"
            }
        }
    }

    var operations : Array<ArchivedOperation> = arrayOf()

    fun refreshed(it:Array<ArchivedOperation>)
    {
        operations = it.sortedByDescending { it.completion.timestamp }.toTypedArray()
        reloadData()
    }

    fun reloadData()
    {
        var html = """
        <div class="ship-history-row ship-history-header-row"> 
            <div class="ship-history-column1 ship-history-header-column">Operation</div>
            <div class="ship-history-column2 ship-history-header-column">Installation/Voyage Number</div>
            <div class="ship-history-column3 ship-history-header-column">Started</div> 
            <div class="ship-history-column4 ship-history-header-column">Completed</div>  
        </div> 
        """

        for (op in operations)
        {
            val handbookName = op.handbookName ?: op.operationType.name
            val dets : MutableList<String> = mutableListOf()

            val destination = op.destinationName ?: op.destinationId
            if (destination != null)
                dets.add(destination)

            if (op.voyageNumber != null)
                dets.add(op.voyageNumber)

            val details = dets.joinToString("/")

            var linkPrefix = ""
            var linkSuffix = ""

            val hb = if (op.handbookId!=null)
            {
                Handbook(op.handbookId,op.handbookName ?: "",true)
            }
            else
            {
                when(op.operationType) {
                    OperationType.OffshoreLoading ->  Handbook("offshore_loading","Offshore Loading",true)
                    OperationType.Navigation ->  Handbook("navigation","Navigation",true)
                    OperationType.None -> null
                }
            }

            hb?.tripId = op.tripId
            hb?.shipId = op.shipId
            hb?.shipName = ship.name

            if (hb != null)
            {
                linkPrefix = """<a href="procedures.html?trip=${op.tripId}&handbook=${hb.params}">"""
                linkSuffix = "</a>"
            }

            html += """ 
            <div class="ship-history-row">
                $linkPrefix
                <div class="ship-history-column1">$handbookName</div>
                <div class="ship-history-column2">$details</div>
                <div class="ship-history-column3">${op.initiation.description}</div>
                <div class="ship-history-column4">${op.completion.description}</div>
                 $linkSuffix
            </div> 
            """
        }

        grid.html = html
    }
}
