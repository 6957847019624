package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.*
import kotlin.browser.document
import kotlin.browser.window

open class ShipScreen(document: Document, private val sid:String) : TopNavBarScreen(document)
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Ships

    val breadcrumbs = Breadcrumbs(document,"page_breadcrumbs", arrayOf(Breadcrumb("Vessels","ships.html")))

    val name = EditText(document,"name","Name",required=true)
    val type = EditText(document,"type","Type")
    val classe = EditText(document,"class","Class")
    val region = EditText(document,"region","Region")
    val disabled = Switch(document,"disabled","Disabled",tooltip = "Disabled vessels will not show up when selecting which vessel the SeaPad application is assigned to")

    val vesselMasterEmail = EditText(document,"vessel_master","Vessel Master Email",tooltip = "If this is set, users are able to reset a forgotten pin code, which is sent to this email address")

    private val handbooksContainer = Div(document,"handbooks")
    private val handbookSwitches : MutableList<Switch> = mutableListOf()

    val navbar = NavBar(document,"page_navbar")
    val saveButton = Button(document,"save_button",tooltip="Save changes to this vessel")
    val deleteButton = Button(document,"delete_button",tooltip="Delete this vessel")
    val historyButton = Button(document,"history_button",tooltip="Operation Archive for this vessel")

    val alert = Alert(document)

    val activeOperations : HTMLDivElement = document.getElementById("operations_active") as HTMLDivElement

    init
    {
        saveButton.onclick = { save() }
        deleteButton.onclick = { delete() }
        historyButton.onclick = { history() }
        disabled.valueChanged = { changed() }

        name.textChanged = { if (it != ship?.name) changed() }
        type.textChanged = { if (it != ship?.type) changed() }
        classe.textChanged = { if (it != ship?.classe) changed() }
        region.textChanged = { if (it != ship?.region) changed() }
        vesselMasterEmail.textChanged = { if (it != ship?.vesselMasterEmail) changed() }
    }

    override fun start()
    {
        super.start()
        refresh()
    }

    open fun refresh()
    {
        val id = sid

        loading = true

        Requests.getShipAndActiveOperationHandbooks(this,id)
        {
            loading = false
            val ship = it.ship
            if (ship != null)
            {
                handbooks = it.handbooks
                saveButton.hidden = false
                refreshed(ship)
                hasChanges = false
            }
            else
            {
                content.hidden = true
                error.hidden = false
                saveButton.hidden = true
                error.text = "Failed to load vessel info"
            }
        }
    }

    private fun changed()
    {
        hasChanges = true
    }

    private var _hasChanges : Boolean = false
    var hasChanges : Boolean get() { return _hasChanges } set(value) { _hasChanges = value; updateOnBeforeUnload() }

    open fun updateOnBeforeUnload()
    {
        if (hasChanges)
            window.onbeforeunload = { "There are unsaved changes. Are you sure that you want to leave this page?" }
        else
            window.onbeforeunload = null
    }

    var ship : Ship? = null
    var handbooks : Array<Handbook> = arrayOf()

    private fun reloadHandbooks()
    {
        handbookSwitches.clear()

        val data = ship
        if (data == null)
        {
            handbooksContainer.html = ""
            return
        }

        var html = """<div class="ship-section_title">Enabled Handbooks</div>"""

        html += """<div class="page_fields">"""

        for (handbook in handbooks)
        {
            html += """<div id="handbook-${handbook.id}"></div>"""
        }
        html += "</div>"
        handbooksContainer.html = html

        for (handbook in handbooks)
        {
            val switch = Switch(document,"handbook-${handbook.id}",handbook.descriptiveName)
            switch.checked = data.supports(handbook.id)
            switch.valueChanged = { changed() }
            handbookSwitches.add(switch)
        }
    }

    private fun reloadActiveOperations()
    {
        var html = ""

        val ship = ship

        if (ship == null)
        {
            activeOperations.innerHTML = html
            return
        }

        for (handbook in handbooks.filter { ship.supports(it.id) })
        {
            val state = ship.state(handbook.id) ?: ShipOperationState()

            html += """<div class="ship-active_operation_section">"""

            html += """<div class="ship-active_operation_section_title">${handbook.name}</div>"""

            if (handbook.featureFlags.contains(HandbookFeature.Loadable))
            {
                html += "<p><b>Load Count: </b>${state.loads}</p>"
            }

            val started = state.initiated

            if (started != null)
            {
                html += "<p><b>Operation Started: </b>${started.description}</p>"
                val voyageNumber = state.voyageNumber
                if (voyageNumber != null)
                {
                    html += "<p><b>Voyage Number: </b>$voyageNumber</p>"
                }
                val destination = state.destinationName ?: state.destination
                if (destination != null)
                {
                    html += "<p><b>Destination: </b>$destination</p>"
                }
                val trip = state.trip
                if (trip != null)
                {
                    handbook.shipId = ship.id
                    handbook.shipName = ship.name
                    handbook.tripId = trip
                    html += """<a href="procedures.html?handbook=${handbook.params}">View Procedures / Checklists</a>"""
                }
            }
            else
            {
                html += "<p>No active operations</p>"
            }

            html += "</div>"
        }

        if (html.isNotEmpty())
            html = """<div class="ship-section_title">Current Operations</div>$html"""
        activeOperations.innerHTML = html
    }

    fun refreshed(data:Ship)
    {
        ship = data

        navbar.title = data.name

        name.text = data.name
        type.text = data.type
        classe.text = data.classe
        region.text = data.region
        disabled.checked = data.disabled

        vesselMasterEmail.text = data.vesselMasterEmail

        reloadHandbooks()
        reloadActiveOperations()
    }

    fun updates(ship:Ship) : ItemUpdates
    {
        val updates = Any().asDynamic()
        var hasChanges = false

        fun addIfChanged(named:String,current:String,change:String)
        {
            if (current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:List<String>,change:List<String>)
        {
            if (current != change)
            {
                updates[named] = change.toTypedArray()
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:Boolean,change:Boolean)
        {
            if (current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        addIfChanged("name",ship.name,name.text)
        addIfChanged("type",ship.type,type.text)
        addIfChanged("class",ship.classe,classe.text)
        addIfChanged("region",ship.region,region.text)
        addIfChanged("vessel_master_email",ship.vesselMasterEmail,vesselMasterEmail.text)
        addIfChanged("disabled",ship.disabled,disabled.checked)

        val handbooks : MutableList<String> = mutableListOf()
        for (handbookSwitch in handbookSwitches)
        {
            val handbookId = handbookSwitch.id.removePrefix("handbook-")
            if (handbookSwitch.checked)
                handbooks.add(handbookId)
        }
        addIfChanged("handbooks",ship.handbooks,handbooks)

        if (name.text.isBlank())
            return ItemUpdates(error="Name cannot be blank")

        if (hasChanges)
            return ItemUpdates(data=updates)
        return ItemUpdates(error="No changes to save")
    }

    open fun save()
    {
        val id = sid
        val ship = ship ?: return

        val updates = updates(ship)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.updateShip(this,id,data){
            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to change vessel info")
            }
            else
            {
                showToast(it,"Vessel info changed!")
                refresh()
            }
        }
    }

    fun delete(confirmed:Boolean=false)
    {
        if (confirmed)
        {
            loading = true
            Requests.deleteShip(this,sid)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it,"Failed to delete vessel")
                }
                else
                {
                    showToast(it,"Vessel deleted!")
                    pushTo("ships")
                }
            }
        }
        else
        {
            alert.open("Delete Vessel", "Are you sure you would like to delete this vessel?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete") {
                    delete(true)
                }
            }
        }
    }

    fun history()
    {
        val s = ship?.params ?: return
        pushToHtml("ship_history.html?ship=$s")
    }
}

class CreateShipScreen(document:Document) : ShipScreen(document,"")
{
    init
    {
        deleteButton.hidden = true
        historyButton.hidden = true
        navbar.title = "Create Vessel"
    }

    override fun refresh()
    {
        loading = false
    }

    override fun save()
    {
        val ship = Ship()
        val updates = updates(ship)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.createShip(this,data)
        {
            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to create vessel")
            }
            else
            {
                showToast(it,"Vessel created!")
                pushTo("ships")
            }
        }
    }

    override fun updateOnBeforeUnload()
    {

    }
}