package main.kotlin.ui

import main.kotlin.data.*
import main.kotlin.models.picker.ProcedureLinkPicker
import main.kotlin.models.picker.ProcedureTemplatePicker
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import kotlin.browser.window

interface TextField
{
    var text : String

    var textChanged : ((String)->Unit)?
}

class BaseEditText(val document: Document,val id:String, val hint:String="")
{
    val input = document.getElementById("$id") as HTMLInputElement

    init
    {
        input.placeholder = hint
    }

    fun focus()
    {
        input.focus()
    }

    var text : String
    get()
    {
        return input.value as String
    }
    set(value)
    {
        input.value = value
    }

    private var _textChanged :  ((String)->Unit)? = null
    var textChanged : ((String)->Unit)?
    get()
    {
        return _textChanged
    }
    set(value)
    {
        _textChanged = value

        input.oninput = null

        if (value == null) return

        input.oninput = {
            _textChanged?.invoke(text)
        }
    }
}

class EditText(val document: Document,val id:String,val hint:String="",val type:String="text",required:Boolean=false,val autocomplete:String="off",val autofill:String="off",tooltip:String?=null) : TextField
{
    val container = Div(document,id,"inline-flex",tooltip)
    private val view : dynamic
    private val input : HTMLInputElement

    init
    {
        container.html = createMarkup(required)
        view = getView(id)
        input = document.getElementById("input-$id") as HTMLInputElement
    }

    fun createMarkup(required:Boolean=false) : String
    {
        val req = if (required) " required" else ""
        val ac = if (autocomplete.isNotEmpty()) """ autocomplete="$autocomplete"""" else ""
        val af = if (autofill.isNotEmpty()) """ autofill="$autofill"""" else ""
        return """
        <input type="$type" class="mdc-text-field__input" id="input-$id" name="$id"$ac$af$req>
        <label class="mdc-floating-label" for="input-$id">$hint</label>
        <div class="mdc-line-ripple"></div>
        """
    }

    private fun getView(id:String) : dynamic
    {
        val obj = js("""
    (function(){
        return new mdc.textField.MDCTextField(document.getElementById(id));
    }())
    """)
        return obj
    }

    fun focus()
    {
        input.focus()
    }

    override var text : String
    get()
    {
        return view.value as String
    }
    set(value)
    {
        view.value = value
    }

    private var _textChanged :  ((String)->Unit)? = null
    override var textChanged : ((String)->Unit)?
    get()
    {
        return _textChanged
    }
    set(value)
    {
        _textChanged = value

        input.oninput = null

        if (value == null) return

        input.oninput = {
            _textChanged?.invoke(text)
        }
    }

    private var _onKeyUp :  ((KeyboardEvent)->Unit)? = null
    var onKeyUp : ((KeyboardEvent)->Unit)?
    get()
    {
        return _onKeyUp
    }
    set(value)
    {
        _onKeyUp = value

        input.onkeyup = null

        if (value == null) return

        input.onkeyup = {
            _onKeyUp?.invoke(it as KeyboardEvent)
        }
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
    }

    var disabled : Boolean
    get()
    {
        return view.disabled
    }
    set(value)
    {
        view.disabled = value
    }

    var opacity : String
    get()
    {
        return container.opacity
    }
    set(value)
    {
        container.opacity = value
    }

    var required : Boolean
    get()
    {
        return input.required
    }
    set(value)
    {
        input.required = value
    }
}

class FullwidthEditText(val document: Document,val id:String,val hint:String="",val type:String="text",val required:Boolean=false)
{
    private val container : HTMLDivElement = document.getElementById(id) as HTMLDivElement
    private val view : dynamic
    private val input : HTMLInputElement

    init
    {
        container.innerHTML = markup
        view = getView(id)
        input = document.getElementById("input-$id") as HTMLInputElement
    }

    val markup : String
        get()
        {
            val req = if (required) " required" else ""

            return """
        <input type="$type" class="mdc-text-field__input" id="input-$id" name="$id" placeholder="$hint"$req>
        """
        }

    private fun getView(id:String) : dynamic
    {
        val obj = js("""
    (function(){
        return new mdc.textField.MDCTextField(document.getElementById(id));
    }())
    """)
        return obj
    }

    fun focus()
    {
        input.focus()
    }

    var text : String
        get()
        {
            return view.value as String
        }
        set(value)
        {
            view.value = value
        }

    private var _textChanged :  ((String)->Unit)? = null
    var textChanged : ((String)->Unit)?
        get()
        {
            return _textChanged
        }
        set(value)
        {
            _textChanged = value

            input.oninput = null

            if (value == null) return

            input.oninput = {
                _textChanged?.invoke(text)
            }
        }

    private var _onKeyUp :  ((String)->Unit)? = null
    var onKeyUp : ((String)->Unit)?
        get()
        {
            return _onKeyUp
        }
        set(value)
        {
            _onKeyUp = value

            input.onkeyup = null

            if (value == null) return

            input.onkeyup = {
                _onKeyUp?.invoke((it as KeyboardEvent).key)
            }
        }

    var hidden : Boolean
        get()
        {
            return view.style.display == "none"
        }
        set(value)
        {
            view.style.display = if (value) "none" else "block"
        }

    var opacity : String
        get()
        {
            return container.style.opacity
        }
        set(value)
        {
            container.style.opacity = value
        }
}

class OutlinedEditText(val document: Document,val id:String,val hint:String="",val type:String="text",val required:Boolean=false)
{
    private val container : HTMLDivElement = document.getElementById(id) as HTMLDivElement
    private val view : dynamic
    private val input : HTMLInputElement

    init
    {
        container.innerHTML = markup
        view = getView(id)
        input = document.getElementById("input-$id") as HTMLInputElement
    }

    val markup : String
        get()
        {
            val req = if (required) " required" else ""

            return """
<input type="text" id="input-$id" class="mdc-text-field__input"$req>
<div class="mdc-notched-outline">
    <div class="mdc-notched-outline__leading"></div>
    <div class="mdc-notched-outline__notch">
        <label for="input-$id" class="mdc-floating-label">$hint</label>
    </div>
    <div class="mdc-notched-outline__trailing"></div>
</div>
"""
        }

    private fun getView(id:String) : dynamic
    {
        val obj = js("""
    (function(){
        return new mdc.textField.MDCTextField(document.getElementById(id));
    }())
    """)
        return obj
    }

    fun focus()
    {
        input.focus()
    }

    var text : String
        get()
        {
            return view.value as String
        }
        set(value)
        {
            view.value = value
        }

    private var _textChanged :  ((String)->Unit)? = null
    var textChanged : ((String)->Unit)?
    get()
    {
        return _textChanged
    }
    set(value)
    {
        _textChanged = value

        input.oninput = null

        if (value == null) return

        input.oninput = {
            _textChanged?.invoke(text)
        }
    }

    private var _onKeyUp :  ((String)->Unit)? = null
    var onKeyUp : ((String)->Unit)?
    get()
    {
        return _onKeyUp
    }
    set(value)
    {
        _onKeyUp = value

        input.onkeyup = null

        if (value == null) return

        input.onkeyup = {
            _onKeyUp?.invoke((it as KeyboardEvent).key)
        }
    }

    var hidden : Boolean
    get()
    {
        return view.style.display == "none"
    }
    set(value)
    {
        view.style.display = if (value) "none" else "block"
    }

    var opacity : String
    get()
    {
        return container.style.opacity
    }
    set(value)
    {
        container.style.opacity = value
    }
}

class EditTextArea(val document:Document,val id:String,val hint:String="",required:Boolean=false,val rows:Int=8) : TextField
{
    private val container = Div(document,id)
    private val view : dynamic
    private val input : HTMLTextAreaElement

    init
    {
        container.addClasses(arrayOf("mdc-text-field","mdc-text-field--textarea"))
        container.view.innerHTML = createMarkup(required)
        view = getView(id)
        input = document.getElementById("textarea-$id") as HTMLTextAreaElement
    }

    fun createMarkup(required:Boolean=false) : String
    {
        val req = if (required) " required" else ""

        return """
        <textarea id="textarea-$id" class="mdc-text-field__input" rows="$rows" cols="40"$req></textarea>
        <label for="textarea-$id" class="mdc-floating-label">$hint</label>
        <div class="mdc-line-ripple"></div>
        """

        /*return """
        <textarea id="textarea-$id" class="mdc-text-field__input" rows="$rows" cols="40"$req></textarea>
        <div class="mdc-notched-outline">
            <div class="mdc-notched-outline__leading"></div>
            <div class="mdc-notched-outline__notch">
                <label for="textarea-$id" class="mdc-floating-label">$hint</label>
            </div>
            <div class="mdc-notched-outline__trailing"></div>
        </div>
        """*/
    }

    private fun getView(id:String) : dynamic
    {
        val obj = js("""
    (function(){
        return new mdc.textField.MDCTextField(document.getElementById(id));
    }())
    """)
        return obj
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
    }

    override var text : String
    get()
    {
        return view.value as String
        return ""
    }
    set(value)
    {
        view.value = value
    }

    var required : Boolean
    get()
    {
        return input.required
    }
    set(value)
    {
        input.required = value
    }

    private var _textChanged :  ((String)->Unit)? = null
    override var textChanged : ((String)->Unit)?
    get()
    {
        return _textChanged
    }
    set(value)
    {
        _textChanged = value

        input.oninput = null

        if (value == null) return

        input.oninput = {
            _textChanged?.invoke(text)
        }
    }
}

class EditTextRich(val document:Document, val handbook: Handbook, val id:String,hint:String="", var required:Boolean=false, config:Config=Config())
{
    data class Config(val linksAllowed:Boolean=true,val indentsAllowed:Boolean=true,val formattingAllowed:Boolean=true)

    private val container = Div(document,id)
    private val qContainer : Div
    private val view : dynamic
    private val boldButton : Button
    private val italicButton : Button
    private val underlineButton : Button
    private val strikeButton : Button
    private val outdentButton : Button
    private val indentButton : Button
    private val linkButton : Button
    private val addTemplateButton : Button
    private val requiredDiv : Div
    private val linkPicker : ProcedureLinkPicker
    private val templatePicker : ProcedureTemplatePicker

    private var _hint : String = ""
    var hint : String
    get()
    {
        return _hint
    }
    set(value)
    {
        _hint = value
        // TODO: Figure out how to update quills placeholder
//        if (view!=null)
//            view.placeholder = value
    }

    init
    {
        _hint = hint

        container.view.innerHTML = createMarkup(required)
        qContainer = Div(document,"$id-quill")
        view = getView("$id",hint)
        boldButton = Button(document,"$id-bold_button",tooltip = "Bold selected text")
        italicButton = Button(document,"$id-italic_button",tooltip = "Italicize selected text")
        underlineButton = Button(document,"$id-underline_button",tooltip = "Underline selected text")
        strikeButton = Button(document,"$id-strike_button",tooltip = "Strikethrough selected text")
        outdentButton = Button(document,"$id-outdent_button",tooltip = "Outdent selected text")
        indentButton = Button(document,"$id-indent_button",tooltip = "Indent selected text")
        linkButton = Button(document,"$id-add_link_button",tooltip = "Link to checklist or procedure")
        addTemplateButton = Button(document,"$id-add_template_button",tooltip = "Add template text")
        requiredDiv = Div(document,"$id-required")

        if (!config.linksAllowed)
            linkButton.hidden = true

        if (!config.indentsAllowed)
        {
            indentButton.hidden = true
            outdentButton.hidden = true
        }

        if (!config.formattingAllowed)
        {
            boldButton.hidden = true
            italicButton.hidden = true
            underlineButton.hidden = true
            strikeButton.hidden = true
        }

        if (!required)
        {
            requiredDiv.hidden = true
        }

        linkButton.onclick = { pickLink() }
        linkPicker = ProcedureLinkPicker(document,"$id-link_picker",handbook)
        templatePicker = ProcedureTemplatePicker(document,"$id-template_picker",handbook)

        view.pickLink = ::pickLink
        view.addIndent = ::addIndent
        view.removeIndent = ::removeIndent

        linkPicker.pickedLink = { pickedLink(it) }

        addTemplateButton.onclick = { pickTemplate() }
        templatePicker.pickedTemplate = { pickedTemplate(it) }

        view.on("text-change", ::textDidChange)
    }

    fun reload(options:ProcedureOptions,current: Procedure?)
    {
        linkPicker.reloadProcedures(options,current)
    }

    private val kShowPickerClass = "quill_picker_popover_show"
    private val kSelectedToolbarButton = "quill_selected_toolbar_button"

    @JsName("addIndent")
    fun addIndent()
    {
        insertText("\t")
    }

    @JsName("removeIndent")
    fun removeIndent()
    {
        removeText("\t")
    }

    @JsName("pickLink")
    fun pickLink()
    {
        templatePicker.container.removeClass(kShowPickerClass)
        addTemplateButton.removeClass(kSelectedToolbarButton)

        if (linkPicker.container.hasClass(kShowPickerClass))
        {
            linkPicker.container.removeClass(kShowPickerClass)
            linkButton.removeClass(kSelectedToolbarButton)
        }
        else
        {
            linkPicker.container.addClass(kShowPickerClass)
            linkButton.addClass(kSelectedToolbarButton)
        }
    }

    fun pickedLink(link:String)
    {
        view.format("link", link)
        linkPicker.container.removeClass(kShowPickerClass)
        linkButton.removeClass(kSelectedToolbarButton)
    }

    fun pickTemplate()
    {
        linkPicker.container.removeClass(kShowPickerClass)
        linkButton.removeClass(kSelectedToolbarButton)

        templatePicker.templates.selected = TemplateType.None
        if (templatePicker.container.hasClass(kShowPickerClass))
        {
            templatePicker.container.removeClass(kShowPickerClass)
            addTemplateButton.removeClass(kSelectedToolbarButton)
        }
        else
        {
            templatePicker.container.addClass(kShowPickerClass)
            addTemplateButton.addClass(kSelectedToolbarButton)
        }
    }

    fun pickedTemplate(template:String)
    {
        templatePicker.container.removeClass(kShowPickerClass)
        addTemplateButton.removeClass(kSelectedToolbarButton)
        if (template.isNotBlank())
        {
            insertText(template)
        }
    }

    fun insertText(text:String)
    {
        var selection = view.getSelection(true)
        if (selection)
        {
            view.insertText(selection.index,text)
        }
        else
        {
            view.insertText(view.getLength(),text)
        }
    }

    fun removeText(text:String)
    {
        var selection = view.getSelection(true)
        var index = 0
        if (selection)
            index = selection.index
        val s = view.getText(index,text.length)
        if (s == text)
        {
            view.deleteText(index,text.length)
        }
        else
        {
            if (index > 0)
            {
                index -= 1
                val s = view.getText(index,text.length)
                if (s == text)
                {
                    view.deleteText(index,text.length)
                }
            }
        }
    }

    fun createMarkup(required:Boolean=false) : String
    {
        return """

            <div id="$id-toolbar">
                <button class="ql-bold" id="$id-bold_button"></button>
                <button class="ql-italic" id="$id-italic_button"></button>
                <button class="ql-underline" id="$id-underline_button"></button>
                <button class="ql-strike" id="$id-strike_button"></button>
                <button class="ql-indent" value="-1" id="$id-outdent_button"></button>
                <button class="ql-indent" value="+1" id="$id-indent_button"></button>
                <button id="$id-add_link_button" class="mdc-button"><i class="material-icons mdc-button__icon" aria-hidden="true">insert_link</i></button>
                <button id="$id-add_template_button" class="mdc-button"><i class="material-icons mdc-button__icon" aria-hidden="true">settings_ethernet</i></button>
                <div id="$id-required" class="quill_required">*</div>
            </div>

            <div id="$id-link_picker" class="quill_picker_popover"></div>
            <div id="$id-template_picker" class="quill_picker_popover"></div>

            <div id="$id-quill"></div>
        """
    }

    private fun getView(id:String,placeholder:String) : dynamic
    {
        val obj = js("""
    (function(){

        window['Quill'].imports['formats/link'].PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'handbooks'];

        var quill = new Quill('#' + id + '-quill', {
            modules: {toolbar:'#' + id + '-toolbar', clipboard: {matchVisual: false} },
            theme: 'snow',
            placeholder: placeholder,
            bounds: '#' + id
        });

        var pickLink = function(value)
        {
            if (value)
            {
                this.quill.pickLink();
            }
            else
            {
                this.quill.format('link', false);
            }
        };

        var addIndent = function(value)
        {
            if (value == -1)
            {
                this.quill.removeIndent();
            }
            else
            {
                this.quill.addIndent();
            }
        };

        var toolbar = quill.getModule('toolbar');
        toolbar.addHandler('link', pickLink);
        toolbar.addHandler('indent', addIndent);

        return quill;
    }())
    """)
        return obj
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
    }

    var text : String
    get()
    {
        val delta = view.getContents()
        val html = window["quill"].convertDeltaToHtml(delta.ops)
        return html
    }
    set(value)
    {
        var html = value

        var startsWithTab = html.startsWith("\t")
        if (startsWithTab)
        {
            html = html.substring(1)
        }

        html = html.replace("\n","<br>")

        view.setContents(emptyArray<dynamic>())
        view.clipboard.dangerouslyPasteHTML(0, html)
        if (startsWithTab)
        {
            view.insertText(0,"\t")
        }
    }

    var ignoreTextDelegate = false

    private var _textChanged :  ((String)->Unit)? = null
    var textChanged : ((String)->Unit)?
    get()
    {
        return _textChanged
    }
    set(value)
    {
        _textChanged = value
    }

    private fun textDidChange(ev:Event) : dynamic
    {
        val text = text
        if (required)
        {
            val trimmed = text.trim()
            requiredDiv.hidden = !(trimmed.isNullOrEmpty() || trimmed == "<br>" || trimmed == "<br/>")
        }
        else
        {
            requiredDiv.hidden = true
        }

        if (ignoreTextDelegate)
        {
            ignoreTextDelegate = false
            return true
        }

        textChanged?.invoke(text)
        return true
    }
}
