package main.kotlin.ui

import main.kotlin.data.Ship
import org.w3c.dom.Document

val List<Ship>.selectorItems : Array<SelectorItem>
get()
{
    val selectors : MutableList<SelectorItem> = mutableListOf()

    var index = 0
    for (ship in this)
    {
        selectors.add(SelectorItem("ship-${ship.id}",ship.name,index.toString()))
        index += 1
    }

    return selectors.toTypedArray()
}

class ShipSelector(document: Document, id:String, hint:String, var ships:List<Ship>) : Selector(document,id,hint,ships.selectorItems)
{
    var selected : Ship?
    get()
    {
        val s = view.value as? String ?: return null
        if (s.isNullOrEmpty()) return null
        val index = s.toInt()
        if (index >= 0)
            return ships[index]
        return null
    }
    set(value)
    {
        val index = ships.indexOfFirst { it.name == value?.name }
        view.value = "$index"
    }

    fun setShips(ships:List<Ship>)
    {
        this.ships = ships
        setSelectorItems(ships.selectorItems)
    }
}

enum class SelectorNotSelectedMode
{
    All,
    None
}

fun List<String>.selectorItems(mode:SelectorNotSelectedMode=SelectorNotSelectedMode.All) : Array<SelectorItem>
{
    val selectors : MutableList<SelectorItem> = mutableListOf()

    when (mode)
    {
        SelectorNotSelectedMode.All -> selectors.add(SelectorItem("all","All","-1"))
        SelectorNotSelectedMode.None -> selectors.add(SelectorItem("none","None","-1"))
    }

    var index = 0
    for (string in this)
    {
        selectors.add(SelectorItem("ship-${string.toLowerCase()}",string,index.toString()))
        index += 1
    }

    return selectors.toTypedArray()
}

open class StringSelector(document:Document,id:String,hint:String,var strings:List<String>,val mode:SelectorNotSelectedMode=SelectorNotSelectedMode.All) : Selector(document,id,hint,strings.selectorItems(mode))
{
    var selected : String?
    get()
    {
        val s = view.value as? String ?: return null
        if (s.isNullOrEmpty()) return null
        val index = s.toInt()
        if (index >= 0)
            return strings[index]
        return null
    }
    set(value)
    {
        val v = if (value == null) -1 else strings.indexOfFirst { it == value }
        view.value = v.toString()
    }

    fun setStrings(classes:List<String>)
    {
        this.strings = classes
        setSelectorItems(classes.selectorItems(mode))
    }
}

class ShipTypeSelector(document:Document,id:String,hint:String,types:List<String>,mode:SelectorNotSelectedMode=SelectorNotSelectedMode.All) : StringSelector(document,id,hint,types,mode)
{
    fun setTypes(types:List<String>)
    {
        setStrings(types)
    }
}

class ShipClassSelector(document:Document,id:String,hint:String,classes:List<String>,mode:SelectorNotSelectedMode=SelectorNotSelectedMode.All) : StringSelector(document,id,hint,classes,mode)
{
    fun setClasses(classes:List<String>)
    {
        setStrings(classes)
    }
}

open class ShipRegionSelector(document:Document,id:String,hint:String,regions:List<String>,mode:SelectorNotSelectedMode=SelectorNotSelectedMode.All) : StringSelector(document,id,hint,regions,mode)
{
    fun setRegions(regions:List<String>)
    {
        setStrings(regions)
    }
}