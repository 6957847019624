package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.FilterByNameModel
import main.kotlin.data.InstallationCard
import main.kotlin.data.InstallationLoadingSystem
import main.kotlin.data.InstallationsResponse
import main.kotlin.ui.*
import org.w3c.dom.Document

class InstallationsScreen(document:Document) : TopNavBarScreen(document), TilesViewDelegate, FilterByNameModel
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Installations

    val grid = TilesView(document,this,"grid", "grid-installations")

    override val searchButton = Button(document,"search_button",tooltip=activeTab.searchHint)
    val createButton = Button(document,"create_button",tooltip="Create a new installation")
    val filters = InstallationsFilters(document,"filters")

    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=installations" }

    override fun start()
    {
        super.start()

        loading = false

        createButton.onclick = { create() }

        filters.start(this)
        {
            changedFilter()
        }

        refresh()
    }

    fun create()
    {
        pushTo("installation")
    }

    fun refresh()
    {
        loading = true
        Requests.listInstallations(this)
        {
            loading = false
            refreshed(it)
        }
    }

    var allinstallations : Array<InstallationCard> = arrayOf()
    var installations : Array<InstallationCard> = arrayOf()

    override fun searchTextChanged(text:String)
    {
        filters.query = text
        changedFilter()
    }

    private fun changedFilter()
    {
        val shouldFilter = filters.query.isNotEmpty() || filters.loadingSystem != null || filters.region != null
        if (!shouldFilter)
            installations = allinstallations
        else
        {
            val query = filters.query.toLowerCase()
            fun includeItem(item:InstallationCard) : Boolean
            {
                val hasQ = matchesSearch(query,item)
                val hasLoadingSystem = if (filters.loadingSystem != null) item.loadingSystem == filters.loadingSystem else true
                val hasRegion = if (filters.region != null) item.region == filters.region else true
                return hasQ && hasLoadingSystem && hasRegion
            }
            installations = allinstallations.filter { includeItem(it) }.toTypedArray()
        }
        grid.reloadData()
    }

    fun refreshed(it:InstallationsResponse)
    {
        allinstallations = it.installations
        searchTextChanged(if (searching) topnavbar.searchbar.text else "")
    }

    override val numberOfItems: Int get() { return installations.count() }

    override fun cellForItem(at:Int) : String
    {
        val it = installations[at]
        val onclick = "window.location.href='installation.html?id=${it.id}'"
        val title = it.name
        val details = it.loadingSystem.title
        val markup = """
    <li class="mdc-image-list__item" onclick="$onclick">
        <div class="mdc-image-list__image">
            <div class="cell_top_bar"></div>
            <i class="material-icons">place</i>
            <span class="cell_title">$title</span>
            <span class="cell_details">$details</span>
        </div>
    </li>"""
        return markup
    }
}

class InstallationsFilters(document:Document,val id:String)
{
    var query : String = ""
    var loadingSystem : InstallationLoadingSystem? = null
    var region : String? = null

    private val loadingSystemSelector : InstallationAllLoadingSystemsSelector
    private val regionSelector : InstallationRegionSelector
    private val container = Div(document,id)

    fun start(screen:AuthScreen,changedFilter:()->Unit)
    {
        loadingSystemSelector.selected = loadingSystem
        loadingSystemSelector.valueChanged = { loadingSystem = loadingSystemSelector.selected; changedFilter.invoke() }

        regionSelector.selected = region
        regionSelector.valueChanged = { region = regionSelector.selected; changedFilter.invoke() }

        regionSelector.start(screen)
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
        if (!value)
        {
            loadingSystemSelector.selected = loadingSystem
            regionSelector.selected = region
        }
    }

    val markup : String
    get()
    {
        return """
       <div class="mdc-select edit" id="${id}-loading_system"></div>
       <div class="mdc-select edit" id="${id}-region"></div>
    """
    }

    init
    {
        container.html = markup
        loadingSystemSelector = InstallationAllLoadingSystemsSelector(document,"${id}-loading_system","Loading System")
        regionSelector = InstallationRegionSelector(document,"${id}-region","Region", SelectorNotSelectedMode.All)
    }
}