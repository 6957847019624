package main.kotlin.ui

import main.kotlin.data.InstallationLoadingSystem
import org.w3c.dom.Document

class InstallationLoadingSystemSelector(document: Document,id:String,hint:String) : Selector(document,id,hint,arrayOf(
        SelectorItem("unknown","None","unknown"),
        SelectorItem("tandem","Tandem","tandem"),
        SelectorItem("ols","OLS","ols"),
        SelectorItem("sal","SAL","sal")

))
{
    var selectedLoadingSystem : InstallationLoadingSystem
    get()
    {
        val s = view.value as String
        return InstallationLoadingSystem.from(s)
    }
    set(value)
    {
        view.value = value.value
    }
}

class InstallationAllLoadingSystemsSelector(document: Document,id:String,hint:String) : Selector(document,id,hint,arrayOf(
        SelectorItem("all","All","unknown"),
        SelectorItem("tandem","Tandem","tandem"),
        SelectorItem("ols","OLS","ols"),
        SelectorItem("sal","SAL","sal")

))
{
    var selected : InstallationLoadingSystem?
    get()
    {
        val s = view.value as String
        if (s.isEmpty() || s == "unknown")
            return null
        val ls = InstallationLoadingSystem.from(s)
        if (ls == InstallationLoadingSystem.Unknown)
            return null
        return ls
    }
    set(value)
    {
        if (value == null)
            view.value = "unknown"
        else
            view.value = value.value
    }
}
