package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.*
import kotlin.browser.document

private val Handbook.breadcrumbs : Array<Breadcrumb>
get()
{
    val shipId = shipId
    if (shipId != null)
        return arrayOf(
                Breadcrumb("Vessels","ships.html"),
                Breadcrumb(shipName ?: shipId,"ship.html?id=$shipId"),
                Breadcrumb(name,link)
        )
    return arrayOf(
            Breadcrumb("Handbooks","handbooks.html"),
            Breadcrumb(name,link)
    )
}

open class HandbookScreen(document: Document, handbook:Handbook) : HandbookBaseScreen(document,handbook,handbook.activeTab),TableViewDelegate,HandbookChapterCellContentDelegate
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Nothing

    val breadcrumbs = Breadcrumbs(document,"page_breadcrumbs", handbook.breadcrumbs)

    val navbar = NavBar(document,"page_navbar")
    val navButtons = Div(document,"page_navbar_buttons","inline")
    val saveButton = Button(document,"save_button",tooltip="Save changes to this handbook")
    val deleteButton = Button(document,"delete_button",tooltip="Delete this handbook")
    val reviseButton = Button(document,"revise_button",tooltip="Create a new revision of this handbook")
    val iconPreview = Div(document,"icon_preview")

    val name = EditText(document,"name","Name of handbook","text",tooltip="Enter the name of this handbook")
    val description = EditText(document,"description","Description of handbook","text",tooltip="Enter a short description of this handbook. Useful to define this if you have several handbooks with the same name")
    val version = EditText(document,"version","Version of handbook","text",tooltip = "Enter the version number for this handbook")
    private val requiresVoyageNumber = Switch(document,"requires_voyage_number","Voyage number",tooltip="Select this option if you want the user to supply a voyage number before starting an operation")
    private val requiresDestination = Switch(document,"requires_destination","Destination",tooltip="Select this option if you want the user to supply a destination installation before starting an operation")
    val sequential = Switch(document,"sequential","Checklists filled in Sequentially",tooltip="Specify if the checklists of this handbook are required to be completed in sequence")
    val buttonOnStep = Switch(document,"button_on_step","Button on Step",tooltip="Check if the action button should appear in the heading instead of below the content")
    val trackLoadCount = Switch(document,"track_load_count","Keep track of load counts",tooltip="Select this option if you want the vessel to increment and keep track of its load count every time an operation is started using this handbook. This can then be used to conditionally require the completion of particular sections of checklists in chapters")

    private val startOperationButtonTitle = EditText(document,"start_operation_button_title","Start Operation Button Title","text",tooltip="Defaults to Explore")
    private val startedOperationButtonTitle = EditText(document,"started_operation_button_title","Started Operation Button Title","text",tooltip="Defaults to Explore")

    val createChapterButton = Button(document,"create_chapter_button",tooltip="Add a new chapter to this handbook")
    val chapterName = EditText(document,"chapter_name","Name of new chapter","text")
    val icon = HandbookIconSelector(document,"icon","Icon",tooltip="Select the most suitable icon representing this handbook")

    private var canEditHandbook = false
    private var canCreateChapters = false

    init
    {
        if (handbook.tripId != null)
            navButtons.hidden = true
        else
        {
            saveButton.onclick = { save() }
            deleteButton.onclick = { delete() }
            createChapterButton.onclick = { createChapter() }
            reviseButton.onclick = { revise() }

            reviseButton.hidden = true

            requiresVoyageNumber.valueChanged = { changedType() }
            requiresDestination.valueChanged = { changedType() }
            icon.valueChanged = { changedIcon() }
        }

        reloadTitle()

//        if (handbook == HandbookType.OffshoreLoading)
//            chapters.view.addClass("mdc-list--two-line")
    }

    val defaultPage = Tab("page-default","Settings")
    val createChapterPage = Tab("page-create_chapter","+ Create Chapter")
    val chapterPages = Div(document,"chapter_pages")
    val pagesList : MutableList<Tab> = mutableListOf()
    val pages = TableView(document,this,"pages")
    var currentPageId : String? = "page-default"

    override var loading : Boolean
    set(value)
    {
        super.loading = value
        if (handbook.tripId == null)
            navButtons.hidden = value
    }
    get()
    {
        return super.loading
    }

    override fun start()
    {
        super.start()

        refresh()
    }

    fun updates(settings:HandbookSettingsResponse) : ItemUpdates
    {
        val updates = Any().asDynamic()
        var hasChanges = false

        fun addIfChanged(named:String,current:Boolean,change:Boolean)
        {
            if (current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:HandbookIcon,change:HandbookIcon)
        {
            if (current != change)
            {
                updates[named] = change.value
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:String,change:String)
        {
            if (current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:List<HandbookChapter>)
        {
            var change = chapterList

            var hasChangedChapters = false

            val requiresDestination = requiresDestination.checked

            for ((i,chapter) in change.withIndex())
            {
                if (requiresDestination)
                {
                    val cell = chapterCells[i] as HandbookOffshoreLoadingChapterCellContent

                    chapter.name = cell.name.text
                    chapter.zone = cell.zone.text
                    chapter.atDestination = cell.atDestination.checked
                    chapter.visibleInTimeline = cell.visibleInTimeline.checked
                    val loadingSystem = cell.loadingSystem.selectedLoadingSystem
                    if (loadingSystem != InstallationLoadingSystem.Unknown)
                        chapter.loadingSystem = loadingSystem.value
                    else
                        chapter.loadingSystem = null
                    chapter.sections.clear()
                    chapter.sections.addAll(cell.sectionList)
                }
                else
                {
                    val cell = chapterCells[i]

                    chapter.name = cell.name.text
                    chapter.sections.clear()
                    chapter.sections.addAll(cell.sectionList)
                }
                if (chapterList.count() != current.count())
                {
                    hasChangedChapters = true
                    continue
                }
                val old = current[i]
                val isDifferent = chapter.hasChanged(old)
                if (isDifferent)
                    hasChangedChapters = true
            }

            if (hasChangedChapters)
            {
                updates.chapters = change.map { it.toDynamic() }.toTypedArray()
                hasChanges = true
            }
        }

        fun addFeaturesIfChanged()
        {
            val features : MutableList<String> = mutableListOf()
            if (trackLoadCount.checked)
                features.add(HandbookFeature.Loadable.value)
            features.sort()

            if (features != settings.features.sorted())
            {
                updates["features"] = features.toTypedArray()
                hasChanges = true
            }
        }

        addIfChanged("name",settings.name,name.text)
        addIfChanged("description",settings.description,description.text)
        addIfChanged("icon",settings.icon,icon.selected)
        addIfChanged("sequential",settings.sequential,sequential.checked)
        addIfChanged("complete_step_button_on_side",settings.buttonOnStep,buttonOnStep.checked)
        addIfChanged("chapters",settings.chaptersUnchanged)
        addIfChanged("version",settings.version,version.text)
        addIfChanged("requires_destination",settings.requiresDestination,requiresDestination.checked)
        addIfChanged("requires_voyage_number",settings.requiresVoyageNumber,requiresVoyageNumber.checked)
        addIfChanged("start_operation_button",settings.startOperationButton,startOperationButtonTitle.text)
        addIfChanged("started_operation_button",settings.startedOperationButton,startedOperationButtonTitle.text)
        addFeaturesIfChanged()

        if (hasChanges)
            return ItemUpdates(data=updates)
        return ItemUpdates(error="No changes to save")
    }

    open fun save()
    {
        val settings = settings ?: return

        val updates = updates(settings)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.updateHandbook(this,handbook.id,data){
            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to change handbook settings")
            }
            else
            {
                showToast(it,"Handbook settings changed!")
                refresh()
            }
        }
    }

    fun delete(confirmed:Boolean=false)
    {
        if (confirmed)
        {
            loading = true
            Requests.deleteHandbook(this,handbook.id)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it,"Failed to delete handbook")
                }
                else
                {
                    showToast(it,"Handbook deleted!")
                    pushTo("handbooks")
                }
            }
        }
        else
        {
            alert.open("Delete Handbook", "Are you sure you would like to delete this handbook?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete")
                {
                    delete(true)
                }
            }
        }
    }

    fun createChapter()
    {
        val name = chapterName.text
        if (name.isEmpty()) return

        val chapter = HandbookChapter(name)
        chapterList.add(chapter)
        chapterName.text = ""
        reloadData()
    }

    override fun refresh()
    {
        loading = true

        Requests.getHandbook(this,handbook.id,handbook.tripId)
        {
            loading = false
            refreshed(it)
        }
    }

    override fun openHandbook(id:String)
    {
        val hb = handbook
        hb.id = id
        pushToHtml("handbook.html?handbook=" + hb.params)
    }

    var settings : HandbookSettingsResponse? = null
    var chapterList : MutableList<HandbookChapter> = mutableListOf()

    override val handbookVersion : String?
    get()
    {
        return settings?.version
    }

    var cells : MutableList<PageCell> = mutableListOf()
    var chapterCells : MutableList<HandbookChapterCellContent> = mutableListOf()

    fun refreshed(it:HandbookSettingsResponse)
    {
        settings = it

        if (it.error == null)
        {
            handbook.name = it.name
            breadcrumbs.update(handbook.breadcrumbs)
        }

        reloadTitle()

        canCreateChapters = !it.isPublished
        canEditHandbook = !it.isPublished

        name.disabled = !canEditHandbook
        description.disabled = !canEditHandbook
        icon.disabled = !canEditHandbook
        version.disabled = !canEditHandbook
        requiresVoyageNumber.disabled = !canEditHandbook
        requiresDestination.disabled = !canEditHandbook
        sequential.disabled = !canEditHandbook
        trackLoadCount.disabled = !canEditHandbook
        buttonOnStep.disabled = !canEditHandbook
        startOperationButtonTitle.disabled = !canEditHandbook
        startedOperationButtonTitle.disabled = !canEditHandbook

        icon.selected = it.icon
        description.text = it.description
        requiresDestination.checked = it.requiresDestination
        requiresVoyageNumber.checked = it.requiresVoyageNumber
        sequential.checked = it.sequential
        buttonOnStep.checked = it.buttonOnStep
        trackLoadCount.checked = it.featureFlags.contains(HandbookFeature.Loadable)
        version.text = it.version
        name.text = it.name
        startOperationButtonTitle.text = it.startOperationButton
        startedOperationButtonTitle.text = it.startedOperationButton

        reloadIcon()

        chapterList.clear()
        chapterList.addAll(it.chapters)

        reloadData()
    }

    private fun changedType()
    {
        reloadData()
    }

    private fun changedIcon()
    {
        reloadIcon()
    }

    open fun reloadTitle()
    {
        fun getHandbookName() : String
        {
            return settings?.descriptiveName ?: handbook.descriptiveName
        }

        fun getSubPageName() : String
        {
            val currentPage = currentPageId ?: return "Settings"
            return when
            {
                currentPage.startsWith("page_chapter_") -> "Chapter"
                currentPage == "page-create_chapter" -> "Create Chapter"
                else -> "Settings"
            }
        }

        navbar.title = "${getHandbookName()} Handbook ${getSubPageName()}"
    }

    private fun reloadIcon()
    {
        val image = icon.selected.image
        iconPreview.html = """
            <div class="handbook-icon-tile"><div class="handbook-icon" style="background-image:url('$image');"></div></div>    
        """
    }

    private fun reloadNavButtons()
    {
        val settings = settings ?: return
        if (settings.isPublished)
        {
            reviseButton.hidden = false
            saveButton.hidden = true
        }
        else
        {
            reviseButton.hidden = true
            saveButton.hidden = false
        }
    }

    fun reloadData()
    {
        for (cell in cells)
            cell.li.onclick = null
        cells.clear()

        var html = ""
        for (v in chapterList.withIndex())
        {
            val id = "page_chapter_${v.index}"
            html += """
<div id="$id" style="display:none;">

    <div id="cell-content-${v.index}" class="handbook-settings-step-content">
        ${if (requiresDestination.checked) v.value.requiresDestinationMarkup(v.index) else v.value.normalMarkup(v.index)}
    </div>

</div> """
        }
        chapterPages.html = html

        pagesList.clear()
        pagesList.add(defaultPage)
        for (v in chapterList.withIndex())
        {
            pagesList.add(Tab("page_chapter_${v.index}",v.value.name))
        }
        if (canCreateChapters)
            pagesList.add(createChapterPage)

        pages.reloadData()

        for (chapter in pagesList)
        {
            val chapterId = chapter.id
            val cell = PageCell(chapterId)
            cell.li.onclick = { clickedPage(chapterId) }
            cells.add(cell)
        }

        reloadCurrentPage()
        reloadChapterPages()
        reloadNavButtons()
    }

    class PageCell(val id:String)
    {
        val li = Li(document,"cell-$id")
        val label = Span(document, "cell-$id-label")
    }

    private fun clickedPage(id:String)
    {
        currentPageId = id
        reloadCurrentPage()
    }

    private fun reloadCurrentPage()
    {
        for (cell in cells)
        {
            val shouldShow = currentPageId == cell.id
            if (shouldShow)
                cell.label.addClasses(arrayOf("current-selection"))
            else
                cell.label.removeClasses(arrayOf("current-selection"))

            val div = Div(document,cell.id)
            div.hidden = !shouldShow
        }

        reloadTitle()
    }

    override val numberOfRows: Int get() { return pagesList.count() }

    override fun cellForRow(at: Int): String
    {
        val chapter = pagesList[at]
        val topDivider = if (chapterList.isNotEmpty() && chapter.id == "page-create_chapter") """<hr class="divider"/>""" else ""
        val bottomDivider = if (chapterList.isNotEmpty() && chapter.id == "page-default") """<hr class="divider"/>""" else ""
        return """
        $topDivider
        <li class="mdc-list-item" id="cell-${chapter.id}">
            <span class="mdc-list-item__text" id="cell-${chapter.id}-label">${chapter.name}</span>
        </li>
        $bottomDivider
        """
    }

    private fun reloadChapterPages()
    {
        val oldCells : MutableMap<HandbookChapter,HandbookChapterCellContent> = mutableMapOf()

        for (cell in chapterCells)
        {
            oldCells[cell.chapter] = cell
        }

        for (cell in chapterCells)
            cell.clear()
        chapterCells.clear()

        val chapterCount = chapterList.count()
        if (requiresDestination.checked)
        {
            for ((i,chapter) in chapterList.withIndex())
            {
                val oldCell = oldCells[chapter]
                val cell = HandbookOffshoreLoadingChapterCellContent(canEditHandbook,handbook,chapter, i, chapterCount)
                cell.update(oldCell)
                cell.delegate = this
                chapterCells.add(cell)
            }
        }
        else
        {
            for ((i,chapter) in chapterList.withIndex())
            {
                val oldCell = oldCells[chapter]
                val cell = HandbookChapterCellContent(canEditHandbook,handbook,chapter, i, chapterCount)
                cell.update(oldCell)
                cell.delegate = this
                chapterCells.add(cell)
            }
        }
    }

    private fun openChapter(at:Int)
    {
        val page = pagesList[at+1]
        clickedPage(page.id)
    }

    override fun clickedUp(at:Int)
    {
        val chapter = chapterList.removeAt(at)
        chapterList.add(at-1,chapter)
        reloadData()
        openChapter(at-1)
    }

    override fun clickedDown(at:Int)
    {
        val chapter = chapterList.removeAt(at)
        chapterList.add(at+1,chapter)
        reloadData()
        openChapter(at+1)
    }

    override fun clickedDelete(at:Int)
    {
        chapterList.removeAt(at)
        reloadData()
    }

    private fun HandbookChapter.buttonsMarkup(at:Int) : String
    {
        val cellContentId = "cell-content-$at"
        return """
            <div class="page_fields_improved">
                <div class="full_width">
                    <div class="chapters-buttons">
                        <button class="mdc-button" id="$cellContentId-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete</button>
                        <button class="mdc-button" id="$cellContentId-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                        <button class="mdc-button" id="$cellContentId-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                    </div>
                </div>
            </div>
        """
    }

    private fun HandbookChapter.sectionsMarkup(at:Int) : String
    {
        val cellContentId = "cell-content-$at"

        val condVisStyle = if (requiresDestination.checked) "style='display:block;'" else "style='display:none;'"
        val conditionalsMarkup = """
        <div class="sections-header conditionals-header" $condVisStyle>Conditional Requirements</div>
        <div class="mdc-select edit" id="$cellContentId-section_conditionals_type" $condVisStyle></div>
        <div class="mdc-text-field edit" id="$cellContentId-section_conditionals_n" $condVisStyle></div>
        """

        return """

        <div class="page_fields_improved">
            <div class="full_width">
                <div class="sections-container">
                    <div class="sections">
        
                        <div id="$cellContentId-sections-header" class="sections-header">Sections</div>
        
                        <ul id="$cellContentId-sections" class="mdc-list" aria-orientation="vertical"></ul>
        
                        <div class="mdc-text-field edit" id="$cellContentId-create_section_name"></div>
                        <br/>
                        <button class="mdc-button" id="$cellContentId-create_section_button"><i class="material-icons mdc-button__icon" aria-hidden="true">add</i>Create Section</button>
                    </div>
        
                    <div class="selected-section" id="$cellContentId-selected_section" style="display:none;">
                        
                        <div class="mdc-text-field edit" id="$cellContentId-section_name"></div>
                        <br/>
                        <div class="sections-header">Header</div>
                        <div class="mdc-text-field edit" id="$cellContentId-section_header"></div>
                        <br/>
                        <div class="mdc-select edit" id="$cellContentId-section_header_type"></div>
                        <br/>
                        <div class="header-preview" id="$cellContentId-section_header_preview"></div>
        
                        $conditionalsMarkup
        
                        <div class="sections-buttons">
                            <button class="mdc-button" id="$cellContentId-delete_section_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete Section</button>
                            <button class="mdc-button" id="$cellContentId-up_section_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                            <button class="mdc-button" id="$cellContentId-down_section_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        """
    }

    private fun HandbookChapter.requiresDestinationMarkup(at:Int) : String
    {
        val cellContentId = "cell-content-$at"

        return """
        
        <div class="page_fields_improved">
            <div class="mdc-text-field edit" id="$cellContentId-name" required></div>
            <div class="mdc-text-field edit" id="$cellContentId-zone"></div>
            <div class="mdc-select edit" id="$cellContentId-loading_system"></div>
            <div id="$cellContentId-visible-in-timeline"></div>
            <div id="$cellContentId-at-destination"></div>
        </div>
        
        ${buttonsMarkup(at)}

        <br/>

        ${sectionsMarkup(at)}
        """
    }

    private fun HandbookChapter.normalMarkup(at:Int) : String
    {
        val cellContentId = "cell-content-$at"

        return """
        <div class="page_fields_improved">
            <div class="mdc-text-field edit" id="$cellContentId-name" required></div>
        </div>

        ${buttonsMarkup(at)}

        <br/>

        ${sectionsMarkup(at)}
        """
    }
}

open class HandbookChapterCellContent(val editable:Boolean,val handbook:Handbook,val chapter:HandbookChapter,var at:Int,val numberOfChapters:Int) : TableViewDelegate,HandbookSelectedSectionSettingsDelegate
{
    val cellContentId = "cell-content-$at"

    val name = EditText(document,"$cellContentId-name","Name","text",true)
    val sections = TableView(document,this,"$cellContentId-sections")
    val upButton = Button(document,"$cellContentId-up_button","Move chapter up")
    val downButton = Button(document,"$cellContentId-down_button","Move chapter down")
    val deleteButton = Button(document,"$cellContentId-delete_button","Delete chapter")

    val createSectionButton = Button(document,"$cellContentId-create_section_button",tooltip="Create a new section in the ${chapter.name} chapter")
    val sectionName = EditText(document,"$cellContentId-create_section_name","Name of new section","text")

    val selectedSection = HandbookSelectedSectionSettings(cellContentId,handbook)

    var sectionList : MutableList<HandbookSection> = mutableListOf()

    var delegate : HandbookChapterCellContentDelegate? = null

    init
    {
        name.text = chapter.name
        sectionList.addAll(chapter.sections)

        reloadData()

        if (at == 0)
            upButton.hidden = true
        if (at >= numberOfChapters - 1)
            downButton.hidden = true

        if (editable)
        {
            upButton.onclick = { delegate?.clickedUp(at) }
            downButton.onclick = { delegate?.clickedDown(at) }
            deleteButton.onclick = { delegate?.clickedDelete(at) }

            createSectionButton.onclick = { clickedCreateSection() }
            selectedSection.deleteButton.onclick = { clickedDeleteSection() }
            selectedSection.upButton.onclick = { clickedUpSection() }
            selectedSection.downButton.onclick = { clickedDownSection() }
        }
        else
        {
            name.disabled = true

            upButton.hidden = true
            downButton.hidden = true
            deleteButton.hidden = true
            createSectionButton.hidden = true
            sectionName.hidden = true

            if (sectionList.isEmpty())
            {
                val sectionsHeader = Div(document,"$cellContentId-sections-header")
                sectionsHeader.hidden = true
            }
        }

        selectedSection.editable = editable

        //name.textChanged = { chapter.name = it; label.innerText = it }
    }

    fun clear()
    {
        upButton.onclick = null
        downButton.onclick = null
        deleteButton.onclick = null

        createSectionButton.onclick = null
        selectedSection.deleteButton.onclick = null
        selectedSection.upButton.onclick = null
        selectedSection.downButton.onclick = null

        delegate = null

        selectedSection.clear()
    }

    fun clickedCreateSection()
    {
        val name = sectionName.text
        if (name.isEmpty()) return

        val section = HandbookSection(name)
        sectionList.add(section)
        sectionName.text = ""
        reloadData()
    }

    fun clickedDeleteSection()
    {
        val section = selectedSection.current ?: return
        deleteSection(section)
    }

    fun clickedUpSection()
    {
        if (currentSection == -1) return
        clickedSectionUp(currentSection)
    }

    fun clickedDownSection()
    {
        if (currentSection == -1) return
        clickedSectionDown(currentSection)
    }

    fun deleteSection(section:HandbookSection)
    {
        sectionList.remove(section)
        clearCurrentSectionSelection()
        reloadData()
    }

    fun clickedSectionUp(at:Int)
    {
        val section = sectionList.removeAt(at)
        sectionList.add(at-1,section)
        reloadData()
        clickedSection(at-1)
    }

    fun clickedSectionDown(at:Int)
    {
        val section = sectionList.removeAt(at)
        sectionList.add(at+1,section)
        reloadData()
        clickedSection(at+1)
    }

    var currentSection : Int = -1

    fun clickedSection(index:Int)
    {
        if (currentSection != -1)
            HandbookSettingsSectionCell.updateStyle(at,currentSection,false)

        if (index == currentSection)
        {
            clearCurrentSectionSelection()
            HandbookSettingsSectionCell.updateStyle(at,index,false)
        }
        else
        {
            currentSection = index
            val section = sectionList[index]
            selectedSection.reload(section,this)
            selectedSection.container.style.display = "block"
            HandbookSettingsSectionCell.updateStyle(at,index,true)
            if (editable)
            {
                if (index != 0)
                    selectedSection.upButton.hidden = false
                if (index < sectionList.count() - 1)
                    selectedSection.downButton.hidden = false
            }
        }
    }

    fun clearCurrentSectionSelection()
    {
        selectedSection.container.style.display = "none"
        currentSection = -1
        selectedSection.clear()
    }

    override fun reloadData()
    {
        sections.reloadData()

        for ((index,_) in sectionList.withIndex())
        {
            val li = document.getElementById("section-cell-$at-$index") as HTMLLIElement
            li.onclick = { clickedSection(index) }
        }
    }

    override val numberOfRows : Int get() { return sectionList.count() }

    override fun cellForRow(at:Int) : String
    {
        val section = sectionList[at]
        val name = section.name
        return HandbookSettingsSectionCell.markup(name,this.at,at,at==currentSection)
    }

    open fun update(cell:HandbookChapterCellContent?)
    {
        val cell = cell ?: return
        name.text = cell.name.text
        sectionList.clear()
        sectionList.addAll(cell.sectionList)
        reloadData()
    }
}

class HandbookOffshoreLoadingChapterCellContent(editable:Boolean,handbook:Handbook,chapter:HandbookChapter,at:Int,numberOfChapters:Int) : HandbookChapterCellContent(editable,handbook,chapter,at,numberOfChapters)
{
    val zone = EditText(document,"$cellContentId-zone","Zone","text",false)
    val atDestination = Switch(document,"$cellContentId-at-destination","At Destination")
    val visibleInTimeline = Switch(document,"$cellContentId-visible-in-timeline","Visible in Timeline")
    val loadingSystem = InstallationLoadingSystemSelector(document,"$cellContentId-loading_system","Loading System")

    init
    {
        zone.text = chapter.zone ?: ""
        atDestination.checked = chapter.atDestination
        visibleInTimeline.checked = chapter.visibleInTimeline
        loadingSystem.selectedLoadingSystem = InstallationLoadingSystem.from(chapter.loadingSystem ?: "")

        if (editable)
        {

        }
        else
        {
            zone.disabled = true
            atDestination.disabled = true
            visibleInTimeline.disabled = true
            loadingSystem.disabled = true
        }
    }

    override fun update(cell:HandbookChapterCellContent?)
    {
        val cell = cell as? HandbookOffshoreLoadingChapterCellContent ?: return
        super.update(cell)
        zone.text = cell.zone.text
        atDestination.checked = cell.atDestination.checked
        visibleInTimeline.checked = cell.visibleInTimeline.checked
        loadingSystem.selectedLoadingSystem = cell.loadingSystem.selectedLoadingSystem
    }
}

class HandbookSelectedSectionSettings(val cellContentId:String, override val handbook:Handbook) : ProcedureConfig
{
    val container = document.getElementById("$cellContentId-selected_section") as HTMLDivElement
    val name = EditText(document,"$cellContentId-section_name","Name of Section","text",true)
    val headerText = EditText(document,"$cellContentId-section_header","Header Text","text")
    val headerType = SectionHeaderTypeSelector(document,"$cellContentId-section_header_type","Header Type")
    val headerPreview = document.getElementById("$cellContentId-section_header_preview") as HTMLDivElement
    val conditionalRequirementType = ConditionalRequirementsTypeSelector(document,"$cellContentId-section_conditionals_type","Type")
    val conditionalRequirementText = EditText(document,"$cellContentId-section_conditionals_n","N","text")

    val deleteButton = Button(document,"$cellContentId-delete_section_button",tooltip="Delete selected section")
    val upButton = Button(document,"$cellContentId-up_section_button",tooltip="Move selected section up")
    val downButton = Button(document,"$cellContentId-down_section_button",tooltip="Move selected section down")

    var current : HandbookSection? = null

    var delegate : HandbookSelectedSectionSettingsDelegate? = null

    var editable : Boolean = false

    override val tripId: String? get() = null

    val noHeaderMarkup : String
    get()
    {
        return "<center>No Header</center>"
    }

    fun reload(section:HandbookSection,delegate:HandbookSelectedSectionSettingsDelegate)
    {
        clear()

        this.delegate = delegate

        name.text = section.name

        val header = section.header
        if (header != null)
        {
            headerText.text = header.text
            headerType.selected = header.type
            headerPreview.innerHTML = BlurbsView.markup(this,listOf(header),BlurbPlacement.Headers())
        }
        else
        {
            headerText.text = ""
            headerType.selected = BlurbType.Text
            headerPreview.innerHTML = noHeaderMarkup
        }

        val conds = section.conditional
        if (conds != null)
        {
            conditionalRequirementType.selectedType = conds.type
            conditionalRequirementText.text = conds.x.toString()
        }
        else
        {
            conditionalRequirementType.selectedType = ConditionalRequirementType.Always
            conditionalRequirementText.text = ""
        }

        current = section

        if (!editable)
        {
            deleteButton.hidden = true
            upButton.hidden = true
            downButton.hidden = true
            headerText.disabled = true
            headerType.disabled = true
            name.disabled = true
            conditionalRequirementText.disabled = true
            conditionalRequirementType.disabled = true
        }

        attach()
    }

    fun clear()
    {
        dettach()
        current = null
        upButton.hidden = true
        downButton.hidden = true
    }

    fun attach()
    {
        if (editable)
        {
            name.textChanged = { current?.name = it; delegate?.reloadData() }
            headerText.textChanged = { updateHeader(it, headerType.selected) }
            headerType.valueChanged = { updateHeader(headerText.text, headerType.selected) }
            conditionalRequirementText.textChanged = { updateConditionals(conditionalRequirementType.selectedType, it) }
            conditionalRequirementType.valueChanged = { updateConditionals(conditionalRequirementType.selectedType, conditionalRequirementText.text) }
        }
    }

    fun dettach()
    {
        name.textChanged = null
        headerText.textChanged = null
        headerType.valueChanged = null
        conditionalRequirementText.textChanged = null
        conditionalRequirementType.valueChanged = null
        delegate = null
    }

    fun updateHeader(text:String,type:BlurbType)
    {
        val cur = current ?: return
        val header = cur.header ?: Blurb(type,text)
        header.text = text
        header.type = type
        cur.header = header
        if (text.isNotEmpty())
            headerPreview.innerHTML = BlurbsView.markup(this,listOf(header),BlurbPlacement.Headers())
        else
            headerPreview.innerHTML = noHeaderMarkup
    }

    fun updateConditionals(type:ConditionalRequirementType,n:String)
    {
        val cur = current ?: return
        val conditional = cur.conditional ?: ConditionalRequirements(type)
        conditional.type = type

        val intN = n.toIntOrNull()
        if (intN != null)
            conditional.x = intN

        cur.conditional = conditional
    }
}

interface HandbookChapterCellContentDelegate
{
    fun clickedUp(at:Int)
    fun clickedDown(at:Int)
    fun clickedDelete(at:Int)
}

interface HandbookSelectedSectionSettingsDelegate
{
    fun reloadData()
}

class HandbookSettingsSectionCell
{
    companion object
    {
        fun markup(name:String,chapterIndex:Int,sectionIndex:Int,isSelected:Boolean) : String
        {
            val sel = if(isSelected) "**" else ""
            return """
                <li class="mdc-list-item" id="section-cell-$chapterIndex-$sectionIndex">
                    <span class="mdc-list-item__text">$name</span> <span id="section-span-selected-$chapterIndex-$sectionIndex">$sel</span>
                </li>
                """
        }

        fun updateStyle(chapterIndex:Int,sectionIndex:Int,selected:Boolean)
        {
            val span = document.getElementById("section-span-selected-$chapterIndex-$sectionIndex") as HTMLSpanElement
            if (!selected)
                span.innerText = ""
            else
                span.innerText = "**"
        }
    }
}

private fun HandbookChapter.hasChanged(old:HandbookChapter) : Boolean
{
    if (name != old.name) return true
    if (zone != old.zone) return true
    if (visibleInTimeline != old.visibleInTimeline) return true
    if (loadingSystem != old.loadingSystem) return true
    if (atDestination != old.atDestination) return true
    if (sections.count() != old.sections.count()) return true
    for ((index,section) in sections.withIndex())
        if (section.hasChanged(old.sections[index]))
            return true
    return false
}

private fun HandbookSection.hasChanged(old:HandbookSection) : Boolean
{
    if (name != old.name) return true
    if (header != old.header) return true
    if (conditional != old.conditional) return true
    return false
}

class CreateHandbookScreen(document:Document) : HandbookScreen(document,Handbook("temp","",false))
{
    init
    {
        deleteButton.hidden = true
    }

    override fun reloadTitle()
    {
        navbar.title = "Create Handbook"
    }

    override fun refresh()
    {
        val settings = HandbookSettingsResponse()
        settings.isPublished = false
        loading = false
        refreshed(settings)
    }

    override fun save()
    {
        val empty = HandbookSettingsResponse()
        val updates = updates(empty)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.createHandbook(this,data)
        {
            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to create handbook")
            }
            else
            {
                showToast(it,"Handbook created!")
                pushTo("handbooks")
            }
        }
    }
}