package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.FilterByNameModel
import main.kotlin.data.Ship
import main.kotlin.data.ShipsResponse
import main.kotlin.ui.*
import org.w3c.dom.Document

class ShipsScreen(document:Document) : TopNavBarScreen(document),TilesViewDelegate,FilterByNameModel
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Ships

    val list = TilesView(document,this,"list")

    override val searchButton = Button(document,"search_button",tooltip=activeTab.searchHint)
    val createButton = Button(document,"create_button",tooltip="Create a new vessel")
    val filters = ShipsFilters(document,"filters")

    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=ships" }

    override fun start()
    {
        super.start()

        loading = false

        createButton.onclick = { create() }

        filters.start()
        {
            changedFilter()
        }

        refresh()
    }

    fun create()
    {
        pushTo("ship")
    }

    fun refresh()
    {
        loading = true

        Requests.listShips(this)
        {
            loading = false
            refreshed(it)
        }
    }

    var allships : Array<Ship> = arrayOf()
    var ships : Array<Ship> = arrayOf()

    override fun searchTextChanged(text:String)
    {
        filters.query = text
        changedFilter()
    }

    private fun changedFilter()
    {
        val shouldFilter = filters.query.isNotEmpty() || filters.type != null || filters.classe != null || filters.region != null

        if (!shouldFilter)
            ships = allships
        else
        {
            val query = filters.query.toLowerCase()
            fun includeItem(item:Ship) : Boolean
            {
                val hasQ = matchesSearch(query,item)
                val hasType = if (filters.type != null) item.type == filters.type else true
                val hasClass = if (filters.classe != null) item.classe == filters.classe else true
                val hasRegion = if (filters.region != null) item.region == filters.region else true
                return hasQ && hasType && hasClass && hasRegion
            }
            ships = allships.filter { includeItem(it) }.toTypedArray()
        }
        list.reloadData()
    }

    fun refreshed(it:ShipsResponse)
    {
        allships = it.ships
        searchTextChanged(if (searching) topnavbar.searchbar.text else "")
    }

    override val numberOfItems: Int get() { return ships.count() }

    override fun itemDisabled(at:Int) : Boolean { return ships[at].disabled }

    override fun titleForItem(at:Int) : String
    {
        return ships[at].name
    }

    override fun detailForItem(at: Int): String
    {
        return ships[at].type
    }

    override fun iconForItem(at:Int) : String
    {
        return "directions_boat"
    }

    override fun onclickForItem(at: Int): String
    {
        val sid = ships[at].id
        return "window.location.href='ship.html?id=$sid'"
    }
}

class ShipsFilters(document:Document,val id:String)
{
    var query : String = ""
    var type : String? = null
    var classe : String? = null
    var region : String? = null

    private val typeSelector : ShipTypeSelector
    private val classSelector : ShipClassSelector
    private val regionSelector : ShipRegionSelector
    private val container = Div(document,id)

    fun start(changedFilter:()->Unit)
    {
        typeSelector.selected = type
        typeSelector.valueChanged = { type = typeSelector.selected; changedFilter.invoke() }

        classSelector.selected = classe
        classSelector.valueChanged = { classe = classSelector.selected; changedFilter.invoke() }

        regionSelector.selected = region
        regionSelector.valueChanged = { region = regionSelector.selected; changedFilter.invoke() }
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
        if (!value)
        {
            typeSelector.selected = type
            classSelector.selected = classe
            regionSelector.selected = region
        }
    }

    val markup : String
    get()
    {
        return """
       <div class="mdc-select edit" id="${id}_type"></div>
       <div class="mdc-select edit" id="${id}_class"></div>
       <div class="mdc-select edit" id="${id}_region"></div>
    """
    }

    init
    {
        container.html = markup
        typeSelector = ShipTypeSelector(document,"${id}_type","Type", listOf("Shuttle Tanker","Test"))
        classSelector = ShipClassSelector(document,"${id}_class","Class", listOf("Explorer","Heritage","Samba"))
        regionSelector = ShipRegionSelector(document,"${id}_region","Region", listOf("North Sea","ECC","Brazil"))
    }
}