package main.kotlin.data

data class ArchivedOperation(val id:String="",
                             val operationType:OperationType=OperationType.None,
                             val shipId:String="",
                             val tripId:String="",
                             val handbookId:String?=null,
                             val handbookName:String?=null,
                             val handbookVersion:String?=null,
                             val destinationId:String?=null,
                             val destinationName:String?=null,
                             val voyageNumber:String?=null,
                             val completion:Event=Event(),
                             val initiation:Event=Event()

)
{
    constructor(dyn:dynamic) : this(
                dyn.id as String,
                OperationType.from(dyn.operation_type as? Int ?: 0),
                dyn.ship_id as String,
                dyn.trip_id as String,
                dyn.handbook_id as? String,
                dyn.handbook_name as? String,
                dyn.handbook_version as? String,
                dyn.destination_id as? String,
                dyn.destination_name as? String,
                dyn.voyage_number as? String,
                Event(dyn=dyn.completion),
                Event(dyn=dyn.initiation)
    )
}
