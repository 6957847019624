package main.kotlin.data

data class User(val id:String="",var firstName:String="",var lastName:String="",var email:String="",var pin:String="",var role : UserRole = UserRole.None,var shipMaster:String?=null,var tkid:String="") : NamedItem
{
    constructor(dyn:dynamic) : this(dyn.id as String,dyn.firstName as String,dyn.lastName as String,dyn.email as? String ?: "",dyn.pin as? String ?: "",UserRole.from(dyn.role as Int),dyn.ship_master as? String,dyn.tkid as? String ?:"")

    override val name : String
    get()
    {
        return "$firstName $lastName"
    }

    fun isShipMaster(shipId:String?) : Boolean
    {
        return when(role)
        {
            UserRole.Admin,UserRole.SuperAdmin,UserRole.VesselMaster -> true
            UserRole.None -> false
            UserRole.Normal ->
            {
                if (shipId == null)
                    false
                else
                    shipMaster == shipId
            }
        }
    }

    val isAdmin : Boolean
    get()
    {
        return role.isAdmin
    }
}

enum class UserRole(val value:Int)
{
    None(0),
    Normal(1),
    Admin(2),
    SuperAdmin(3),
    VesselMaster(4);

    companion object
    {
        fun from(value: Int) : UserRole
        {
            when (value)
            {
                0 -> return None
                1 -> return Normal
                2 -> return Admin
                3 -> return SuperAdmin
                4 -> return VesselMaster
            }
            return None
        }
    }

    val title : String
    get()
    {
        return when (this)
        {
            None -> "Inactive"
            Normal -> "Normal"
            Admin -> "Admin"
            SuperAdmin -> "Super Admin"
            VesselMaster -> "Vessel Master"
        }
    }

    val isAdmin : Boolean
    get()
    {
        return this == UserRole.Admin || this == UserRole.SuperAdmin
    }
}
