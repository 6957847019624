package main.kotlin.data

data class FeedbackItem(val id:String="",val ship:String="",val text:String="",val uid:String="",val by:String,val timestamp:UnixTime=0.0,val status:FeedbackStatus=FeedbackStatus.Unknown)
{
    var resolved : Event? = null

    constructor(dyn:dynamic) : this(dyn.id as String,dyn.ship as String,dyn.text as String,dyn.uid as String,dyn.by as String ,dyn.timestamp as UnixTime,FeedbackStatus.from(dyn.status as Int))
    {
        val r = dyn.resolved
        if (r != null)
            resolved = Event(dyn=r)
    }
}

data class FeedbackDetails(val id:String="",val ship:String="",val text:String="",val uid:String="",val by:String,val timestamp:UnixTime=0.0,val status:FeedbackStatus=FeedbackStatus.Unknown)
{
    var resolved : Event? = null

    var comments : Array<Event> = arrayOf()

    constructor(dyn:dynamic) : this(dyn.id as String,dyn.ship as String,dyn.text as String,dyn.uid as String,dyn.by as String ,dyn.timestamp as UnixTime,FeedbackStatus.from(dyn.status as Int))
    {
        val r = dyn.resolved
        if (r != null)
            resolved = Event(dyn=r)

        val comms : MutableList<Event> = mutableListOf()
        val cdr = dyn.comments as? Array<Any>
        if (cdr != null)
        {
            for (hd in cdr)
            {
                comms.add(Event(dyn=hd.asDynamic()))
            }
        }
        comments = comms.toTypedArray()
    }
}

enum class FeedbackStatus(val value:Int)
{
    Unknown(0),
    Created(1),
    Submitted(2),
    Resolved(3);

    companion object
    {
        fun from(value: Int) : FeedbackStatus
        {
            when (value)
            {
                0 -> return Unknown
                1 -> return Created
                2 -> return Submitted
                3 -> return Resolved
            }
            return Unknown
        }
    }

    val title : String
    get()
    {
        return when (this)
        {
            Unknown -> "Unknown"
            Created -> "Created"
            Submitted -> "Submitted"
            Resolved -> "Resolved"
        }
    }
}