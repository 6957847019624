package main.kotlin.models

import org.w3c.dom.Document
import main.kotlin.ui.*
import main.kotlin.data.*
import main.kotlin.comms.Requests
import kotlin.browser.window

class AppsScreen(document:Document) : TopNavBarScreen(document)
{
    val alert = Alert(document)

    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=manuals" }

    override val activeTab: TopNavBarTab get() = TopNavBarTab.Apps

    val appPanel = Div(document,"app_panel")
    val configPanel = Div(document, "app_config")

    val minDocCount = EditText(document, "min_document_count", "Minimum Document Count")     
    val minVersion = EditText(document, "min_version", "Minimum Version")    
    val installUrl = EditText(document, "install_url", "Installation URL")
    val saveButton = Button(document, "save_button")

    val qrContainer : Div
    val installButton : Button

    var config : AppConfig? = null

    init
    {
        var html = ""
        for (app in Config.apps)
        {
            html += """
            <h1>${app.name}</h1>
            
            <div id="qr_container">
                <div id="qrcode" style="width:164px; height:164px;"></div> 
                <br/>
                <button class="mdc-button mdc-button with-ripple" id="install_button">Install</button>
             </div>
            """
        }
        appPanel.html = html

        qrContainer = Div(document, "qr_container")
        installButton = Button(document, "install_button")

        installButton.onclick = { install() }
        saveButton.onclick = { save() }
    }

    override fun start()
    {
        super.start()

        if (!isAuthenticated)
            return

        refresh()
    }

    fun refresh()
    {
        loading = true

        Requests.getAppConfig(this,"seapad")
        {
            loading = false
            val config = it.config
            if (config != null)
            {
                //saveButton.hidden = false
                refreshed(config)
                //hasChanges = false
            }
            else
            {
                content.hidden = true
                error.hidden = false
                //saveButton.hidden = true
                error.text = "Failed to load config details"
            }
        }
    }

    fun refreshed(config:AppConfig)
    {
        this.config = config

        val url = config.url ?: ""
        minDocCount.text = "${config.minimumDocumentCount}"
        minVersion.text = config.minimumVersion ?: ""
        installUrl.text = url

        if (url.length > 0)
        {
            qrContainer.hidden = false
            updateQRCode(url)
        }
        else
        {
            qrContainer.hidden = true
        }
    }

    fun updateQRCode(url:String)
    {
        js("""
            updateQRCode(url);
        """)
    }

    fun install()
    {
        val url = config?.url ?: return
        if (url.length == 0) return
        window.open(url, "_blank")
    }

    fun updates(config:AppConfig) : ItemUpdates
    {
        val updates = Any().asDynamic()
        var hasChanges = false

        fun addIfChanged(named:String,current:Int,change:String)
        {
            if (change.isBlank())
            {
                updates[named] = null
            }
            else
            {
                val changeI = change.toInt()
                if (current != changeI)
                {
                    updates[named] = changeI
                    hasChanges = true
                }
            }
        }

        fun addIfChanged(named:String,current:String?,change:String)
        {
            if (current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        addIfChanged("minimum_document_count",config.minimumDocumentCount,minDocCount.text)
        addIfChanged("minimum_version",config.minimumVersion,minVersion.text)
        addIfChanged("url",config.url,installUrl.text)

        if (minDocCount.text.isBlank())
            return ItemUpdates(error="Minimum Document Count cannot be blank")

        if (hasChanges)
            return ItemUpdates(data=updates)

        return ItemUpdates(error="No changes to save")
    }

    fun save()
    {
        val config = config ?: return

        val updates = updates(config)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.updateAppConfig(this,"seapad",data)
        {
            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to update config")
            }
            else
            {
                showToast(it,"App config updated!")
                refresh()
            }
        }
    }
}
