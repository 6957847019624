package main.kotlin.data

import fromBase64
import main.kotlin.utils.ObjectHelper
import toBase64
import kotlin.js.Json

class BaseShip(val id:String,val name:String)
{
    companion object
    {
        fun from(params: String): BaseShip?
        {
            val b64 = decodeURIComponent(params)
            val json = b64.fromBase64()
            val obj = JSON.parse<Json>(json)
            val id = obj["id"] as? String ?: return null
            val name = obj["name"] as? String ?: return null
            return BaseShip(id, name)
        }
    }
}

data class Ship(override val id:String="") : IdentifiedItem
{
    constructor(dyn:dynamic) : this(dyn.id as String)
    {
        name = dyn.name as String
        type = dyn.type as? String ?: ""
        val cl = dyn.`class` as? String
        if (cl != null)
            classe = cl

        val rg = dyn.region as? String
        if (rg != null)
            region = rg

        val vme = dyn.vessel_master_email as? String
        if (vme != null)
            vesselMasterEmail = vme

        val hbs = dyn.handbooks as? Array<String>
        if (hbs != null)
        {
            handbooks.addAll(hbs)
        }

        if (dyn.operations != null)
        {
            val keys = ObjectHelper.getKeys(dyn.operations)
            if (keys != null)
            {
                for (key in keys)
                {
                    val value = dyn.operations[key]
                    val state = ShipOperationState(dyn = value)
                    operations[key] = state
                }
            }
        }

        val disabled = dyn.disabled as? Boolean
        if (disabled != null)
            this.disabled = disabled
    }

    val params : String
    get()
    {
        val d = Any().asDynamic()
        d.id = id
        d.name = name
        val json = JSON.stringify(d)
        val b64 = json.toBase64()
        return encodeURIComponent(b64)
    }

    override var name = ""
    var type = ""
    var classe = ""
    var region = ""
    var disabled = false

    var vesselMasterEmail = ""

    var handbooks : MutableList<String> = mutableListOf()

    fun supports(handbook:String) : Boolean
    {
        return handbooks.contains(handbook)
    }

    var operations = HashMap<String,ShipOperationState>()

    fun state(handbook:Handbook?) : ShipOperationState?
    {
        return state(handbook?.id)
    }

    fun state(handbookId:String?) : ShipOperationState?
    {
        val id = handbookId ?: return null
        var st = operations[id]
        if (st != null) { return st }
        st = ShipOperationState()
        operations[id] = st
        return st
    }
}

open class ShipOperationState
{
    constructor()

    constructor(dyn:dynamic)
    {
        val started = dyn.initiated
        if (started!=null)
            initiated = Event(dyn=started)

        destination = dyn.destination as? String
        destinationName = dyn.destination_name as? String
        trip = dyn.trip as? String
        loads = dyn.loads as? Int ?: 0
        voyageNumber = dyn.voyage_number as? String
    }

    var initiated : Event? = null
    var destination : String? = null
    var destinationName : String? = null
    var voyageNumber : String? = null

    var trip : String? = null
    var loads = 0 // Don't clear this

    fun clear()
    {
        initiated = null
        destination = null
        trip = null
        voyageNumber = null
    }
}

data class ShipType(override val name:String) : NamedItem
data class ShipClass(override val name:String) : NamedItem
data class ShipRegion(override val name:String) : NamedItem

data class ShipTrip(val ship:Ship,val id:String,val destination:Installation?)
