package main.kotlin.ui

import extensions.getLastPathComponent
import extensions.getLink
import main.kotlin.data.*

class BlurbsView
{
    companion object
    {
        fun markup(config:ProcedureConfig,blurbs:List<Blurb>,placement:BlurbPlacement) : String
        {
            var html = ""
            for (blurb in blurbs)
            {
                val editingClass = if (blurb.isEditing(placement)) " editing" else ""
                when(placement)
                {
                    is BlurbPlacement.Content -> html += "<div class='procedure-step-content-blurb$editingClass' id='blurb_content_${blurb.id}'>"
                    is BlurbPlacement.StepHeader -> { html += "<div class='procedure-step-header-blurb$editingClass' id='blurb_step-header_${blurb.id}'>" }
                    is BlurbPlacement.Headers -> html += "<div class='procedure-header-blurb$editingClass' id='blurb_header_${blurb.id}'>"
                    is BlurbPlacement.Footers -> html += "<div class='procedure-footer-blurb$editingClass' id='blurb_footer_${blurb.id}'>"
                }
                html += blurb.markup(config,placement)
                html += "</div>"
            }
            return html
        }
    }
}

sealed class BlurbPlacement
{
    class Headers(val editing:List<String>?=null) : BlurbPlacement()
    class Footers(val editing:List<String>?=null) : BlurbPlacement()
    class StepHeader(val step:ProcedureItem,val editing:List<String>?=null) : BlurbPlacement()
    class Content(val step:ProcedureItem,val editing:List<String>?=null,val choiceEditing:List<String>?=null) : BlurbPlacement()
    class Choice(val step:ProcedureItem,val choice:BlurbChoice,val editing:List<String>?=null) : BlurbPlacement()

    fun isEditing(id:String,choiceBlurb:Boolean=false) : Boolean
    {
        if (choiceBlurb)
        {
            return when (this)
            {
                is Headers -> { false}
                is Footers -> { false}
                is StepHeader -> { false }
                is Content -> { choiceEditing?.contains(id) ?: false}
                is Choice -> { editing?.contains(id) ?: false}
            }
        }

        return when (this)
        {
            is Headers -> { editing?.contains(id) ?: false}
            is Footers -> { editing?.contains(id) ?: false}
            is StepHeader -> { editing?.contains(id) ?: false}
            is Content -> { editing?.contains(id) ?: false}
            is Choice -> { editing?.contains(id) ?: false}
        }
    }

    fun canBeStepHeader(blurb:Blurb) : Boolean
    {
        return when (this)
        {
            is Headers -> { false }
            is Footers -> { false }
            is StepHeader -> { true }
            is Content -> { step.step != 0 && BlurbType.stepHeaderTypes.contains(blurb.type) }
            is Choice -> { false }
        }
    }

    fun getStep() : ProcedureItem?
    {
        return when (this)
        {
            is Headers -> null
            is Footers -> null
            is Content -> step
            is StepHeader -> step
            is Choice -> step
        }
    }

    fun getChoice() : BlurbChoice?
    {
        return when(this)
        {
            is Headers -> null
            is Footers -> null
            is Content -> null
            is StepHeader -> null
            is Choice -> choice
        }
    }

    fun getBlurbs(procedure:Procedure) : MutableList<Blurb>
    {
        return when(this)
        {
            is Headers -> procedure.headers
            is Footers -> procedure.footers
            is Content -> step.blurbs
            is StepHeader -> step.headers
            is Choice -> choice.blurbs
        }
    }

    val title : String
    get()
    {
        return when(this)
        {
            is Headers -> "Header"
            is Footers -> "Footer"
            is StepHeader -> "Item Header"
            is Content -> "Item Content"
            is Choice -> "Choice"
        }
    }
}

fun Blurb.isEditing(placement:BlurbPlacement) : Boolean
{
    return placement.isEditing(id)
}

fun Blurb.isChoiceEditing(placement:BlurbPlacement?) : Boolean
{
    return placement?.isEditing(id,true) ?: false
}

fun ProcedureItem.isEditing(placement:BlurbPlacement) : Boolean
{
    return placement.isEditing(id)
}

fun getText(text:String,config:ProcedureConfig) : String
{
    var t = text.replace("\n","<br/>")
    t = t.replace("\t","&nbsp;&nbsp;&nbsp;&nbsp;")
    t = t.replace("    ","&nbsp;&nbsp;&nbsp;&nbsp;")
    while (t.contains("handbooks://procedure/"))
    {
        val index = t.indexOf("handbooks://procedure/")
        if (index >= 0)
        {
            val link = t.getLink(index)
            t = t.replace(link,"procedure.html?handbook=${config.handbook.params}&id=${link.getLastPathComponent()}")
        }
        else
        {
            break
        }
    }
    t = Handbook.tailorProcedureText(t,config.trip)
    return t
}

fun Blurb.getText(config:ProcedureConfig) : String
{
    return getText(text,config)
}

fun Blurb.markup(config:ProcedureConfig,placement:BlurbPlacement) : String
{
    return when(placement)
    {
        is BlurbPlacement.StepHeader -> stepHeaderMarkup(config)
        is BlurbPlacement.Headers -> markup(config,null)
        is BlurbPlacement.Footers -> markup(config,null)
        is BlurbPlacement.Content -> markup(config,placement.step,placement)
        is BlurbPlacement.Choice -> markup(config,placement.step,placement)
    }
}

data class BlurbMarkupConfig(val config:ProcedureConfig,val step:ProcedureItem?,val placement:BlurbPlacement?)
{
    var disableGotoLinks = false
}

fun Blurb.markup(config:ProcedureConfig,step:ProcedureItem?,placement:BlurbPlacement?=null) : String
{
    return markup(BlurbMarkupConfig(config,step,placement))
}

fun Blurb.markup(config:BlurbMarkupConfig) : String
{
    val text = getText(config.config)
    return when(type)
    {
        BlurbType.Info -> """
            <div class='blurb-notice blurb-notice-info'>
                <img src="static/media/baseline-info-24px.svg"/>
                <p>$text</p>
            </div>
        """

        BlurbType.InfoUser -> """
            <div class='blurb-notice blurb-notice-info'>
                <img src="static/media/baseline-group-24px.svg"/>
                <p>$text</p>
            </div>
        """

        BlurbType.Note -> """
            <div class='blurb-notice blurb-notice-note'>
                <img src="static/media/baseline-group-24px.svg"/>
                <div class='blurb-notice-title'>Note</div>
                <p>$text</p>
            </div>
        """
        BlurbType.Warning -> """
            <div class='blurb-notice blurb-notice-warning'>
                <img src="static/media/baseline-warning-24px.svg"/>
                <div class='blurb-notice-title'>Warning</div>
                <p>$text</p>
            </div>
        """
        BlurbType.Caution -> """
            <div class='blurb-notice blurb-notice-caution'>
                <img src="static/media/baseline-info-24px.svg"/>
                <div class='blurb-notice-title'>Caution</div>
                <p>$text</p>
            </div>
        """
        BlurbType.Choices ->
        {
            var html = "<p>$text</p>"

            html += "<div class='blurb-choices'>"
            val last = choices.lastOrNull()
            for (choice in choices)
            {
                val isLast = last == choice

                html += "<div class='blurb-choice'>"
                if (!isLast)
                    html += "<div class='blurb-choice-line'></div>"

                html += "<div class='blurb-choice-diamond'></div>"
                html += "<div class='blurb-choice-content'>"
                html += "<p>${choice.title}</p>"
                for (blurb in choice.blurbs)
                {
                    val editingClass = if (blurb.isChoiceEditing(config.placement)) " editing" else ""
                    html += """<div class='blurb-choice-content-blurb$editingClass'>${blurb.markup(config)}</div>"""
                }
                html += "</div>"
                html += "</div>"
            }
            html += "</div>"
            html
        }
        BlurbType.Image ->
        {
            """<img class="blurb-image" src="${Config.url}/images/$text"/>"""
        }
        BlurbType.Goto ->
        {
            var onclick = ""
            val link = link
            if (link != null)
            {
                val lnk = getText(link, config.config)
                when
                {
                    lnk == "step=next" ->
                    {
                        val oldStep = config.step?.step ?: -1
                        val step = oldStep + 1
                        onclick = "'openStep($oldStep,false); openStep($step,true);'"
                    }
                    lnk == "step=previous" ->
                    {
                        val oldStep = config.step?.step ?: -1
                        val step = oldStep - 1
                        onclick = "'openStep($oldStep,false); openStep($step,true);'"
                    }
                    lnk.startsWith("step=") ->
                    {
                        val oldStep = config.step?.step ?: -1
                        val step = lnk.removePrefix("step=").toInt()
                        onclick = "'openStep($oldStep,false); openStep(${step-1},true);'"
                    }
                    else -> onclick = "window.location.href='$lnk';"
                }
            }
            if (config.disableGotoLinks)
                onclick = ""
            """
            <div class='blurb-goto'>
                <div class="blurb-goto-caption">Go to</div>
                <button class="mdc-button mdc-button with-ripple blurb-goto-button" id="blurb-goto-button" onclick=$onclick>$text</button>
            </div>
            """
        }
        BlurbType.InputText ->
        {
            """<p>$text</p><div class="mdc-text-field edit" id="$id"></div>"""
        }
        BlurbType.Text -> "<p>$text</p>"
    }
}

fun Blurb.stepHeaderMarkup(config:ProcedureConfig) : String
{
    val text = getText(config)
    return when(type)
    {
        BlurbType.Info -> """
        <img src="static/media/baseline-info-24px-step-header.svg"/>
        <div class='blurb-notice-step-header blurb-notice-info'>
            <p>$text</p>
        </div>
    """

        BlurbType.InfoUser-> """
        <img src="static/media/baseline-group-24px-step-header.svg"/>
        <div class='blurb-notice-step-header blurb-notice-info'>
            <p>$text</p>
        </div>
    """

        BlurbType.Note -> """
        <img src="static/media/baseline-group-24px-step-header.svg"/>
        <div class='blurb-notice-step-header blurb-notice-note'>
            <div class='blurb-notice-title'>Note</div>
            <p>$text</p>
        </div>
    """
        BlurbType.Warning -> """
        <img src="static/media/baseline-warning-24px-step-header.svg"/>
        <div class='blurb-notice-step-header blurb-notice-warning'>
            <div class='blurb-notice-title'>Warning</div>
            <p>$text</p>
        </div>
    """
        BlurbType.Caution -> """
        <img src="static/media/baseline-caution-24px-step-header.svg"/>
        <div class='blurb-notice-step-header blurb-notice-caution'>
            <div class='blurb-notice-title'>Caution</div>
            <p>$text</p>
        </div>
    """
        else -> ""
    }
}