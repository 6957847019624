package main.kotlin.data

import kotlin.js.Date

data class Blurb(var type : BlurbType = BlurbType.Text, var text:String="",override var destinations:List<String> = listOf(),override var ships:List<String> = listOf(),override var classes:List<String> = listOf(),override var types:List<String> = listOf(),override var regions : List<String> = listOf()) : Specificable
{
    // Text Input

    var hint : String?=null
    var input : String? = null

    fun clear()
    {
        input = null
    }

    // Choices

    var choices : MutableList<BlurbChoice> = mutableListOf()

    // Goto

    var link : String? = null

    // Local

    var id = ""

    constructor(id:String,dyn:dynamic) : this(BlurbType.from(dyn.type as Int),dyn.text as? String ?: "",(dyn.destinations as? Array<String>)?.toList() ?: listOf<String>(),(dyn.ships as? Array<String>)?.toList() ?: listOf<String>(),(dyn.classes as? Array<String>)?.toList() ?: listOf<String>(),(dyn.types as? Array<String>)?.toList() ?: listOf<String>())
    {
        hint = dyn.hint as? String
        input = dyn.input as? String
        link = dyn.link as? String

        val ch = dyn.choices as? Array<Any>
        if (ch != null)
        {
            for ((index,item) in ch.withIndex())
            {
                choices.add(BlurbChoice(id="$id-$index",dyn=item.asDynamic()))
            }
        }

        this.id = id
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.type = type.value
        updates.text = text
        updates.destinations = destinations.toTypedArray()
        updates.ships = ships.toTypedArray()
        updates.classes = classes.toTypedArray()
        updates.types = types.toTypedArray()
        val hint = hint
        if (hint != null)
            updates.hint = hint
        val input = input
        if (input != null)
            updates.input = input
        val link = link
        if (link != null)
            updates.link = link
        if (choices.count() > 0)
            updates.choices = choices.map { it.toDynamic() }
        return updates
    }

    companion object
    {
        fun createId(type:BlurbType,text:String) : String
        {
            return "blurb-${type.value}-${text.hashCode()}-${Date().getTime()}"
        }
    }
}

data class BlurbChoice(val id:String,var title:String)
{
    var blurbs : MutableList<Blurb> = mutableListOf()

    constructor(id:String,dyn:dynamic) : this(id,dyn.title as String)
    {
        val ch = dyn.blurbs as? Array<Any>
        if (ch != null)
        {
            var i = 0
            for (item in ch)
            {
                blurbs.add(Blurb(id="$id-choice-$i",dyn=item.asDynamic()))
                i += 1
            }
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.title = title
        if (blurbs.count() > 0)
            updates.blurbs = blurbs.map { it.toDynamic() }
        return updates
    }
}

enum class BlurbType(val value:Int)
{
    Text(0),

    Info(1),
    InfoUser(2),
    Image(3),
    Choices(4),
    Goto(5),

    Note(10),
    Caution(11),
    Warning(12),

    InputText(20);

    val title : String
    get()
    {
        return when(this)
        {
            Text -> "Text"
            Info -> "Information"
            InfoUser -> "User Information"
            Image -> "Image"
            Choices -> "Decisions"
            Goto -> "Go To"
            Note -> "Note"
            Caution -> "Caution"
            Warning -> "Warning"
            InputText -> "Input Text"
        }
    }

    companion object
    {
        fun from(value: Int) : BlurbType
        {
            when (value)
            {
                0 -> return Text
                1 -> return Info
                2 -> return InfoUser
                3 -> return Image
                4 -> return Choices
                5 -> return Goto
                10 -> return Note
                11 -> return Caution
                12 -> return Warning
                20 -> return InputText
            }
            return Text
        }

        val stepHeaderTypes : Array<BlurbType>
        get()
        {
            return arrayOf(Info, InfoUser, Note, Caution, Warning)
        }

        val headerTypes : Array<BlurbType>
        get()
        {
            return arrayOf(Text, Image, Info, InfoUser, Note, Caution, Warning)
        }

        val footerTypes : Array<BlurbType>
        get()
        {
            return arrayOf(Text, Image, Info, InfoUser, Note, Caution, Warning, Goto)
        }

        val choiceTypes : Array<BlurbType>
        get()
        {
            val values = values().toMutableList()
            values.remove(Choices)
            values.remove(InputText)
            return values.toTypedArray()
        }
    }
}
