package main.kotlin.models.editor

import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document

class ProcedureStepEditor(document: Document, override val handbook: Handbook) : ContainerEditorPanel, ProcedureRequirementsPanel
{
    override val container = Div(document, "procedure_step_editor")
    override val panelType : ContainerEditorPanelType get() { return ContainerEditorPanelType.Step }
    val text = EditTextRich(document,handbook, "step_text", "Enter step title", required = true,config= EditTextRich.Config(false,indentsAllowed = false, formattingAllowed = false))

    private val actionContainer = Div(document,"procedure_step_editor-step_action")
    val actionVerb = EditText(document, "step_action_verb", "Action Button Label", required = true, tooltip = "Enter the text you want to appear on the action button for this step")
    val actionPast = EditText(document, "step_action_past", "Action Button Label (Completed)", tooltip = "Enter the text you want to appear on the action button for this step if the step has been completed")
    val notApplicable = NotApplicableModeSelector(document, "step_action_not_applicable", "Not Applicable Button",tooltip="Specify if this step should be possible to be marked as 'Not Applicable'. Also select if a comment should be required or not, as well as optional or compulsory input from the app user")

    val buttons = ProcedureEditorStepButtons(document, "procedure_step_editor")
    override val specifics = ProcedureSpecificsEditor(document, "procedure_step_editor",requirementsAppliesTo)
    override val requirementsAppliesTo : String get() { return "step" }
    override var options = ProcedureOptions()

    var delegate : ProcedureEditorDelegate? = null

    var current : ProcedureItem? = null

    init
    {

    }

    fun update(data:ProcedureItem)
    {
        dettach()

        text.text = data.name
        actionVerb.text = data.action.verb
        actionPast.text = data.action.past
        notApplicable.selected = data.action.notApplicable

        current = data

        val procedure = delegate?.procedure

        if (procedure != null)
        {
            when(procedure.type)
            {
                ProcedureType.Procedure -> actionContainer.hidden = true
                ProcedureType.Checklist -> actionContainer.hidden = false
            }
        }

        text.reload(options,procedure)

        reloadRequirements(data)

        attach()
    }

    fun update(atTop:Boolean,atBottom:Boolean)
    {
        buttons.update(atTop,atBottom)
    }

    fun clear()
    {
        current = null
        dettach()
    }

    fun changed()
    {
        delegate?.changedStep()
    }

    fun clickedDelete()
    {
        val step = current ?: return
        delegate?.clickedStepButton(ProcedureEditorButtonStepAction.Delete(step))
    }

    fun clickedDown()
    {
        val step = current ?: return
        delegate?.clickedStepButton(ProcedureEditorButtonStepAction.Down(step))
    }

    fun clickedUp()
    {
        val step = current ?: return
        delegate?.clickedStepButton(ProcedureEditorButtonStepAction.Up(step))
    }

    fun clickedCreateContent()
    {
        val step = current ?: return
        delegate?.clickedBlurbButton(ProcedureEditorButtonBlurbAction.Create(BlurbPlacement.Content(step)))
    }

    fun clickedEditRequirements()
    {
        val data = current ?: return
        delegate?.clickedEditRequirements(data)
    }

    fun attach()
    {
        specifics.editButton.onclick = { clickedEditRequirements() }
        buttons.deleteButton.onclick = { clickedDelete() }
        buttons.upButton.onclick = { clickedUp() }
        buttons.downButton.onclick = { clickedDown() }
        buttons.addContentButton.onclick = { clickedCreateContent() }

        text.textChanged = { current?.name = it; changed() }
        actionVerb.textChanged = { actionPast.text = it; current?.action?.past = it; current?.action?.verb = it; changed() }
        actionPast.textChanged = { current?.action?.past = it; changed() }
        notApplicable.valueChanged = { current?.action?.notApplicable = notApplicable.selected; changed() }
    }

    fun dettach()
    {
        specifics.editButton.onclick = null
        buttons.deleteButton.onclick = null
        buttons.upButton.onclick = null
        buttons.downButton.onclick = null
        buttons.addContentButton.onclick = null

        text.textChanged = null
        actionVerb.textChanged = null
        actionPast.textChanged = null
        notApplicable.valueChanged = null
    }
}

class ProcedureEditorStepButtons(document: Document, containerId:String) : ProcedureEditorButtons(document,containerId)
{
    override val deleteTooltip : String get() { return "Delete Step" }
    override val upTooltip : String get() { return "Move Step Up" }
    override val downTooltip : String get() { return "Move Step Down" }

    val addContentButton = Button(document, "$containerId-add_content_button",tooltip="Add Content")
}
