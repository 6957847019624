package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.FilterByNameModel
import main.kotlin.data.User
import main.kotlin.data.UserRole
import main.kotlin.data.UsersResponse
import main.kotlin.ui.*
import org.w3c.dom.Document

class UsersScreen(document:Document) : TopNavBarScreen(document),TilesViewDelegate, FilterByNameModel
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Users

    val list = TilesView(document,this,"list")

    override val searchButton = Button(document,"search_button",tooltip=activeTab.searchHint)
    val createButton = Button(document,"create_button",tooltip="Create a new user")
    val navTitle = Div(document,"navbar_title","inline")
    val filters = UsersFilters(document,"filters")

    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=users" }

    override fun start()
    {
        super.start()

        loading = false

        createButton.onclick = { create() }

        filters.start()
        {
            changedFilter()
        }

        refresh()
    }

    fun create()
    {
        pushTo("user")
    }

    fun refresh()
    {
        loading = true
        Requests.listUsers(this)
        {
            loading = false
            refreshed(it)
        }
    }

    var allusers : Array<User> = arrayOf()
    var users : Array<User> = arrayOf()

    override var searching : Boolean
    get()
    {
        return super.searching
    }
    set(value)
    {
        super.searching = value
        navTitle.hidden = searching
    }

    override fun searchTextChanged(text:String)
    {
        filters.query = text
        changedFilter()
    }

    private fun changedFilter()
    {
        val hasQuery = filters.query.isNotEmpty()
        users = if (filters.role == null && !hasQuery)
            allusers
        else
        {
            if (hasQuery && filters.role != null)
            {
                val query = filters.query.toLowerCase()
                allusers.filter { filters.role == it.role && matchesSearch(query, it) }.toTypedArray()
            }
            else if (!hasQuery && filters.role != null)
            {
                allusers.filter { filters.role == it.role }.toTypedArray()
            }
            else
            {
                val query = filters.query.toLowerCase()
                allusers.filter { matchesSearch(query, it) }.toTypedArray()
            }
        }
        list.reloadData()
    }

    fun refreshed(it:UsersResponse)
    {
        allusers = it.users
        searchTextChanged(if (searching) topnavbar.searchbar.text else "")
    }

    override val numberOfItems: Int get() { return users.count() }

    override fun titleForItem(at: Int): String { return users[at].name }

    override fun detailForItem(at: Int): String { return users[at].role.title }

    override fun iconForItem(at:Int) : String { return "account_circle" }

    override fun onclickForItem(at: Int): String
    {
        val uid = users[at].id
        return "window.location.href='user.html?id=$uid'"
    }
}

class UsersFilters(document:Document,val id:String)
{
    var query : String = ""
    var role : UserRole? = null

    private val roleSelector : AllUserRolesSelector
    private val container = Div(document,id)

    fun start(changedFilter:()->Unit)
    {
        roleSelector.selectedRole = role
        roleSelector.valueChanged = { role = roleSelector.selectedRole; changedFilter.invoke() }
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
        if (!value)
        {
            roleSelector.selectedRole = role
        }
    }

    val markup : String
    get()
    {
        return """
           <div class="mdc-select edit" id="${id}_role"></div>
        """
    }

    init
    {
        container.html = markup
        roleSelector = AllUserRolesSelector(document,"${id}_role","Role")
    }
}