package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.models.editor.*
import main.kotlin.ui.*
import org.w3c.dom.*
import kotlin.browser.document
import kotlin.browser.window

private val Handbook.breadcrumbs : Array<Breadcrumb>
get()
{
    val shipId = shipId
    if (shipId != null)
        return arrayOf(
                Breadcrumb("Vessels","ships.html"),
                Breadcrumb(shipName ?: shipId,"ship.html?id=$shipId"),
                Breadcrumb(name,link)
        )
    return arrayOf(
            Breadcrumb("Handbooks","handbooks.html"),
            Breadcrumb(name,link)
    )
}

val Handbook.link : String
get()
{
    return "procedures.html?handbook=$params"
}

val Procedure.requiresOptionsImmediately : Boolean
get()
{
    if (ships.isNotEmpty() || destinations.isNotEmpty())
        return true

    val itemsWithReqs = items.filter { it.ships.isNotEmpty() || destinations.isNotEmpty() }

    if (itemsWithReqs.isNotEmpty())
        return true

    return false
}

open class ProcedureScreen(document:Document,override val handbook:Handbook,private val procedureId:String,final override val tripId:String?=null,private var mode:String?=null) : TopNavBarScreen(document,handbook.activeTab),ProcedureConfig,TableViewDelegate, ProcedureEditorDelegate, ProcedureOpenerDelegate
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Nothing

    override val content = Div(document,"page_content","flex")

    val expandButton = Button(document,"expand_button",tooltip="Expand all Procedure/Checklist steps")
    val collapseButton = Button(document,"collapse_button",tooltip="Collapse all Procedure/Checklist steps")
    val editButton = Button(document,"edit_button",tooltip="Edit current Procedure/Checklist")
    val reviseButton = Button(document,"revise_button",tooltip="Revise current Procedure/Checklist")
    val deleteButton = Button(document,"delete_button",tooltip="Delete Procedure/Checklist")
    val submitButton = Button(document, "submit_button",tooltip="Submit Procedure/Checklist for Approval")
    val approveButton = Button(document, "approve_button",tooltip="Approve Procedure/Checklist")
    val rejectButton = Button(document, "reject_button",tooltip="Reject Procedure/Checklist")
    val publishButton = Button(document, "publish_button",tooltip="Publish Procedure/Checklist to Android Devices")
    val editor = ProcedureEditor(document, handbook, this)
    val state = Div(document,"procedure_state",visibleDisplayStyle = "inline")
    val stateSummary = Div(document,"procedure_state_summary",visibleDisplayStyle = "inline")
    private val workflowButtons = Div(document,"procedure_workflow_buttons","inline")

    val breadcrumbs = Breadcrumbs(document,"page_breadcrumbs", handbook.breadcrumbs)

    val navbar = NavBar(document,"page_navbar")
    val navbarButtons = Div(document,"navbar_buttons","inline-flex")
    val requirements = ProcedureIcons(document,"procedure_requirements")

    val headers : HTMLDivElement = document.getElementById("procedure_headers") as HTMLDivElement
    val footers : HTMLDivElement = document.getElementById("procedure_footers") as HTMLDivElement

    val revisions = ProcedureRevisionsSelector(document,"procedure_revisions")

    val list = TableView(document,this,"list")

    val alert = Alert(document)

    val onTrip : Boolean = tripId != null
    private var canEditOrRevise = false
    private var canDelete = false
    private var canApproveOrReject = false
    private var isChecklist = false

    private var collapseButtonsHidden : Boolean
    get()
    {
        return collapseButton.hidden
    }
    set(newValue)
    {
        collapseButton.hidden = newValue
        editor.collapseButton.hidden = newValue
    }

    private var expandButtonsHidden : Boolean
    get()
    {
        return expandButton.hidden
    }
    set(newValue)
    {
        expandButton.hidden = newValue
        editor.expandButton.hidden = newValue
    }

    init
    {
        expandButton.onclick = { toggleExpandCollapse() }
        collapseButton.onclick = { toggleExpandCollapse() }
        editButton.onclick = { edit() }
        reviseButton.onclick = { edit() }
        deleteButton.onclick = { delete(); }

        submitButton.onclick = { submit() }
        rejectButton.onclick = { reject() }
        approveButton.onclick = { approve() }
        publishButton.onclick = { publish() }

        editor.saveButton.onclick = { save() }
        editor.deleteButton.onclick = { delete() }
        editor.addStepButton.onclick = { addStep() }
        editor.addHeaderButton.onclick = { addHeader() }
        editor.addFooterButton.onclick = { addFooter() }
        editor.settingsButton.onclick = { editor.toggleSettings() }
        editor.expandButton.onclick = { toggleExpandCollapse() }
        editor.collapseButton.onclick = { toggleExpandCollapse() }
        editor.cancelButton.onclick = { cancel() }
        editor.settings.closeButton.onclick = { editor.toggleSettings() }
        editor.requirements.closeButton.onclick = { editor.closeRequirements() }
        editor.images.closeButton.onclick = { editor.closeImages() }
        editor.choiceBlurbEditor.closeButton.onclick = { editor.closeChoiceBlurb() }

        editor.delegate = this

        editButton.hidden = true
        reviseButton.hidden = true

        collapseButtonsHidden = true
        expandButtonsHidden = false

        if (tripId == null)
            list.view.addClasses(arrayOf("mdc-list procedure"))
        else
            list.view.addClasses(arrayOf("mdc-list","mdc-list--two-line"))
    }

    override fun start()
    {
        super.start()

        revisions.delegate = this

        refresh()
    }

    private var _hasChangedSettings = false
    private var _hasChangedHeaders = false
    private var _hasChangedFooters = false
    private var _hasChangedSteps = false

    var hasChangedSettings : Boolean get() { return _hasChangedSettings } set(value) { _hasChangedSettings = value; updateOnBeforeUnload() }
    var hasChangedHeaders : Boolean get() { return _hasChangedHeaders } set(value) { _hasChangedHeaders = value; updateOnBeforeUnload() }
    var hasChangedFooters : Boolean get() { return _hasChangedFooters } set(value) { _hasChangedFooters = value; updateOnBeforeUnload() }
    var hasChangedSteps : Boolean get() { return _hasChangedSteps } set(value) { _hasChangedSteps = value; updateOnBeforeUnload() }

    val hasChanges : Boolean get() { return hasChangedSettings || hasChangedFooters || hasChangedSteps || hasChangedHeaders }

    fun updateOnBeforeUnload()
    {
        if (hasChanges)
            window.onbeforeunload = { "There are unsaved changes. Are you sure that you want to leave this page?" }
        else
            window.onbeforeunload = null
    }

    open fun refresh()
    {
        loading = true
        navbarButtons.hidden = true
        workflowButtons.hidden = true

        fun gotProcedure(procedure:Procedure,response:ProcedureResponse)
        {
            loading = false
            navbarButtons.hidden = false
            workflowButtons.hidden = false
            val canApproveOrReject = response.role == UserRole.SuperAdmin
            refreshed(procedure,response.revisions,response.canEditOrRevise,response.canDelete,canApproveOrReject)
            if (mode == "editing")
            {
                mode = null
                edit()
            }
            else if (mode == "published")
            {
                mode = null
                val thing = procedure.type.title
                showToast(response,"$thing published!")
            }
        }

        fun getOptions(procedure:Procedure,response:ProcedureResponse)
        {
            Requests.getProcedureOptions(this, handbook.id)
            {
                if (it.error == null)
                {
                    editor.options = it.options
                }
                gotProcedure(procedure,response)
            }
        }

        fun getProcedure()
        {
            Requests.getProcedure(this,handbook.id,procedureId,tripId,!handbook.published)
            {
                val procedure = it.procedure
                if (procedure != null)
                {
                    if (procedure.requiresOptionsImmediately)
                        getOptions(procedure,it)
                    else
                        gotProcedure(procedure,it)
                }
                else
                {
                    loading = false
                    content.hidden = true
                    error.hidden = false
                    error.text = "Failed to load procedure details"
                }
            }
        }

        getProcedure()
    }

    fun refreshed(data:Procedure,draftsData:Array<ProcedureCard> = arrayOf(),canEditOrRevise:Boolean=false,canDelete:Boolean=false,canApproveOrReject:Boolean=false)
    {
        this.canEditOrRevise = canEditOrRevise
        this.canDelete = canDelete
        this.canApproveOrReject = canApproveOrReject
        this.isChecklist = data.type == ProcedureType.Checklist
        procedure = data

        _hasChangedFooters = false
        _hasChangedHeaders = false
        _hasChangedSteps = false
        _hasChangedSettings = false
        updateOnBeforeUnload()

        editor.settings.update(this,data)

        reloadWorkflowButtons()

        reloadTitle()
        reloadData()

        if (draftsData.isEmpty() || (data.isDraft && data.parent == null && draftsData.count() <= 1))
        {
            revisions.hidden = true
        }
        else if (!data.isDraft && draftsData.count() == 1)
        {
            revisions.hidden = true
        }
        else
        {
            revisions.update(data, draftsData)
            revisions.hidden = false
        }
    }

    open fun reloadWorkflowButtons()
    {
        submitButton.hidden = true
        rejectButton.hidden = true
        approveButton.hidden = true
        publishButton.hidden = true

        if (creating) return

        val data = procedure ?: return

        if (data.isDraft)
        {
            when (data.state.status)
            {
                ProcedureWorkflowStatus.Unknown -> submitButton.hidden = false
                ProcedureWorkflowStatus.Approved -> {}
                ProcedureWorkflowStatus.Rejected -> submitButton.hidden = false
                ProcedureWorkflowStatus.Submitted ->
                {
                    if (canApproveOrReject)
                    {
                        approveButton.hidden = false
                        rejectButton.hidden = false
                    }
                }
            }
        }
    }

    var editing = false

    fun reloadTitle()
    {
        navbar.title = Handbook.tailorProcedureText(editor.settings.name.text,trip)
        //navbar.subTitle = procedure?.publishedMessage
    }

    override fun reloadData()
    {
        val procedure = procedure
        if (procedure != null)
        {
            editButton.hidden = true
            reviseButton.hidden = true
            deleteButton.hidden = true

            if (tripId==null)
            {
                if (canEditOrRevise)
                {
                    if (procedure.isDraft)
                        editButton.hidden = false
                    else
                        reviseButton.hidden = false
                }
                if (canDelete)
                {
                    deleteButton.hidden = false
                }
            }

            val headerEditing = if (editing) procedure.headers.filter { editor.blurb.current?.id == it.id }.map { it.id } else null
            val footerEditing = if (editing) procedure.footers.filter { editor.blurb.current?.id == it.id }.map { it.id } else null

            headers.innerHTML = BlurbsView.markup(this,procedure.headers, BlurbPlacement.Headers(editing=headerEditing))
            footers.innerHTML = BlurbsView.markup(this,procedure.footers, BlurbPlacement.Footers(editing=footerEditing))
        }
        else
        {
            headers.innerHTML = ""
            footers.innerHTML = ""
        }

        list.reloadData()

        clearBlurbs()
        reloadHeaders()
        reloadFooters()
        reloadCells()
        reloadState()
        reloadRequirements()
    }

    private fun reloadState()
    {
        val procedure = procedure
        if (creating)
        {
            state.text = ""
            state.hidden = true
            stateSummary.html = ""
        }
        else if (procedure != null && procedure.isDraft)
        {
            state.text = procedure.stateName
            state.removeClasses(arrayOf("draft","draft_submitted","draft_rejected","draft_approved","revision","revision_submitted","revision_rejected","revision_approved"))
            state.addClass(procedure.stateCss)
            state.hidden = false
            stateSummary.html = procedure.stateSummary
        }
        else if (procedure != null && tripId == null)
        {
            state.text = ""
            state.hidden = true
            stateSummary.html = procedure.stateSummary
        }
        else if (procedure != null && tripId != null && procedure.isCompleted)
        {
            state.text = ""
            state.hidden = true
            stateSummary.html = procedure.completionSummary
        }
        else
        {
            state.text = ""
            state.hidden = true
            stateSummary.html = ""
        }
    }

    private fun reloadRequirements()
    {
        val procedure = procedure ?: return
        requirements.reload(procedure,editor.options)
    }

    override fun openProcedure(card:ProcedureCard)
    {
        val procedure = procedure ?: return
        if (procedure.id == card.id) return
        pushToHtml("procedure.html?id=${card.id}&handbook=${handbook.params}")
    }

    private fun clearBlurbs()
    {
        for (bl in blurbs)
            bl.onclick = null
        blurbs.clear()
    }

    private fun clearCells()
    {
        for (cell in cells)
            cell.onclick = null
        cells.clear()
    }

    override fun changedBlurb(placement:BlurbPlacement)
    {
        when (placement)
        {
            is BlurbPlacement.Footers -> hasChangedFooters = true
            is BlurbPlacement.Headers -> hasChangedHeaders = true
            else -> hasChangedSteps = true
        }
        reloadData()
    }

    override fun changedStep()
    {
        hasChangedSteps = true
        reloadData()
    }

    override fun changedSettings()
    {
        hasChangedSettings = true
        reloadTitle()
    }

    override fun changedRequirements(data:Specificable)
    {
        when (data) {
            is Procedure -> { hasChangedSettings = true }
            is ProcedureItem -> { hasChangedSteps = true }
            is Blurb -> {
                val placement = editor.blurb.currentPlacement ?: return
                changedBlurb(placement)
            }
        }
        editor.reloadRequirements(data)
        reloadData()
    }

    override fun clickedBlurbButton(action:ProcedureEditorButtonBlurbAction)
    {
        val procedure = procedure ?: return

        fun getPlacement() : BlurbPlacement
        {
            return when(action) {
                is ProcedureEditorButtonBlurbAction.Delete -> action.placement
                is ProcedureEditorButtonBlurbAction.Down -> action.placement
                is ProcedureEditorButtonBlurbAction.Up -> action.placement
                is ProcedureEditorButtonBlurbAction.Create -> action.placement
                is ProcedureEditorButtonBlurbAction.ConvertToStepHeader -> action.placement
            }
        }

        fun getStep() : ProcedureItem?
        {
            val placement = getPlacement()
            return placement.getStep()
        }

        fun getChoice() : BlurbChoice?
        {
            val placement = getPlacement()
            return placement.getChoice()
        }

        fun getBlurbs() : MutableList<Blurb>
        {
            val placement = getPlacement()
            return placement.getBlurbs(procedure)
        }

        fun changed(placement:BlurbPlacement)
        {
            when(placement)
            {
                is BlurbPlacement.Headers -> hasChangedHeaders = true
                is BlurbPlacement.Footers -> hasChangedFooters = true
                is BlurbPlacement.Content -> hasChangedSteps = true
                is BlurbPlacement.StepHeader -> hasChangedSteps = true
                is BlurbPlacement.Choice -> hasChangedSteps = true
            }
        }

        fun changed()
        {
            when(action)
            {
                is ProcedureEditorButtonBlurbAction.Delete -> changed(action.placement)
                is ProcedureEditorButtonBlurbAction.Down -> changed(action.placement)
                is ProcedureEditorButtonBlurbAction.Up -> changed(action.placement)
                is ProcedureEditorButtonBlurbAction.Create -> changed(action.placement)
            }
        }

        when(action)
        {
            is ProcedureEditorButtonBlurbAction.Delete ->
            {
                val blurbs = getBlurbs()
                val at = blurbs.indexOfFirst { it.id == action.blurb.id }
                blurbs.removeAt(at)
                reloadData()
                if (getChoice() == null)
                    editor.showDefaults()
                else
                    editor.closeChoiceBlurb()
                changed()
            }
            is ProcedureEditorButtonBlurbAction.Down ->
            {
                val blurbs = getBlurbs()
                val at = blurbs.indexOfFirst { it.id == action.blurb.id }
                val step = blurbs.removeAt(at)
                blurbs.add(at+1,step)
                reloadData()
                val choice = getChoice()
                if (choice == null)
                    editor.editBlurb(action.blurb,action.placement,false, blurbs.atBottom(at+1))
                else
                    clickedEditChoiceBlurb(choice,step,action.placement)
                changed()
            }
            is ProcedureEditorButtonBlurbAction.Up ->
            {
                val blurbs = getBlurbs()
                val at = blurbs.indexOfFirst { it.id == action.blurb.id }
                val step = blurbs.removeAt(at)
                blurbs.add(at-1,step)
                reloadData()
                val choice = getChoice()
                if (choice == null)
                    editor.editBlurb(action.blurb,action.placement,at-1 == 0,blurbs.atBottom(at-1))
                else
                    clickedEditChoiceBlurb(choice,step,action.placement)
                changed()
            }
            is ProcedureEditorButtonBlurbAction.Create ->
            {
                val blurbs = getBlurbs()
                val text = "New ${action.placement.title}"
                val type = if (action.placement is BlurbPlacement.StepHeader) BlurbType.Note else BlurbType.Text
                val blurb = Blurb(text=text,type=type)
                blurb.id = Blurb.createId(type,text)
                blurbs.add(blurb)
                val at = blurbs.indexOfFirst { it.id == blurb.id }
                reloadData()
                editor.editBlurb(blurb,action.placement,at == 0,true)
                changed()
            }
            is ProcedureEditorButtonBlurbAction.ConvertToStepHeader ->
            {
                val step = getStep() ?: return
                val blurbs = getBlurbs()
                val at = blurbs.indexOfFirst { it.id == action.blurb.id }
                val blurb = blurbs.removeAt(at)
                if (action.stepheader)
                {
                    val atTop = step.headers.count() == 0
                    step.headers.add(blurb)
                    reloadData()
                    editor.editBlurb(blurb,BlurbPlacement.StepHeader(step, listOf(blurb.id)),atTop,true)
                    changed()
                }
                else
                {
                    val atTop = step.blurbs.count() == 0
                    step.blurbs.add(blurb)
                    reloadData()
                    editor.editBlurb(blurb,BlurbPlacement.Content(step, listOf(blurb.id)),atTop,true)
                    changed()
                }
            }
        }
    }

    private fun updateStepIndexes()
    {
        val steps = procedure?.items ?: return

        var index = 0
        for (step in steps)
        {
            step.step = index
            index += 1
        }
    }

    override fun clickedStepButton(action:ProcedureEditorButtonStepAction)
    {
        val steps = procedure?.items ?: return

        when(action)
        {
            is ProcedureEditorButtonStepAction.Delete ->
            {
                deleteStep(action.step.id)
            }
            is ProcedureEditorButtonStepAction.Down ->
            {
                val at = steps.indexOfFirst { it.id == action.step.id }
                val step = steps.removeAt(at)
                steps.add(at+1,step)
                updateStepIndexes()
                reloadData()
                editor.editStep(step,false,steps.atBottom(at+1))
                hasChangedSteps = true
            }
            is ProcedureEditorButtonStepAction.Up ->
            {
                val at = steps.indexOfFirst { it.id == action.step.id }
                val step = steps.removeAt(at)
                steps.add(at-1,step)
                updateStepIndexes()
                reloadData()
                editor.editStep(step,at-1 == 0,steps.atBottom(at-1))
                hasChangedSteps = true
            }
            is ProcedureEditorButtonStepAction.Create ->
            {
                val ids = steps.map { it.id }

                var id = "1"
                var i = 1
                while (ids.contains(id))
                {
                    i += 1
                    id = i.toString()
                }

                val step = ProcedureItem(id,"New Step")
                steps.add(step)
                val at = steps.indexOfFirst { it.id == step.id }
                updateStepIndexes()
                reloadData()
                editor.editStep(step,at == 0,true)
                hasChangedSteps = true
            }
        }
    }

    private fun deleteStep(stepId:String,confirmed:Boolean=false)
    {
        if (confirmed)
        {
            val steps = procedure?.items ?: return

            val at = steps.indexOfFirst { it.id == stepId }
            steps.removeAt(at)
            updateStepIndexes()
            reloadData()
            editor.showDefaults()
            hasChangedSteps = true
        }
        else
        {
            val title = "Delete Step"
            val msg = "Are you sure you would like to delete this step?"
            alert.open(title, msg, arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete") {
                    deleteStep(stepId,true)
                }
            }
        }
    }

    override fun clickedEditRequirements(data:Specificable)
    {
        editor.editRequirements(data)
    }

    override fun clickedEditImages(blurb:Blurb,placement:BlurbPlacement)
    {
        editor.editImages(this,blurb,placement)
    }

    override fun clickedEditChoiceBlurb(choice:BlurbChoice,blurb:Blurb,placement:BlurbPlacement)
    {
        val step = placement.getStep() ?: return
        editor.editChoiceBlurb(choice,blurb,step)
    }

    private fun reloadHeaders()
    {
        reloadBlurbs(procedure?.headers ?: listOf(),BlurbPlacement.Headers())
    }

    private fun reloadFooters()
    {
        reloadBlurbs(procedure?.footers ?: listOf(),BlurbPlacement.Footers())
    }

    private fun reloadBlurbs(blurbs:Collection<Blurb>,placement: BlurbPlacement)
    {
        val idPrefix : String = when(placement)
        {
            is BlurbPlacement.Headers -> "blurb_header"
            is BlurbPlacement.Footers -> "blurb_footer"
            is BlurbPlacement.Content -> "blurb_content"
            is BlurbPlacement.StepHeader -> "blurb_step-header"
            is BlurbPlacement.Choice -> ""
        }

        if (idPrefix.isEmpty())
            return

        for (blurb in blurbs)
        {
            if (blurb.id.isEmpty()) continue
            val div = Div(document,"${idPrefix}_${blurb.id}")
            div.onclick = { clickedBlurb(blurb,blurbs,placement) }
            this.blurbs.add(div)
        }
    }

    private fun reloadCells()
    {
        clearCells()
        textfields.clear()
        val checklist = procedure != null && procedure!!.type == ProcedureType.Checklist
        for ((at,step) in steps.withIndex())
        {
            val li = Li(document,"table-item-cell-$at")
            li.onclick = { clickedCell(at) }
            cells.add(li)

            val req = ProcedureRequirements(document,"table-item-cell-$at-requirements")
            req.reload(step,editor.options)

            for (blurb in step.blurbs)
            {
                if (blurb.type != BlurbType.InputText) continue
                if (blurb.id.isEmpty()) continue
                val field = EditText(document,blurb.id,blurb.hint ?: "")
                field.text = blurb.input ?: ""
                textfields[blurb.id] = field
            }

            if (checklist)
                ProcedureStepActions.reload(step,onTrip)

            reloadBlurbs(step.blurbs,BlurbPlacement.Content(step))
            reloadBlurbs(step.headers,BlurbPlacement.StepHeader(step))
        }
    }

    override var procedure : Procedure? = null
    val steps : MutableList<ProcedureItem> get() { return procedure?.items ?: mutableListOf() }
    var cells : MutableList<Li> = mutableListOf()
    var textfields : MutableMap<String,EditText> = mutableMapOf()
    var blurbs : MutableList<Div> = mutableListOf()

    fun cellContentIdForRow(at:Int) : String
    {
        return "table-item-content-$at"
    }

    override fun cellForRow(at:Int) : String
    {
        val step = steps[at]

        val atTop = at == 0
        val atBottom = at == steps.count() - 1

        val topLineStyle = if (atTop) "style='display:none;'" else ""
        val bottomLineStyle = if (atBottom) "style='display:none;'" else ""
        val name = Handbook.tailorProcedureText(step.name,trip)
        var details = ""
        val stepNumber = "${at + 1}"
        val cellContentId = cellContentIdForRow(at)
        val circleMarkup : String
        val stepEditing = editing && editor.step.current?.id == step.id
        val stepHeaderEditing = if (editing) step.headers.filter { editor.blurb.current?.id == it.id }.map { it.id } else null

        var contentEditing : List<String>? = null
        var choiceEditing : List<String>? = null
        if (editing)
        {
            if (editor.nav.contains(ContainerEditorPanelType.ChoiceBlurb))
            {
                val editorIds : MutableList<String> = mutableListOf()
                for (blurb in step.blurbs)
                {
                    if (blurb.type != BlurbType.Choices) continue
                    for (choice in blurb.choices)
                    {
                        editorIds.addAll(choice.blurbs.filter { editor.choiceBlurbEditor.current?.id == it.id }.map { it.id })
                    }
                }
                choiceEditing = editorIds
            }
            else
                contentEditing = step.blurbs.filter { editor.blurb.current?.id == it.id }.map { it.id }
        }

        if (step.isCompleted)
        {
            circleMarkup = if (step.isNotApplicable)
                """<span id="$cellContentId-circle" class="mdc-list-item__graphic circle-procedure-step circle-procedure-step-checked not_applicable"><i class='material-icons'>arrow_downward</i></span>"""
            else
                """<span id="$cellContentId-circle" class="mdc-list-item__graphic circle-procedure-step circle-procedure-step-checked completed"><i class='material-icons'>done</i></span>"""
            details = step.subtitle
        }
        else
        {
            circleMarkup = """<span id="$cellContentId-circle" class="mdc-list-item__graphic circle-procedure-step">$stepNumber</span>"""
            details = if (onTrip && isChecklist)
                "This step was not completed"
            else
                "   "
        }

        var btnMarkup = ""

        if (procedure != null && procedure!!.type == ProcedureType.Checklist)
        {
            btnMarkup = ProcedureStepActions.markup(step,stepEditing,onTrip)
        }

        val contentStyle = if (expanded)
        {
            "style='display:block;'"
        }
        else
        {
            "style='display:none;'"
        }

        val editingClass = if (stepEditing) " editing" else ""
        val blurbsMarkup = BlurbsView.markup(this,step.blurbs,BlurbPlacement.Content(step,editing=contentEditing,choiceEditing=choiceEditing))
        val stepHeadersMarkup = BlurbsView.markup(this,step.headers,BlurbPlacement.StepHeader(step,editing=stepHeaderEditing))

        val detailsSpan = if (details.isNotEmpty()) """<span class="mdc-list-item__secondary-text">$details</span>""" else ""
        val textMarkup = if (details.isNotEmpty()) """
            <span class="mdc-list-item__text">
                <span class="mdc-list-item__primary-text">$name</span>
                $detailsSpan
            </span>
            """ else """<span class="mdc-list-item__text">$name</span>"""

        return """
            <div class="procedure-step-content-container">
            <div class="procedure-step-content-line" $topLineStyle></div>
            $stepHeadersMarkup
            </div>
            <li class="mdc-list-item $editingClass" id="table-item-cell-$at">
                <div class="procedure-step-line-over-circle" $topLineStyle></div>
                <div class="procedure-step-line-under-circle" $bottomLineStyle></div>
                <div class="procedure-requirements-step" id="table-item-cell-$at-requirements"></div>
                $circleMarkup
                $textMarkup
            </li>
            <div class="procedure-step-content-container">
            <div class="procedure-step-content-line" $bottomLineStyle></div>
            <div id="$cellContentId" class="procedure-step-content" $contentStyle">
                $blurbsMarkup
                $btnMarkup
            </div>
            </div>
            """
    }

    override val numberOfRows: Int get() { return steps.count() }

    var expanded = false

    @Suppress("UNUSED_PARAMETER")
    fun openStep(at:Int, expand:Boolean, completed:Boolean,notApplicable:Boolean)
    {
        js("openStep(at,expand,completed,notApplicable);")
    }

    @Suppress("UNUSED_PARAMETER")
    fun toggleStep(at:Int, completed:Boolean,notApplicable:Boolean)
    {
        js("toggleStepOpen(at,completed,notApplicable);")
    }

    fun clickedCell(at:Int)
    {
        val step = steps[at]
        if (editing)
        {
            editor.toggleStep(step,at==0,steps.atBottom(at))
        }
        else
        {
            toggleStep(at, step.isCompleted,step.isNotApplicable)
        }
    }

    fun clickedBlurb(blurb:Blurb,blurbs:Collection<Blurb>,placement:BlurbPlacement)
    {
        if (editing)
        {
            val at = blurbs.indexOfFirst { it.id == blurb.id }
            editor.toggleBlurb(blurb,placement,at==0,blurbs.atBottom(at))
        }
    }

    fun toggleExpandCollapse()
    {
        if (expanded)
        {
            expandAllSteps(false)
        }
        else
        {
            expandAllSteps()
        }
    }

    fun expandAllSteps(expand:Boolean=true)
    {
        collapseButtonsHidden = !expand
        expandButtonsHidden = expand
        for ((stepIndex,step) in steps.withIndex())
        {
            openStep(stepIndex,expand,step.isCompleted,step.isNotApplicable)
        }
        expanded = expand
    }

    var wasExpanded = false

    fun setEditing(editing:Boolean)
    {
        this.editing = editing

        editor.hidden = !editing
        navbarButtons.hidden = editing
        workflowButtons.hidden = editing
        expanded = if (editing) true else wasExpanded

        collapseButtonsHidden = !expanded
        expandButtonsHidden = expanded
    }

    fun edit()
    {
        if (!canEditOrRevise) return

        val procedure = procedure ?: return
        val thing = procedure.type.title

        if (procedure.isDraft)
        {
            loading = true

            Requests.getProcedureOptions(this, handbook.id)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it, "Failed to load ${thing.toLowerCase()} options")
                }
                else
                {
                    editor.options = it.options
                    editor.settings.update(this, procedure)

                    wasExpanded = expanded

                    setEditing(true)

                    reloadData()
                }
            }
        }
        else
        {
            createDraft()
        }
    }

    fun cancel()
    {
        setEditing(false)

        reloadData()
    }

    open fun save()
    {
        save(false)
    }

    open fun save(publishing:Boolean)
    {
        val id = procedureId
        val procedure = procedure ?: return
        val thing = procedure.type.title

        val updates = updates(procedure,publishing)
        val data = updates.data
        if (data == null)
        {
            val error = updates.error ?: kNoChangesToSave

            if (error == kNoChangesToSave)
            {
                cancel()
            }

            showToast(error)
            return
        }

        loading = true

        Requests.updateProcedure(this,handbook.id,id,data)
        {
            if (it.error != null)
            {
                loading = false
                showToast(it,"Failed to change ${thing.toLowerCase()} info")
            }
            else
            {
                showToast(it,"$thing info changed!")
                setEditing(false)
                editor.showDefaults()
                refresh()
            }
        }
    }

    open fun publish()
    {
        /*
        val id = procedureId
        val procedure = procedure ?: return
        val thing = procedure.type.title

        if (hasChanges)
        {
            showToast("Unsaved changes, please save them before proceeding")
            return
        }

        loading = true

        Requests.publishProcedure(this,handbook.id,id)
        {
            if (it.error != null)
            {
                loading = false
                showToast(it,"Failed to publish ${thing.toLowerCase()}")
            }
            else
            {
                loading = false
                pushToHtml("procedure.html?handbook=${handbook.params}&id=${it.id}&mode=published")
            }
        }*/
    }

    open fun approve()
    {
        val id = procedureId
        val procedure = procedure ?: return
        val thing = procedure.type.title

        if (hasChanges)
        {
            showToast("Unsaved changes, please save them before proceeding")
            return
        }

        loading = true

        Requests.approveProcedure(this,handbook.id,id)
        {
            if (it.error != null)
            {
                loading = false
                showToast(it,"Failed to approve ${thing.toLowerCase()}")
            }
            else
            {
                loading = false
                showToast(it,"$thing approved!")
                setEditing(false)
                refresh()
            }
        }
    }

    open fun reject(comment:String?=null)
    {
        val id = procedureId
        val procedure = procedure ?: return
        val thing = procedure.type.title

        if (hasChanges)
        {
            showToast("Unsaved changes, please save them before proceeding")
            return
        }

        if (comment != null)
        {
            loading = true

            Requests.rejectProcedure(this, handbook.id, id, comment)
            {
                if (it.error != null)
                {
                    loading = false
                    showToast(it, "Failed to reject ${thing.toLowerCase()}")
                }
                else
                {
                    loading = false
                    showToast(it, "$thing rejected!")
                    setEditing(false)
                    refresh()
                }
            }
        }
        else
        {
            alert.openForInput("Reject", "Please add a comment as to the reason for the rejection", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("reject", "Reject")),"Comment")
            {
                if (it == "reject")
                {
                    val rejection = alert.inputText ?: ""
                    reject(rejection)
                }
            }
        }
    }

    open fun submit()
    {
        val id = procedureId
        val procedure = procedure ?: return
        val thing = procedure.type.title

        if (hasChanges)
        {
            showToast("Unsaved changes, please save them before proceeding")
            return
        }

        loading = true

        Requests.submitProcedure(this,handbook.id,id)
        {
            if (it.error != null)
            {
                loading = false
                showToast(it,"Failed to submit ${thing.toLowerCase()}")
            }
            else
            {
                loading = false
                showToast(it,"$thing submitted!")
                setEditing(false)
                refresh()
            }
        }
    }

    private val kNoChangesToSave = "No changes to save"

    open val creating = false

    fun updates(procedure:Procedure,publishing:Boolean) : ItemUpdates
    {
        val updates = Any().asDynamic()
        var hasChanges = false

        val allowWithoutChangeChecks = publishing || creating

        fun addIfChanged(named:String,current:String,change:String)
        {
            if (allowWithoutChangeChecks || current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:ProcedureType,change:ProcedureType)
        {
            if (allowWithoutChangeChecks || current != change)
            {
                updates[named] = change.value
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:CompletionMode,change:CompletionMode)
        {
            if (allowWithoutChangeChecks || current != change)
            {
                updates[named] = change.value
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:Boolean,change:Boolean)
        {
            if (allowWithoutChangeChecks || current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:Int,change:Int)
        {
            if (allowWithoutChangeChecks || current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        val settings = editor.settings
        addIfChanged("name",procedure.name,settings.name.text)
        addIfChanged("chapter",procedure.chapter,settings.chapter.selected?.name ?: "")
        addIfChanged("section",procedure.section ?: "",settings.section.selected?.name ?: "")
        addIfChanged("spawnable",procedure.spawnable,settings.spawnable.checked)
        addIfChanged("optional",procedure.optional,settings.optional.checked)
        addIfChanged("unsequential",procedure.unsequential,settings.unsequential.checked)
        addIfChanged("type",procedure.type,settings.type.selected)
        addIfChanged("linked",procedure.linked ?: "",settings.linked.selected?.id ?: "")
        addIfChanged("order",procedure.order,settings.order.text.toIntOrNull() ?: procedure.order)
        if (procedure.type == ProcedureType.Checklist)
            addIfChanged("completion_mode",procedure.completionMode,settings.completionMode.selected)

        if (allowWithoutChangeChecks || hasChangedSettings)
        {
            updates["ships"] = procedure.ships.toTypedArray()
            updates["destinations"] = procedure.destinations.toTypedArray()
            updates["classes"] = procedure.classes.toTypedArray()
            updates["types"] = procedure.types.toTypedArray()
            updates["regions"] = procedure.regions.toTypedArray()
            hasChanges = true
        }

        if (allowWithoutChangeChecks || hasChangedHeaders)
        {
            updates.headers = procedure.headers.map { it.toDynamic() }
            hasChanges = true
        }
        if (allowWithoutChangeChecks || hasChangedFooters)
        {
            updates.footers = procedure.footers.map { it.toDynamic() }
            hasChanges = true
        }
        if (allowWithoutChangeChecks || hasChangedSteps)
        {
            updates.items = procedure.items.map { it.toDynamic() }
            hasChanges = true
        }

        val chapter = settings.chapter.selected?.name ?: ""

        if (settings.name.text.isBlank())
            return ItemUpdates(error="Name cannot be blank")
        if (chapter.isBlank())
            return ItemUpdates(error="Chapter cannot be blank")

        if (hasChanges)
        {
            return ItemUpdates(data = updates)
        }
        return ItemUpdates(error=kNoChangesToSave)
    }

    fun delete(confirmed:Boolean=false,markForDeletion:Boolean=false)
    {
        val procedure = procedure ?: return
        val thing = procedure.type.title

        if (confirmed)
        {
            loading = true
            Requests.deleteProcedure(this, handbook.id, procedureId,markForDeletion)
            {
                if (it.error != null)
                {
                    loading = false
                    showToast(it, "Failed to delete ${thing.toLowerCase()}")
                }
                else
                {
                    if (markForDeletion)
                    {
                        showToast(it, "$thing marked for deletion!")
                        refresh()
                    }
                    else
                    {
                        showToast(it, "$thing deleted!")
                        pushToHtml(handbook.link)
                    }
                }
            }
        }
        else
        {
            val title = if (procedure.isDraft) "Delete $thing" else "Delete Published $thing"
            val msg =
            if (procedure.isDraft && procedure.parent == null)
                "Are you sure you would like to delete this ${thing.toLowerCase()}?"
            else
                "Are you sure you would like to delete this ${procedure.type.title}?\nYou can either delete the revision or mark it for deletion, which means when you publish the handbook, the ${procedure.type.title} will be removed from the handbook. Please note this will not affect any active voyages."

            var actions = arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete"))
            if (procedure.isDraft && procedure.parent != null)
                actions = arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete Revision"), AlertAction("delete_mark", "Mark for Deletion"))

            alert.open(title, msg, actions)
            {
                if (it == "delete")
                {
                    delete(true)
                }
                else if (it == "delete_mark")
                {
                    delete(confirmed=true,markForDeletion=true)
                }
            }
        }
    }

    private fun createDraft(confirmed:Boolean=false)
    {
        if (!canEditOrRevise) return

        val procedure = procedure ?: return
        val thing = procedure.type.title

        if (confirmed)
        {
            loading = true

            Requests.createProcedureRevision(this, handbook.id, procedureId)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it, "Failed to create a revision of the current ${thing.toLowerCase()}")
                }
                else
                {
                    pushToHtml("procedure.html?handbook=${handbook.params}&id=${it.id}&mode=editing")
                }
            }
        }
        else
        {
            val title = "$thing is published"
            val msg = "You cannot edit a published ${thing.toLowerCase()} directly. Would you like to create a revision of this $thing?"
            alert.open(title, msg, arrayOf(AlertAction("cancel", "Cancel"), AlertAction("create", "Create")))
            {
                if (it == "create")
                {
                    createDraft(true)
                }
            }
        }
    }

    fun addStep()
    {
        clickedStepButton(ProcedureEditorButtonStepAction.Create())
    }

    fun addHeader()
    {
        clickedBlurbButton(ProcedureEditorButtonBlurbAction.Create(BlurbPlacement.Headers()))
    }

    fun addFooter()
    {
        clickedBlurbButton(ProcedureEditorButtonBlurbAction.Create(BlurbPlacement.Footers()))
    }
}

class CreateProcedureScreen(document:Document,handbook:Handbook) : ProcedureScreen(document,handbook,"")
{
    init
    {
        editor.deleteButton.hidden = true
        navbar.title = "Create Procedure"
        submitButton.hidden = true
        rejectButton.hidden = true
        approveButton.hidden = true
        publishButton.hidden = true
        deleteButton.hidden = true
    }

    override val creating = true

    override fun refresh()
    {
        loading = false
        val proc = Procedure(id="",name="",type=ProcedureType.Procedure)
        proc.isDraft = true
        refreshed(proc,arrayOf(),true)
        deleteButton.hidden = true
        edit()
    }

    override fun save()
    {
        val procedure = procedure
        if (procedure == null)
        {
            showToast("Procedure/Checklist is missing")
            return
        }
        val updates = updates(procedure,false)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        val thing = procedure.type.title

        loading = true

        Requests.createProcedure(this,handbook.id, data)
        {
            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to create ${thing.toLowerCase()}")
            }
            else
            {
                window.onbeforeunload = null
                showToast(it,"$thing created!")
                pushToHtml(handbook.link)
            }
        }
    }
}

class ProcedureStepActions
{
    companion object
    {
        fun markup(step:ProcedureItem,editing:Boolean,onTrip:Boolean) : String
        {
            val action = step.action
            val editingClass = if (editing) " editing" else ""
            var html = """<div class="procedure-step-content-actions$editingClass">"""

            when (action.notApplicable)
            {
                NotApplicableMode.CommentOptional,NotApplicableMode.CommentNotRequired,NotApplicableMode.CommentRequired ->
                {
                    html += """<button id="procedure-step-content-action-${step.step}-na" class="procedure-step-content-action">Not Applicable</button>"""
                }
                NotApplicableMode.NotEnabled -> {}
            }

            if (onTrip)
            {
                html += if (step.isCompleted)
                {
                    if (step.isNotApplicable) "" else """<button id="procedure-step-content-action-${step.step}-past" class="procedure-step-content-action-completed">${action.past}</button>"""
                }
                else
                {
                    """<button id="procedure-step-content-action-${step.step}-verb" class="procedure-step-content-action">${action.verb}</button>"""
                }
            }
            else
            {
                html += """<button id="procedure-step-content-action-${step.step}-verb" class="procedure-step-content-action">${action.verb}</button>"""
                html += """<button id="procedure-step-content-action-${step.step}-past" class="procedure-step-content-action-completed">${action.past}</button>"""
            }
            html += "</div>"
            return html
        }

        fun reload(step:ProcedureItem,onTrip:Boolean)
        {
            val naTooltip = if (onTrip) step.notApplicableButtonTooltipForTrip else step.notApplicableButtonTooltip
            when (step.action.notApplicable)
            {
                NotApplicableMode.CommentNotRequired -> Button(document, "procedure-step-content-action-${step.step}-na", tooltip = naTooltip)
                NotApplicableMode.CommentOptional -> Button(document, "procedure-step-content-action-${step.step}-na", tooltip = naTooltip)
                NotApplicableMode.CommentRequired -> Button(document, "procedure-step-content-action-${step.step}-na", tooltip = naTooltip)
                NotApplicableMode.NotEnabled -> { }
            }

            if (onTrip)
            {
                if (step.isCompleted)
                {
                    if (!step.isNotApplicable)
                        Button(document, "procedure-step-content-action-${step.step}-past", tooltip = "This step was completed")
                }
                else
                {
                    Button(document, "procedure-step-content-action-${step.step}-verb", tooltip = "This step was not completed")
                }
            }
            else
            {
                Button(document, "procedure-step-content-action-${step.step}-verb", tooltip = "Shows up before a checklist step has been completed")
                Button(document, "procedure-step-content-action-${step.step}-past", tooltip = "Shows up after a checklist step has been completed")
            }
        }
    }
}

fun ProcedureItem.notApplicableButtonTooltipForTrip(completed:Event) : String?
{
    val comment = completed.comment ?: ""
    return when(action.notApplicable)
    {
        NotApplicableMode.NotEnabled -> ""
        NotApplicableMode.CommentNotRequired -> "Comment was not required when clicked"
        NotApplicableMode.CommentRequired -> if (comment.isNotBlank()) "Comment was compulsory when clicked: ($comment)" else "Comment was compulsory when clicked"
        NotApplicableMode.CommentOptional -> if (comment.isNotBlank()) "Comment was optional when clicked: ($comment)" else "Comment was optional when clicked"
    }
}

val ProcedureItem.notApplicableButtonTooltipForTrip : String?
get()
{
    val completed = completed.firstOrNull()
    if (completed != null && completed.tag == "n/a")
    {
        return notApplicableButtonTooltipForTrip(completed)
    }

    return when(action.notApplicable)
    {
        NotApplicableMode.NotEnabled -> null
        NotApplicableMode.CommentNotRequired -> "Comment was not required when clicked"
        NotApplicableMode.CommentRequired -> "Comment was compulsory when clicked"
        NotApplicableMode.CommentOptional -> "Comment was optional when clicked"
    }
}

val ProcedureItem.notApplicableButtonTooltip : String?
get()
{
    return when (action.notApplicable)
    {
        NotApplicableMode.CommentNotRequired -> "Comment is not required when clicked"
        NotApplicableMode.CommentOptional -> "Comment optional when clicked"
        NotApplicableMode.CommentRequired -> "Comment is compulsory when clicked"
        NotApplicableMode.NotEnabled -> null
    }
}

val Procedure.regionRequirementsTooltip : String
get()
{
    val thing = type.title.toLowerCase()
    return regionRequirementsTooltip(thing)
}

fun Procedure.shipRequirementsTooltip(provider:SpecificableInfoProvider) : String
{
    val thing = type.title.toLowerCase()
    return shipRequirementsTooltip(thing,provider)
}

fun Procedure.destinationRequirementsTooltip(provider:SpecificableInfoProvider) : String
{
    val thing = type.title.toLowerCase()
    return destinationRequirementsTooltip(thing,provider)
}

val ProcedureItem.regionRequirementsTooltip : String
get()
{
    if (regions.isEmpty()) return ""
    return regionRequirementsTooltip("Step")
}

fun ProcedureItem.shipRequirementsTooltip(provider:SpecificableInfoProvider) : String
{
    if (ships.isEmpty() && classes.isEmpty() && types.isEmpty()) return ""
    return shipRequirementsTooltip("Step",provider)
}

fun ProcedureItem.destinationRequirementsTooltip(provider:SpecificableInfoProvider) : String
{
    if (destinations.isEmpty()) return ""
    return destinationRequirementsTooltip("Step",provider)
}

val Procedure.settingsTooltip : String
get()
{
    val thing = type.title.toLowerCase()
    val otherThing = if (type == ProcedureType.Procedure) ProcedureType.Checklist.title.toLowerCase() else ProcedureType.Procedure.title.toLowerCase()

    fun settingSwitch(name:String,on:Boolean) : String
    {
        val yay = if (on) "Yes" else "No"
        return """$name: $yay<br/>"""
    }

    fun settingString(name:String,value:String?) : String
    {
        val string = value ?: return ""
        if (string.isEmpty()) return ""
        return """$name: $string<br/>"""
    }

    var settingsMarkup = """<div class="tooltip-description">"""
    settingsMarkup += settingString("Chapter",chapter)
    settingsMarkup += settingString("Section",section)
    settingsMarkup += "<br/>"

    if (type == ProcedureType.Checklist)
    {
        settingsMarkup += settingSwitch("Repeatable",spawnable)
        settingsMarkup += settingSwitch("Optional",optional)
        settingsMarkup += settingSwitch("Unsequential",unsequential)
        settingsMarkup += settingString("Completion Mode",completionMode.title)
        settingsMarkup += "<br/>"
    }

    settingsMarkup += settingSwitch("Linked to $otherThing",linked != null)
    settingsMarkup += settingString("Order number",order.toString())
    settingsMarkup += "</div>"

    return """
    <div class="tooltip-title">Settings</div><div class="tooltip-description">This $thing has the following settings:</div>
    $settingsMarkup
"""
}

val Procedure.completionSummary : String
get()
{
    val ev = completed.firstOrNull() ?: return ""
    val timestamp = ev.timestamp ?: return ""
    val thing = type.title.toLowerCase()
    val time = timestamp.displayCompleted
    var markup = """<button class="procedure-step-content-action-completed">COMPLETED</button>"""
    var msg = "This $thing was completed by ${ev.by} on $time"
    val comment = ev.comment
    if (comment != null && comment.isNotBlank())
        msg += " - '$comment'"
    markup = """$markup<span class="procedure-completion_summary">$msg</span>"""
    return markup
}

open class ProcedureRequirements(document:Document,val id:String)
{
    val container = Div(document,id)

    protected fun markup(item:Procedure) : String
    {
        return markup(item,false)
    }

    protected fun markup(item:ProcedureItem) : String
    {
        return markup(item,true)
    }

    protected open fun markup(item:Specificable,omitIfEmpty:Boolean) : String
    {
        val activeShipIcon = item.ships.isNotEmpty() || item.classes.isNotEmpty() || item.types.isNotEmpty()
        val activeRegionIcon = item.regions.isNotEmpty()
        val activeDestinationIcon = item.destinations.isNotEmpty()

        val regionMarkup = if (omitIfEmpty && !activeRegionIcon) "" else """<div id="$id-region"><i class="material-icons ${if(activeRegionIcon) "active" else ""}">${Icons.regions}</i></div>"""
        val shipMarkup = if (omitIfEmpty && !activeShipIcon) "" else """<div id="$id-ship"><i class="material-icons ${if(activeShipIcon) "active" else ""}">${Icons.ships}</i></div>"""
        val destinationMarkup = if (omitIfEmpty && !activeDestinationIcon) "" else """<div id="$id-destination"><i class="material-icons ${if(activeDestinationIcon) "active" else ""}">${Icons.destinations}</i></div>"""

        return """ 
        $regionMarkup
        $shipMarkup
        $destinationMarkup
        """
    }

    open fun reload(item:Procedure,provider:SpecificableInfoProvider)
    {
        container.html = markup(item)

        reloadTooltips(
                item.regionRequirementsTooltip,
                item.shipRequirementsTooltip(provider),
                item.destinationRequirementsTooltip(provider)
        )
    }

    fun reload(item:ProcedureItem,provider:SpecificableInfoProvider)
    {
        container.html = markup(item)

        reloadTooltips(
                item.regionRequirementsTooltip,
                item.shipRequirementsTooltip(provider),
                item.destinationRequirementsTooltip(provider)
        )
    }

    private fun reloadTooltips(region:String,ship:String,destination:String)
    {
        if (region.isNotEmpty())
            Div(document,"$id-region",tooltip=region)
        if (ship.isNotEmpty())
            Div(document,"$id-ship",tooltip=ship)
        if (destination.isNotEmpty())
            Div(document,"$id-destination",tooltip=destination)
    }
}

class ProcedureIcons(document:Document,id:String) : ProcedureRequirements(document,id)
{
    override fun markup(item:Specificable,omitIfEmpty:Boolean) : String
    {
        var html = super.markup(item,omitIfEmpty)
        html += """<div id="$id-procedure_settings"><i class="material-icons">${Icons.procedureSettings}</i></div>"""
        return html
    }

    override fun reload(item:Procedure,provider:SpecificableInfoProvider)
    {
        super.reload(item,provider)
        Div(document,"$id-procedure_settings",tooltip=item.settingsTooltip)
    }
}