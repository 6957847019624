package main.kotlin.data

data class Event(val uid:String="",val by:String="",val timestamp:UnixTime?=null,val tag:String?=null,val comment:String?=null)
{
    constructor(user:User,timestamp:UnixTime) : this(user.id,user.name,timestamp)

    constructor(dyn:dynamic) : this(dyn.uid as String,dyn.by as String,dyn.timestamp as? UnixTime,dyn.tag as? String,dyn.comment as? String)

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.uid = uid
        updates.by = by
        updates.timestamp = timestamp
        updates.tag = tag
        updates.comment = comment
        return updates
    }

    val description : String
    get()
    {
        if (timestamp != null)
        {
            return "$by at: ${timestamp.display}"
        }
        return by
    }
}
