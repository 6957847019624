package main.kotlin.models.editor

import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import kotlin.js.Date

abstract class ProcedureBasicBlurbEditor(document: Document,val id:String,override val handbook: Handbook) : ContainerEditorPanel, ProcedureRequirementsPanel
{
    override val container = Div(document, id)

    val text : EditTextRich
    val hint : EditText
    val input : EditText
    val link : ProcedureGotoEditor
    val type : BlurbTypeSelector
    val preview : HTMLDivElement
    val title : Span
    val pickImageButton : Button
    val isStepHeader : Switch

    val buttons : ProcedureEditorBlurbButtons
    override val specifics : ProcedureSpecificsEditor

    init
    {
        container.view.innerHTML = markup

        text = EditTextRich(document, handbook,"$id-blurb_text", "Text", required = true)
        hint = EditText(document, "$id-blurb_hint", "Hint")
        input = EditText(document, "$id-blurb_input", "Default Text")
        link = ProcedureGotoEditor(document, "$id-blurb_link_editor", handbook)
        type = BlurbTypeSelector(kotlin.browser.document, "$id-blurb_type", "Type", BlurbType.values())
        preview = kotlin.browser.document.getElementById("$id-blurb_preview") as HTMLDivElement
        title = Span(document,"$id-title")
        pickImageButton = Button(document, "$id-blurb_pick_image_button",tooltip="Select an existing image or upload a new one")
        isStepHeader = Switch(document,"$id-is_step_header","Step Header")

        buttons = ProcedureEditorBlurbButtons(document, id)
        specifics = ProcedureSpecificsEditor(document,id,"content")
    }

    open val choicesMarkup : String get() { return "" }

    open val markup : String
    get()
    {
        var html = """

    <div class="procedure-editing-panel-sub-title">
        <span id="$id-title">Blurb</span>
    </div>

    <div class="mdc-select edit" id="$id-blurb_type"></div>

    <div class="edit" id="$id-blurb_text"></div>

    <button class="mdc-button edit_requirements" id="$id-blurb_pick_image_button">Select Image</button>

    <div class="mdc-text-field edit" id="$id-blurb_hint"></div>

    <div class="mdc-text-field edit" id="$id-blurb_input"></div>

    <div id="$id-blurb_link_editor"></div>

    <div id="$id-is_step_header" class="edit"></div>

    <div class="header-preview" id="$id-blurb_preview"></div>

    $choicesMarkup

    <div class="buttons">

        <button class="mdc-button" id="$id-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i></button>
        <button class="mdc-button" id="$id-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i></button>
        <button class="mdc-button" id="$id-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i></button>

    </div>

    <hr class="procedure_editor"/>

    <div>

        <div class="procedure-editing-panel-sub-title">
            <span>Requirements</span>
        </div>

        <p id="$id.specifics.description" class="edit_requirements"></p>

        <button class="mdc-button edit_requirements" id="$id.specifics.edit_button">Edit Requirements</button>

    </div>

"""
        return html
    }

    override val requirementsAppliesTo : String get()
    {
        val fb = "blurb"

        val placement = currentPlacement ?: return fb

        return when (placement)
        {
            is BlurbPlacement.StepHeader -> "Step Header"
            is BlurbPlacement.Headers -> "Header"
            is BlurbPlacement.Footers -> "Footer"
            is BlurbPlacement.Content -> "Content"
            is BlurbPlacement.Choice -> "Choice Content"
        }

        return fb
    }
    override var options = ProcedureOptions()
    val procedure : Procedure? get() { return delegate?.procedure }
    var delegate : ProcedureEditorDelegate? = null

    var current : Blurb? = null
    var currentPlacement : BlurbPlacement? = null

    fun update(data: Blurb, placement: BlurbPlacement)
    {
        dettach()

        text.text = data.text
        hint.text = data.hint ?: ""
        input.text = data.input ?: ""
        link.buttonText.text = data.text
        link.field.text = data.link ?: ""

        when (placement)
        {
            is BlurbPlacement.Content -> type.setTypes(BlurbType.values())
            is BlurbPlacement.StepHeader -> type.setTypes(BlurbType.stepHeaderTypes)
            is BlurbPlacement.Choice -> type.setTypes(BlurbType.choiceTypes)
            is BlurbPlacement.Headers -> type.setTypes(BlurbType.headerTypes)
            is BlurbPlacement.Footers -> type.setTypes(BlurbType.footerTypes)
        }

        type.selected = data.type

        current = data
        currentPlacement = placement

        isStepHeader.checked = placement is BlurbPlacement.StepHeader

        text.reload(options,procedure)

        reloadTypeSpecificFields()
        reloadPreview()
        reloadRequirements(data)

        title.text = requirementsAppliesTo

        attach()
    }

    fun update(atTop:Boolean,atBottom:Boolean)
    {
        buttons.update(atTop,atBottom)
    }

    fun clear()
    {
        current = null
        currentPlacement = null
        dettach()
    }

    fun clickedEditImages()
    {
        val data = current ?: return
        val placement = currentPlacement?: return
        delegate?.clickedEditImages(data,placement)
    }

    fun clickedDelete()
    {
        val blurb = current ?: return
        val placement = currentPlacement ?: return
        delegate?.clickedBlurbButton(ProcedureEditorButtonBlurbAction.Delete(blurb, placement))
    }

    fun clickedUp()
    {
        val blurb = current ?: return
        val placement = currentPlacement ?: return
        delegate?.clickedBlurbButton(ProcedureEditorButtonBlurbAction.Up(blurb, placement))
    }

    fun clickedDown()
    {
        val blurb = current ?: return
        val placement = currentPlacement ?: return
        delegate?.clickedBlurbButton(ProcedureEditorButtonBlurbAction.Down(blurb, placement))
    }

    fun clickedEditRequirements()
    {
        val data = current ?: return
        delegate?.clickedEditRequirements(data)
    }

    fun changed()
    {
        val placement = currentPlacement ?: return
        delegate?.changedBlurb(placement)
    }

    open fun reloadTypeSpecificFields()
    {
        val data = current ?: return
        val currentType = data.type
        hint.hidden = true
        input.hidden = true
        pickImageButton.hidden = true
        text.hidden = false
        link.hidden = true
        isStepHeader.hidden = true
        when(currentType)
        {
            BlurbType.InputText -> { hint.hidden = false; input.hidden = false }
            BlurbType.Image -> { pickImageButton.hidden = false; text.hidden = true }
            BlurbType.Goto -> { text.hidden = true; link.reloadProcedures(options,delegate?.procedure); link.hidden = false }
            else -> {}
        }
    }

    fun reloadPreview()
    {
//        val data = current ?: return
//        preview.innerHTML = BlurbsView.markup(this,listOf(data),BlurbPlacement.Headers())
        preview.hidden = true
    }

    open fun attach()
    {
        pickImageButton.onclick = { clickedEditImages() }
        specifics.editButton.onclick = { clickedEditRequirements() }
        buttons.deleteButton.onclick = { clickedDelete() }
        buttons.upButton.onclick = { clickedUp() }
        buttons.downButton.onclick = { clickedDown() }

        text.textChanged = { current?.text = it; reloadPreview(); changed() }
        hint.textChanged = { current?.hint = it; reloadPreview(); changed() }
        input.textChanged = { current?.input = it; reloadPreview(); changed() }
        type.valueChanged = { current?.type = type.selected; reloadPreview(); reloadTypeSpecificFields(); changed() }

        link.field.textChanged = { current?.link = it; reloadPreview(); changed() }
        link.buttonText.textChanged = { current?.text = it; reloadPreview(); changed() }
        link.attach()
    }

    open fun dettach()
    {
        pickImageButton.onclick = null
        specifics.editButton.onclick = null
        buttons.deleteButton.onclick = null
        buttons.upButton.onclick = null
        buttons.downButton.onclick = null

        text.textChanged = null
        hint.textChanged = null
        input.textChanged = null
        type.valueChanged = null

        link.field.textChanged = null
        link.buttonText.textChanged = null
        link.dettach()
    }
}

class ProcedureBlurbChoiceEditor(document: Document,id:String,handbook: Handbook) : ProcedureBasicBlurbEditor(document,id,handbook)
{
    override val panelType : ContainerEditorPanelType get() { return ContainerEditorPanelType.ChoiceBlurb }

    val closeButton : Button

    override val markup : String
    get()
    {
        var html = super.markup
        return """

            <div class="procedure-editing-panel-overlay-title">
                <button class="mdc-button" id="$id-close_button"><i class="material-icons mdc-button__icon" aria-hidden="true">close</i></button>
                <span>Choice Content</span>
            </div>

            <div class="procedure-editing-panel-overlay-content">

            $html

            </div>

        """
    }

    init
    {
        closeButton = Button(document,"$id-close_button")
    }
}

class ProcedureBlurbEditor(document: Document,handbook: Handbook) : ProcedureBasicBlurbEditor(document,"procedure_blurb_editor",handbook),ProcedureChoicesEditorDelegate
{
    override val panelType : ContainerEditorPanelType get() { return ContainerEditorPanelType.Blurb }

    val choices : ProcedureChoicesEditor = ProcedureChoicesEditor(document,"$id-procedure_choices_editor",handbook)
    val addChoiceButton = Button(document,"$id-add_choice_button",tooltip="Add Diamond")

    override val choicesMarkup : String
    get()
    {
        return """
        <div id="$id-procedure_choices_editor">

            <div class="procedure-editing-panel-sub-title diamonds">
                <span>Diamonds</span>
                <button class="mdc-button add_diamond_button" id="$id-add_choice_button"><i class="material-icons mdc-button__icon" aria-hidden="true">add</i></button>
            </div>

            <div id="$id-procedure_choices_editor-diamonds" class="diamond-editor"></div>
        </div>
    """
    }

    override fun clickedBlurbChoice(choice: BlurbChoice, blurb: Blurb)
    {
        val placement = currentPlacement ?: return
        delegate?.clickedEditChoiceBlurb(choice,blurb,placement)
    }

    override fun changedBlurbChoice(choice:BlurbChoice)
    {
        val placement = currentPlacement ?: return
        delegate?.changedBlurb(placement)
    }

    override fun clickedDeleteChoice(choice:BlurbChoice)
    {
        val data = current ?: return
        val placement = currentPlacement ?: return
        data.choices.remove(choice)
        delegate?.changedBlurb(placement)
        choices.reload(data)
    }

    override fun clickedAddBlurbToChoice(choice:BlurbChoice)
    {
        val data = current ?: return
        val placement = currentPlacement ?: return
        val text = "New Choice Content"
        val type = BlurbType.Text
        val blurb = Blurb(text=text,type=type)
        blurb.id = Blurb.createId(type,text)
        choice.blurbs.add(blurb)
        delegate?.changedBlurb(placement)
        choices.reload(data)
    }

    override fun clickedRemoveBlurbFromChoice(choice:BlurbChoice,blurb:Blurb)
    {
        val pl = currentPlacement ?: return
        val step = pl.getStep() ?: return
        val placement = BlurbPlacement.Choice(step,choice)
        delegate?.clickedBlurbButton(ProcedureEditorButtonBlurbAction.Delete(blurb,placement))
    }

    fun clickedAddChoice()
    {
        val data = current ?: return
        val placement = currentPlacement ?: return
        data.choices.add(BlurbChoice("blurb-choice-${Date().getTime()}","New Choice"))
        delegate?.changedBlurb(placement)
        choices.reload(data)
    }

    fun changedIsStepHeader()
    {
        val data = current ?: return
        val placement = currentPlacement ?: return
        delegate?.clickedBlurbButton(ProcedureEditorButtonBlurbAction.ConvertToStepHeader(data, placement,isStepHeader.checked))
    }

    override fun reloadTypeSpecificFields()
    {
        super.reloadTypeSpecificFields()
        val data = current ?: return
        val currentType = data.type
        choices.hidden = true
        isStepHeader.hidden = !(currentPlacement?.canBeStepHeader(data) ?: false)
        text.required = true
        when(currentType)
        {
            BlurbType.Choices -> { text.required = false; choices.hidden = false; reloadChoices() }
            else -> {}
        }
    }

    fun reloadChoices()
    {
        val data = current ?: return
        choices.procedure = delegate?.procedure
        choices.options = options
        choices.reload(data)
    }

    override fun attach()
    {
        super.attach()
        isStepHeader.valueChanged = { changedIsStepHeader() }
        choices.delegate = this
        addChoiceButton.onclick = { clickedAddChoice() }
    }

    override fun dettach()
    {
        super.dettach()
        isStepHeader.valueChanged = null
        choices.delegate = null
        addChoiceButton.onclick = null
    }
}

class ProcedureEditorBlurbButtons(document: Document, containerId:String) : ProcedureEditorButtons(document,containerId)
{
    override val deleteTooltip : String get() { return "Delete Content" }
    override val upTooltip : String get() { return "Move Content Up" }
    override val downTooltip : String get() { return "Move Content Down" }
}
