package main.kotlin.comms

import main.kotlin.data.*
import main.kotlin.models.AuthScreen
import main.kotlin.utils.toSHA256
import org.w3c.dom.events.Event
import org.w3c.xhr.XMLHttpRequest
import toBase64
import kotlin.browser.window

interface CredentialProvider
{
    val id : String?
    val token : String?

    val authorizationHeader : String
    get()
    {
        val id = id ?: ""
        val token = token ?: ""
        return "Basic " + "$id:$token".toBase64()
    }
}

fun XMLHttpRequest.configure(credentials:CredentialProvider?=null)
{
    setRequestHeader("Content-Type","application/json")

    if (credentials != null)
    {
        setRequestHeader("Authorization", credentials.authorizationHeader)
    }
}

class Requests
{
    companion object
    {
        fun login(email:String,password:String,callback:(LoginResponse)->Unit)
        {
            val lr = LoginRequest(email,password.toSHA256())

            val json = JSON.stringify(lr)

            val req = XMLHttpRequest()
            req.open("POST", Config.url + "/users/login", true)
            req.configure()
            req.onloadend = fun(_:Event)
            {
                if (handleError(req){ code:Int, msg:String? -> callback.invoke(LoginResponse(error=code,msg=msg)) })
                    return

                val text = req.responseText
                println(text)

                val obj = JSON.parse<LoginResponse>(text)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = LoginResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = LoginResponse(error=0)
                callback.invoke(obj)
            }
            req.send(json)
        }

        fun resetPassword(email:String,callback:(BaseResponse)->Unit)
        {
            val pwdR = ResetPasswordRequest(email)

            val json = JSON.stringify(pwdR)

            val req = XMLHttpRequest()
            req.open("POST", Config.url + "/users/reset_password", true)
            req.configure()
            req.onloadend = fun(_:Event)
            {
                if (handleError(req){ code:Int, msg:String? -> callback.invoke(BaseResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = JSON.parse<BaseResponse>(text)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.send(json)
        }

        private fun handleError(req:XMLHttpRequest,callback:(code:Int,msg:String?) -> Unit) : Boolean
        {
            val statusCode = req.status.toInt()
            if (statusCode in 500..599)
            {
                callback(statusCode,"Something is wrong with the server :(")
                return true
            }
            return false
        }

        private fun handleError(screen:AuthScreen,req:XMLHttpRequest,callback:(code:Int,msg:String?) -> Unit) : Boolean
        {
            val statusCode = req.status.toInt()
            if (statusCode == 401)
            {
                screen.clearCredentials()
                screen.pushTo("index")
                return true
            }
            return handleError(req,callback)
        }

        fun changePassword(screen:AuthScreen,password1:String,password2:String,callback:(BaseResponse)->Unit)
        {
            when
            {
                password1 != password2 -> callback.invoke(BaseResponse(error=0,msg="Please make sure your 'Password' and your 'Confirm Password' are the same"))
                password1.isBlank() -> callback.invoke(BaseResponse(error=0,msg="Add a 'Password' and 'Confirm Password'"))
                else ->
                {
                    val obj = ChangePasswordRequest(password1.toSHA256())

                    val json = JSON.stringify(obj)

                    val req = XMLHttpRequest()
                    req.open("POST", Config.url + "/users/change_password", true)
                    req.configure(screen)
                    req.onloadend = fun(_:Event)
                    {
                        if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(BaseResponse(code,msg)) })
                            return

                        val text = req.responseText

                        val br = JSON.parse<BaseResponse>(text)

                        callback.invoke(br)
                    }
                    req.onerror = fun(_:Event)
                    {
                        val br = BaseResponse(error = 0)
                        callback.invoke(br)
                    }
                    req.ontimeout = fun(_:Event)
                    {
                        val br = BaseResponse(error = 0)
                        callback.invoke(br)
                    }
                    req.send(json)
                }
            }
        }

        fun listFeedback(screen:AuthScreen,callback:(FeedbackResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/feedback", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(FeedbackResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = FeedbackResponse.fromJson(text) ?: FeedbackResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = FeedbackResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = FeedbackResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getFeedback(screen:AuthScreen,id:String,callback:(FeedbackDetailsResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/feedback/" + id, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(FeedbackDetailsResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = FeedbackDetailsResponse.fromJson(text) ?: FeedbackDetailsResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = FeedbackDetailsResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = FeedbackDetailsResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun listUsers(screen:AuthScreen,callback:(UsersResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/users", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(UsersResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = UsersResponse.fromJson(text) ?: UsersResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = UsersResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = UsersResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getUser(screen:AuthScreen,id:String,callback:(UserResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/users/" + id, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(UserResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = UserResponse.fromJson(text) ?: UserResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = UserResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = UserResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        private fun createItem(screen:AuthScreen,url:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            val json = JSON.stringify(updates)

            val req = XMLHttpRequest()
            req.open("POST", url, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(BaseResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = JSON.parse<BaseResponse>(text)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.send(json)
        }

        fun createUser(screen:AuthScreen,updates:Any,callback:(BaseResponse)->Unit)
        {
            createItem(screen,Config.url + "/users",updates,callback)
        }

        fun createShip(screen:AuthScreen,updates:Any,callback:(BaseResponse)->Unit)
        {
            createItem(screen,Config.url + "/ships",updates,callback)
        }

        fun createInstallation(screen:AuthScreen,updates:Any,callback:(BaseResponse)->Unit)
        {
            createItem(screen,Config.url + "/installations",updates,callback)
        }

        fun createProcedure(screen:AuthScreen,handbook:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            createItem(screen,Config.url + "/procedures/" + handbook ,updates,callback)
        }

        fun createHandbook(screen:AuthScreen,updates:Any,callback:(BaseResponse)->Unit)
        {
            createItem(screen,Config.url + "/handbooks",updates,callback)
        }

        private fun updateItem(screen:AuthScreen,url:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            val json = JSON.stringify(updates)

            val req = XMLHttpRequest()
            req.open("PUT", url, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(BaseResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = JSON.parse<BaseResponse>(text)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.send(json)
        }

        fun updateUser(screen:AuthScreen,id:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            updateItem(screen,Config.url + "/users/" + id,updates,callback)
        }

        fun updateInstallation(screen:AuthScreen,id:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            updateItem(screen,Config.url + "/installations/" + id,updates,callback)
        }

        fun updateShip(screen:AuthScreen,id:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            updateItem(screen,Config.url + "/ships/" + id,updates,callback)
        }

        fun updateProcedure(screen:AuthScreen,handbook:String,id:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            updateItem(screen,Config.url + "/procedures/" + handbook + "/" + id,updates,callback)
        }

        private fun updateItem(screen:AuthScreen,url:String,callback:(IdResponse)->Unit,body:Any?=null,method:String="POST")
        {
            val req = XMLHttpRequest()
            req.open(method, url, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(IdResponse("",IdResponseCode.Normal,code,msg)) })
                    return

                val text = req.responseText

                val obj = IdResponse.fromJson(text) ?: IdResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = IdResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = IdResponse(error=0)
                callback.invoke(obj)
            }
            if (body != null)
            {
                req.send(JSON.stringify(body))
            }
            else
            {
                req.send(Any().asDynamic())
            }
        }

        fun createProcedureRevision(screen:AuthScreen,handbook:String,id:String,callback:(IdResponse)->Unit)
        {
            val url = Config.url + "/procedures/" + handbook + "/" + id + "/create_revision"
            updateItem(screen,url,callback)
        }

        fun submitProcedure(screen:AuthScreen,handbook:String,id:String,callback:(IdResponse)->Unit)
        {
            val url = Config.url + "/procedures/" + handbook + "/" + id + "/submit"
            updateItem(screen,url,callback)
        }

        fun rejectProcedure(screen:AuthScreen,handbook:String,id:String,comment:String,callback:(IdResponse)->Unit)
        {
            data class RejectionComment(val comment:String)
            val url = Config.url + "/procedures/" + handbook + "/" + id + "/reject"
            val body = RejectionComment(comment)
            updateItem(screen,url,callback,body)
        }

        fun approveProcedure(screen:AuthScreen,handbook:String,id:String,callback:(IdResponse)->Unit)
        {
            val url = Config.url + "/procedures/" + handbook + "/" + id + "/approve"
            updateItem(screen,url,callback)
        }

        fun publishProcedure(screen:AuthScreen,handbook:String,id:String,callback:(IdResponse)->Unit)
        {
            val url = Config.url + "/procedures/" + handbook + "/" + id + "/publish"
            updateItem(screen,url,callback)
        }

        fun resolveFeedback(screen:AuthScreen,id:String,callback:(IdResponse)->Unit)
        {
            val url = Config.url + "/feedback/" + id + "/resolve"
            updateItem(screen,url,callback,null,"PUT")
        }

        data class FeedbackComment(val comment:String)

        fun addCommentToFeedback(screen:AuthScreen,id:String,msg:String,callback:(FeedbackDetailsResponse)->Unit)
        {
            val url = Config.url + "/feedback/" + id + "/comments"
            val body = FeedbackComment(msg)
            val req = XMLHttpRequest()
            req.open("PUT", url, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(FeedbackDetailsResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = FeedbackDetailsResponse.fromJson(text) ?: FeedbackDetailsResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = FeedbackDetailsResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = FeedbackDetailsResponse(error=0)
                callback.invoke(obj)
            }
            req.send(JSON.stringify(body))
        }

        data class HandbookVersion(val version:String)

        fun createHandbookRevision(screen:AuthScreen,handbook:String,version:String,callback:(IdResponse)->Unit)
        {
            val url = Config.url + "/handbooks/" + handbook + "/create_revision"
            val body = HandbookVersion(version)
            updateItem(screen,url,callback,body,"PUT")
        }

        fun publishHandbook(screen:AuthScreen,handbook:String,version:String,callback:(IdResponse)->Unit)
        {
            val url = Config.url + "/handbooks/" + handbook + "/publish"
            val body = HandbookVersion(version)
            updateItem(screen,url,{
                checkTaskStatus(screen,it,callback)
            },body)
        }

        fun listShips(screen:AuthScreen,callback:(ShipsResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/ships", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(ShipsResponse(code,msg)) })
                    return

                val text = req.responseText
                val obj = ShipsResponse.fromJson(text) ?: ShipsResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = ShipsResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = ShipsResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getShip(screen:AuthScreen,id:String,callback:(ShipResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/ships/" + id, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(ShipResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = ShipResponse.fromJson(text) ?: ShipResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = ShipResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = ShipResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getShipAndActiveOperationHandbooks(screen:AuthScreen,id:String,callback:(ShipAndActiveOperationHandbooksResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/ships/$id/handbooks", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(ShipAndActiveOperationHandbooksResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = ShipAndActiveOperationHandbooksResponse.fromJson(text) ?: ShipAndActiveOperationHandbooksResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = ShipAndActiveOperationHandbooksResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = ShipAndActiveOperationHandbooksResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getShipHistory(screen:AuthScreen,id:String,callback:(ShipHistoryResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/ships/$id/operations", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(ShipHistoryResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = ShipHistoryResponse.fromJson(text) ?: ShipHistoryResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = ShipHistoryResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = ShipHistoryResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun listInstallations(screen:AuthScreen,callback:(InstallationsResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/installations", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(InstallationsResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = InstallationsResponse.fromJson(text) ?: InstallationsResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = InstallationsResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = InstallationsResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getInstallation(screen:AuthScreen,id:String,callback:(InstallationResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/installations/" + id, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(InstallationResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = InstallationResponse.fromJson(text) ?: InstallationResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = InstallationResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = InstallationResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getRegions(screen:AuthScreen,callback:(RegionsResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/regions", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(RegionsResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = RegionsResponse.fromJson(text) ?: RegionsResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = RegionsResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = RegionsResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        private fun deleteItem(screen:AuthScreen,url:String,callback:(BaseResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("DELETE", url, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(BaseResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = JSON.parse<BaseResponse>(text)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun deleteShip(screen:AuthScreen,id:String,callback:(BaseResponse)->Unit)
        {
            deleteItem(screen,Config.url + "/ships/" + id,callback)
        }

        fun deleteUser(screen:AuthScreen,id:String,callback:(BaseResponse)->Unit)
        {
            deleteItem(screen,Config.url + "/users/" + id,callback)
        }

        fun deleteFeedback(screen:AuthScreen,id:String,callback:(BaseResponse)->Unit)
        {
            deleteItem(screen,Config.url + "/feedback/" + id,callback)
        }

        fun deleteInstallation(screen:AuthScreen,id:String,callback:(BaseResponse)->Unit)
        {
            deleteItem(screen,Config.url + "/installations/" + id,callback)
        }

        fun deleteHandbook(screen:AuthScreen,id:String,callback:(BaseResponse)->Unit)
        {
            deleteItem(screen,Config.url + "/handbooks/" + id,callback)
        }

        fun deleteProcedure(screen:AuthScreen,handbook:String,id:String,markParentForDeletion:Boolean,callback:(BaseResponse)->Unit)
        {
            var url = Config.url + "/procedures/" + handbook + "/" + id
            if (markParentForDeletion)
                url += "?mark_parent_for_deletion=yes"
            deleteItem(screen,url,callback)
        }

        fun deleteImage(screen:AuthScreen,id:String,callback:(BaseResponse)->Unit)
        {
            deleteItem(screen,Config.url + "/images/" + id,callback)
        }

        fun listProcedures(screen:AuthScreen,handbook:String,tripId:String?,callback:(ProceduresResponse)->Unit)
        {
            var url = Config.url + "/procedures/" + handbook
            if (tripId != null)
                url += "/trips/$tripId"
            val req = XMLHttpRequest()
            req.open("GET", url, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(ProceduresResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = ProceduresResponse.fromJson(text,includeEmptyChapters = tripId == null) ?: ProceduresResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = ProceduresResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = ProceduresResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getProcedure(screen:AuthScreen,handbook:String,procedureId:String,tripId:String?,includeRevisions:Boolean=false,callback:(ProcedureResponse)->Unit)
        {
            val url = if (tripId != null)
            {
                Config.url + "/procedures/" + handbook + "/trips/" + tripId + "/" + procedureId
            }
            else
            {
                var args = ""
                if (includeRevisions)
                    args = "?include_revisions=yes"
                Config.url + "/procedures/" + handbook + "/" + procedureId + args
            }
            val req = XMLHttpRequest()
            req.open("GET", url, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(ProcedureResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = ProcedureResponse.fromJson(text) ?: ProcedureResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = ProcedureResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = ProcedureResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getProcedureOptions(screen:AuthScreen,handbook:String,callback:(ProcedureOptionsResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/procedures/$handbook/options", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(ProcedureOptionsResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = ProcedureOptionsResponse.fromJson(text) ?: ProcedureOptionsResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = ProcedureOptionsResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = ProcedureOptionsResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun getImages(screen:AuthScreen,callback:(ImagesResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/images", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(ImagesResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = ImagesResponse.fromJson(text) ?: ImagesResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = ImagesResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = ImagesResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun listHandbooks(screen:AuthScreen,callback:(HandbooksResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/handbooks", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(HandbooksResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = HandbooksResponse.fromJson(text) ?: HandbooksResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = HandbooksResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = HandbooksResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun duplicateHandbook(screen: AuthScreen,handbook:String,callback:(IdResponse)->Unit)
        {
            val url = Config.url + "/handbooks/" + handbook + "/duplicate"
            updateItem(screen,url,{
                checkTaskStatus(screen,it,callback)
            },null,"POST")
        }

        fun getHandbook(screen:AuthScreen,handbook:String,trip:String?,callback:(HandbookSettingsResponse)->Unit)
        {
            val url = if (trip != null) Config.url + "/handbooks/$handbook/trips/$trip" else Config.url + "/handbooks/$handbook"

            val req = XMLHttpRequest()
            req.open("GET", url , true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(HandbookSettingsResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = HandbookSettingsResponse.fromJson(text) ?: HandbookSettingsResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = HandbookSettingsResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = HandbookSettingsResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun updateHandbook(screen:AuthScreen,handbook:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            val json = JSON.stringify(updates)

            val req = XMLHttpRequest()
            req.open("PUT", Config.url + "/handbooks/" + handbook, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(BaseResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = JSON.parse<BaseResponse>(text)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.send(json)
        }

        private fun checkTaskStatus(screen: AuthScreen,response:IdResponse,callback:(IdResponse)->Unit)
        {
            if (response.error == null)
            {
                checkTaskStatusUntilDone(screen,response.id)
                {
                    if (it.error == null)
                    {
                        callback(IdResponse(it.result.id as? String ?: "",IdResponseCode.Normal,it.error,it.msg))
                    }
                    else
                    {
                        callback(IdResponse("",IdResponseCode.Normal,it.error,it.msg))
                    }
                }
            }
            else
            {
                callback(response)
            }
        }

        fun checkTaskStatus(screen: AuthScreen,id:String,callback:(TaskStatusResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/tasks/$id/status", true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(TaskStatusResponse(APITaskStatus.Failure,null,code,msg)) })
                    return

                val text = req.responseText

                val obj = TaskStatusResponse.fromJson(text) ?: TaskStatusResponse(APITaskStatus.Failure,null,0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = TaskStatusResponse(APITaskStatus.Failure,error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = TaskStatusResponse(APITaskStatus.Failure,error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        private fun checkTaskStatusUntilDone(screen: AuthScreen,id:String,callback:(TaskStatusResponse)->Unit)
        {
            console.log("Checking task status $id")
            checkTaskStatus(screen,id)
            {
                if (it.error != null)
                {
                    console.log("Checking task status failed for $id")
                    callback(it)
                }
                else
                {
                    when (it.status)
                    {
                        APITaskStatus.Failure,APITaskStatus.Success->
                        {
                            console.log("Checking task status finished with status ${it.status.title}")
                            callback(it)
                        }
                        else ->
                        {
                            console.log("Going to retry check status for $id")
                            window.setTimeout({
                                console.log("Retrying check status for $id")
                                checkTaskStatusUntilDone(screen,id,callback)
                            },5000)
                        }
                    }
                }
            }
        }

        fun getAppConfig(screen:AuthScreen,id:String,callback:(AppConfigResponse)->Unit)
        {
            val req = XMLHttpRequest()
            req.open("GET", Config.url + "/apps/" + id, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(AppConfigResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = AppConfigResponse.fromJson(text) ?: AppConfigResponse(error=0)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = AppConfigResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = AppConfigResponse(error=0)
                callback.invoke(obj)
            }
            req.send()
        }

        fun updateAppConfig(screen:AuthScreen,id:String,updates:Any,callback:(BaseResponse)->Unit)
        {
            val json = JSON.stringify(updates)

            val req = XMLHttpRequest()
            req.open("PUT", Config.url + "/apps/" + id, true)
            req.configure(screen)
            req.onloadend = fun(_:Event)
            {
                if (handleError(screen,req){ code:Int, msg:String? -> callback.invoke(BaseResponse(code,msg)) })
                    return

                val text = req.responseText

                val obj = JSON.parse<BaseResponse>(text)

                callback.invoke(obj)
            }
            req.onerror = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.ontimeout = fun(_:Event)
            {
                val obj = BaseResponse(error=0)
                callback.invoke(obj)
            }
            req.send(json)
        }
    }
}