package main.kotlin.ui

import main.kotlin.comms.Requests
import main.kotlin.models.AuthScreen
import org.w3c.dom.Document

class InstallationRegionSelector(document: Document, id:String, hint:String, mode:SelectorNotSelectedMode) : ShipRegionSelector(document,id,hint,listOf(),mode)
{
    fun start(screen: AuthScreen,started:((Boolean)->Unit)?=null)
    {
        disabled = true

        Requests.getRegions(screen)
        {
            val regions = it.regions
            if (regions.isNotEmpty())
            {
                disabled = false
                val sel = selected
                setRegions(regions.toList())
                selected = sel
                started?.invoke(true)
            }
            else
            {
                disabled = true
                started?.invoke(false)
            }
        }
    }
}
