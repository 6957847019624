package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document

class FeedbackScreen(document:Document) : TopNavBarScreen(document),TilesViewDelegate, FilterByNameModel
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Feedback

    val list = TilesView(document,this,"list","grid-feedback",empty = "No feedback added yet")

    val navTitle = Div(document,"navbar_title","inline")
    val filters = FeedbackFilters(document,"filters")

    override val searchButton = Button(document,"search_button",tooltip="Search feedback")
    override val helpTooltip : String get() { return "Help" }
    //override val helpPage : String get() { return "help.html?chapter=feedback" }

    override var searching : Boolean
    get()
    {
        return super.searching
    }
    set(value)
    {
        super.searching = value
        navTitle.hidden = searching
    }

    override fun start()
    {
        super.start()

        filters.start()
        {
            changedFilter()
        }

        loading = false

        refresh()
    }

    fun refresh()
    {
        if (ships.count() == 0)
        {
            getShips()
        }
        else
        {
            getFeedback()
        }
    }

    fun getFeedback()
    {
        loading = true
        Requests.listFeedback(this)
        {
            loading = false
            gotFeedback(it)
        }
    }

    var ships : Map<String,String> = mapOf()

    fun getShips()
    {
        loading = true
        Requests.listShips(this)
        {

            val ships : MutableMap<String,String> = mutableMapOf()
            for (ship in it.ships)
            {
                ships[ship.id] = ship.name
            }
            this.ships = ships
            getFeedback()
        }
    }

    fun gotFeedback(it: FeedbackResponse)
    {
        allfeedback = it.items
        searchTextChanged(if (searching) topnavbar.searchbar.text else "")
    }

    var allfeedback : Array<FeedbackItem> = arrayOf()
    var feedback : Array<FeedbackItem> = arrayOf()

    fun matchesSearch(text:String,item:FeedbackItem) : Boolean
    {
        val hasUser = item.by.toLowerCase().startsWith(text)
        val hasShip = ships[item.ship]?.toLowerCase()?.startsWith(text) ?: false
        val hasText = item.text.toLowerCase().startsWith(text)
        return hasUser || hasShip || hasText
    }

    override fun searchTextChanged(text:String)
    {
        filters.query = text
        changedFilter()
    }

    private fun changedFilter()
    {
        val hasQuery = filters.query.isNotEmpty()

        feedback = if (filters.status == null && !hasQuery)
            allfeedback
        else
        {
            if (hasQuery && filters.status != null)
            {
                val query = filters.query.toLowerCase()
                allfeedback.filter { filters.status == it.status && matchesSearch(query, it) }.toTypedArray()
            }
            else if (!hasQuery && filters.status != null)
            {
                allfeedback.filter { filters.status == it.status }.toTypedArray()
            }
            else
            {
                val query = filters.query.toLowerCase()
                allfeedback.filter { matchesSearch(query, it) }.toTypedArray()
            }
        }
        list.reloadData()
    }

    override val numberOfItems: Int get() { return feedback.count() }

    private val FeedbackItem.statusCss : String
    get()
    {
        return when(this.status)
        {
            FeedbackStatus.Unknown -> "unknown"
            FeedbackStatus.Submitted -> ""
            FeedbackStatus.Created -> "created"
            FeedbackStatus.Resolved -> "resolved"
        }
    }

    override fun cellForItem(at:Int) : String
    {
        val it = feedback[at]
        val onclick = "window.location.href='feedback_details.html?id=${it.id}'"
        val text = it.text
        val css = it.statusCss
        val ship = ships[it.ship] ?: it.ship
        return """
    <li class="mdc-image-list__item" onclick="$onclick">
        <div class="mdc-image-list__image">
            <div class="cell_top_bar $css"></div>
            <i class="material-icons $css">feedback</i>
            <span class="cell_text">$text</span>
            <span class="cell_timestamp">${it.timestamp.displayShort}</span>
            <span class="cell_user">${it.by}</span>
            <span class="cell_ship">$ship</span>
        </div>
    </li>"""
    }
}

class FeedbackFilters(document:Document,val id:String)
{
    var query : String = ""
    var status : FeedbackStatus? = FeedbackStatus.Submitted

    private val statusSelector : FeedbackStatusSelector
    private val container = Div(document,id)

    fun start(changedFilter:()->Unit)
    {
        statusSelector.selected = status
        statusSelector.valueChanged = { status = statusSelector.selected; changedFilter.invoke() }
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
        if (!value)
        {
            statusSelector.selected = status
        }
    }

    val markup : String
    get()
    {
        return """
       <div class="mdc-select edit" id="${id}_status"></div>
    """
    }

    init
    {
        container.html = markup
        statusSelector = FeedbackStatusSelector(document,"${id}_status","Status")
    }
}