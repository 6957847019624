package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.*
import kotlin.browser.window

open class UserScreen(document: Document,private val uid:String) : TopNavBarScreen(document)
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Users

    val breadcrumbs = Breadcrumbs(document,"page_breadcrumbs", arrayOf(Breadcrumb("Users","users.html")))

    val tkid = EditText(document,"tkid","Altera Employee Id",required=true)
    val email = EditText(document,"email","Email")
    val firstName = EditText(document,"firstName","First Name",required=true)
    val lastName = EditText(document,"lastName","Last Name",required=true)
    val pin = EditText(document,"pin","Pin",required=true)
    val role : UserRoleSelector = UserRoleSelector(document,"role","Role")

//    val vesselMaster = ShipSelector(document,"vesselMaster","Vessel Master", listOf())

    val navbar = NavBar(document,"page_navbar")
    val saveButton = Button(document,"save_button",tooltip="Save changes to this user")
    val deleteButton = Button(document,"delete_button",tooltip="Delete this user")

    val alert = Alert(document)

    init
    {
        saveButton.onclick = { save() }
        deleteButton.onclick = { delete() }

        tkid.textChanged = { if (it != user?.tkid) changed() }
        //email.textChanged = { if (it != user?.email) changed() }
        firstName.textChanged = { if (it != user?.firstName) changed() }
        lastName.textChanged = { if (it != user?.lastName) changed() }
        pin.textChanged = { if (it != user?.pin) changed() }
        role.valueChanged = { if (role.selectedRole != user?.role) changed() }
    }

    override fun start()
    {
        super.start()
        refresh()
    }

    open fun refresh()
    {
        refreshUser()

//        saveButton.hidden = false
//        loading = true
//        Requests.listShips(this)
//        {
//
//            if (it.error == null)
//            {
//                val list = it.ships.toMutableList()
//                val none = Ship(id="")
//                none.name="None"
//                list.add(0, none)
//
//                vesselMaster.setShips(list)
//                refreshUser()
//            }
//            else
//            {
//                content.hidden = true
//                error.hidden = false
//                saveButton.hidden = true
//                error.text = "Failed to load user details"
//            }
//        }
    }

    fun refreshUser()
    {
        val id = uid
        saveButton.hidden = false
        loading = true

        Requests.getUser(this,id)
        {

            loading = false
            val user = it.user
            if (user != null)
            {
                saveButton.hidden = false
                refreshed(user)
                hasChanges = false
            }
            else
            {
                content.hidden = true
                error.hidden = false
                saveButton.hidden = true
                error.text = "Failed to load user details"
            }
        }
    }

    var user : User? = null

    fun refreshed(data:User)
    {
        user = data

        navbar.title = data.name

        tkid.text = data.tkid
        firstName.text = data.firstName
        lastName.text = data.lastName
        email.text = data.email
        pin.text = data.pin

//        var selectedShip = vesselMaster.ships.find { it.id == data.shipMaster }
//        vesselMaster.selected = selectedShip ?: vesselMaster.ships.getOrNull(0)

        role.selectedRole = data.role
    }

    fun updates(user:User) : ItemUpdates
    {
        val updates = Any().asDynamic()
        var hasChanges = false

        fun addIfChanged(named:String,current:String,change:String)
        {
            if (current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:String?,change:Ship?)
        {
            val id = change?.id
            if (current == null && (id == null || id == ""))
                return

            if (current != id)
            {
                updates[named] = id
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:UserRole,change:UserRole)
        {
            if (current != change)
            {
                updates[named] = change.value
                hasChanges = true
            }
        }

        val role = role.selectedRole

        addIfChanged("tkid",user.tkid,tkid.text)
        addIfChanged("firstName",user.firstName,firstName.text)
        addIfChanged("lastName",user.lastName,lastName.text)
        addIfChanged("pin",user.pin,pin.text)
        addIfChanged("email",user.email,email.text)
        addIfChanged("role",user.role,role)
//        addIfChanged("ship_master",user.shipMaster,vesselMaster.selected)

        if (tkid.text.isBlank())
            return ItemUpdates(error="Altera Employee Id cannot be blank")
        if (firstName.text.isBlank())
            return ItemUpdates(error="First name cannot be blank")
        if (lastName.text.isBlank())
            return ItemUpdates(error="Last name cannot be blank")
        if ((user.isAdmin  || role.isAdmin) && email.text.isBlank())
            return ItemUpdates(error="Email cannot be blank for administrators")
        if (pin.text.isBlank())
            return ItemUpdates(error="PIN cannot be blank")

        if (hasChanges)
            return ItemUpdates(data=updates)
        return ItemUpdates(error="No changes to save")
    }

    open fun save()
    {
        val id = uid
        val user = user ?: return

        val updates = updates(user)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.updateUser(this,id,data){

            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to change user info")
            }
            else
            {
                showToast(it,"User info changed!")
                refresh()
                // TODO: Log out user if he changed himself to a non admin.
            }
        }
    }

    fun delete(confirmed:Boolean=false)
    {
        if (confirmed)
        {
            loading = true
            Requests.deleteUser(this,uid)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it,"Failed to delete user")
                }
                else
                {
                    showToast(it,"User deleted!")
                    pushTo("users")
                }
            }
        }
        else
        {
            alert.open("Delete User", "Are you sure you would like to delete this user?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete") {
                    delete(true)
                }
            }
        }
    }

    private fun changed()
    {
        hasChanges = true
    }

    private var _hasChanges : Boolean = false
    var hasChanges : Boolean get() { return _hasChanges } set(value) { _hasChanges = value; updateOnBeforeUnload() }

    open fun updateOnBeforeUnload()
    {
        if (hasChanges)
            window.onbeforeunload = { "There are unsaved changes. Are you sure that you want to leave this page?" }
        else
            window.onbeforeunload = null
    }
}

class CreateUserScreen(document:Document) : UserScreen(document,"")
{
    init
    {
        deleteButton.hidden = true
        role.selectedRole = UserRole.Normal
        navbar.title = "Create User"
    }

    override fun refresh()
    {
        loading = false
    }

    override fun save()
    {
        val user = User()
        val updates = updates(user)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.createUser(this,data)
        {
            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to create user")
            }
            else
            {
                showToast(it,"User created!")
                pushTo("users")
            }
        }
    }

    override fun updateOnBeforeUnload()
    {

    }
}