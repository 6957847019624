package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event

open class Selector(val document:Document,val id:String,val hint:String,private var items:Array<SelectorItem>,val required:Boolean=false,val tooltip:String?=null)
{
    private val container = Div(document,id,"inline-flex",tooltip=tooltip)
    protected var view : dynamic

    init
    {
        container.html = markup
        view = getView(id)
    }

    val markup : String
    get()
    {
        var itemsMarkup = ""

        for (item in items)
        {
            itemsMarkup += """<li class="mdc-list-item" data-value="${item.value}" id="$id-${item.id}" role="option">${item.name}</li>"""
        }

        return """
        <input type="hidden" name="enhanced-select">
        <i class="mdc-select__dropdown-icon"></i>
        <div id="$id-selected-text" class="mdc-select__selected-text" role="button" aria-haspopup="listbox" aria-labelledby="$id-label $id-selected-text">None</div>
        <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
        <ul class="mdc-list">
        $itemsMarkup
        </ul>
        </div>
        <span id="$id-label" class="mdc-floating-label mdc-floating-label--float-above">$hint</span>
        <div class="mdc-line-ripple"></div>
        """
    }

    private fun getView(id:String) : dynamic
    {
        val obj = js("""
    (function(){
        return new mdc.select.MDCSelect(document.getElementById(id));
    }())
    """)
        return obj
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
    }

    var disabled : Boolean
    get()
    {
        return view.disabled
    }
    set(value)
    {
        view.disabled = value
    }

    protected fun setSelectorItems(items:Array<SelectorItem>)
    {
        this.items = items
        container.html = markup
        view = getView(id)
    }

    private var _valueChanged :  (()->Unit)? = null
    var valueChanged : (()->Unit)?
    get()
    {
        return _valueChanged
    }
    set(value)
    {
        _valueChanged = value

        val oldcallback = cb
        if (oldcallback != null)
            view.unlisten("MDCSelect:change",oldcallback)

        cb = null

        if (value == null) return

        val callback : ((Event) -> Unit) = {
            _valueChanged?.invoke()
        }

        view.listen("MDCSelect:change",callback)

        cb = callback
    }

    private var cb : ((Event) -> Unit)? = null
}

data class SelectorItem(val id:String,val name:String,val value:String)
