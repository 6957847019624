package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.Handbook
import main.kotlin.data.User
import main.kotlin.ui.*
import org.w3c.dom.Document
import kotlin.browser.document

val Handbook.overflowButtonTitle : String
get()
{
    return "Actions for $descriptiveName"
}

class HandbooksScreen(document:Document) : TopNavBarScreen(document)
{
    val alert = Alert(document)

    private val publishedHandbooks = HandbooksTilesView(document,"published","Published",this)
    private val inProgressHandbooks = HandbooksTilesView(document,"in_progress","In Progress",this)

    private val createButton = Button(document,"create_button",tooltip="Create a new handbook")
    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=handbooks" }

    override val activeTab: TopNavBarTab get() = TopNavBarTab.Nothing

    override fun start()
    {
        super.start()

        if (!isAuthenticated)
            return

        createButton.onclick = { create() }

        refresh()
    }

    fun refresh()
    {
        loading = true

        Requests.getUser(this,id!!)
        {
            val user = it.user
            if (user != null)
            {
                refreshed(user)
            }
        }

        Requests.listHandbooks(this)
        {
            loading = false

            publishedHandbooks.handbooks = it.handbooks.filter { it.published }.toTypedArray()
            inProgressHandbooks.handbooks = it.handbooks.filter { !it.published }.toTypedArray()

            publishedHandbooks.reloadData()
            inProgressHandbooks.reloadData()
        }
    }

    fun refreshed(user: User)
    {

    }

    fun create()
    {
        pushTo("handbook")
    }
}

class HandbooksTilesView(document:Document,val id:String,val name:String,val screen:HandbooksScreen) : TilesViewDelegate
{
    val view = Div(document,id)
    val list : TilesView

    init
    {
        val html = """
            <div class="handbook-section-title">$name</div>
            <div id="list-$id"></div> 
        """

        view.html = html

        list = TilesView(document,this,"list-$id","grid-handbooks")
    }

    var handbooks : Array<Handbook> = arrayOf()

    override val numberOfItems : Int get() { return handbooks.count() }

    override fun cellForItem(at: Int): String
    {
        val handbook =  handbooks[at]
        val title = handbook.name
        val description = handbook.description
        val onclick = "window.location.href='procedures.html?handbook=${handbook.params}'"
        val icon = handbook.icon.image
        val details = if (handbook.version.isNotBlank()) "v${handbook.version}" else ""
        return """
            <li class="mdc-image-list__item" onclick="$onclick">
                <div class="mdc-image-list__image">
                    <div class="cell_icon" style="background-image:url('$icon');"></div>
                    <span class="cell_title">$title</span>
                    <span class="cell_details">$details</span>
                    <span class="cell_description">$description</span>
                    <button class="mdc-button overflow" id="handbook-${handbook.id}-overflow_btn"><i class="material-icons mdc-button__icon" aria-hidden="true">more_vert</i></button>
                </div>
            </li>
            """
    }

    data class HandbookCell(val handbook:Handbook)
    {
        val btn = Button(document,"handbook-${handbook.id}-overflow_btn",tooltip=handbook.overflowButtonTitle)

        fun clear()
        {
            btn.onclick = null
        }
    }

    var cells : MutableList<HandbookCell> = mutableListOf()

    fun reloadData()
    {
        for (cell in cells)
            cell.clear()
        cells.clear()

        list.reloadData()
        for (handbook in handbooks)
        {
            val cell = HandbookCell(handbook)
            cell.btn.onclick = { it.stopPropagation(); clickedHandbookOverflow(handbook) }
            cells.add(cell)
        }

        view.hidden = handbooks.isEmpty()
    }

    private fun clickedHandbookOverflow(handbook:Handbook)
    {
        val actions : MutableList<AlertChoice> = mutableListOf()
        actions.add(AlertChoice("duplicate","Duplicate Handbook",""))

        screen.alert.openForChoices(handbook.overflowButtonTitle,actions.toTypedArray())
        {
            when(it)
            {
                "duplicate" -> duplicate(handbook)
                else -> {}
            }
        }
    }

    private fun duplicate(handbook:Handbook)
    {
        screen.loading = true

        Requests.duplicateHandbook(screen,handbook.id)
        {
            screen.loading = false
            if (it.error == null)
            {
                screen.showToast("Handbook ${handbook.descriptiveName} duplicated!")
                screen.refresh()
            }
            else
            {
                screen.showToast(it,"Failed to duplicate handbook!")
            }
        }
    }
}
