import main.kotlin.data.BaseShip
import main.kotlin.data.Handbook
import main.kotlin.data.URL
import main.kotlin.models.*

import kotlin.browser.document
import kotlin.browser.window

fun main(args: Array<String>)
{
    val url = URL(document.URL)

    val pathname = url.lastPathComponent

    fun goto404()
    {
        window.location.href = "404.html"
    }

    when(pathname)
    {
        "index.html" -> { val screen = IndexScreen(document); screen.start() }
        "login.html" -> { val screen = LoginScreen(document); screen.start() }
        "users.html" -> { val screen = UsersScreen(document); screen.start() }
        "ships.html" -> { val screen = ShipsScreen(document); screen.start() }
        "apps.html" -> { val screen = AppsScreen(document); screen.start() }
        "feedback.html" -> { val screen = FeedbackScreen(document); screen.start() }
        "handbooks.html" -> { val screen = HandbooksScreen(document); screen.start() }
        "installations.html" -> { val screen = InstallationsScreen(document); screen.start() }
        "404.html" -> { }
        "help.html" ->
        {
            val chapter = url.searchParams["chapter"] ?: ""
            val section = url.searchParams["section"] ?: ""
            val screen = HelpScreen(document,chapter,section); screen.start()
        }
        "user.html" ->
        {
            val id = url.searchParams["id"]
            if (id == null)
            {
                val screen = CreateUserScreen(document)
                screen.start()
            }
            else
            {
                val screen = UserScreen(document, id)
                screen.start()
            }
        }
        "feedback_details.html" ->
        {
            val id = url.searchParams["id"]
            if (id == null)
            {
                goto404()
            }
            else
            {
                val screen = FeedbackDetailsScreen(document, id)
                screen.start()
            }
        }
        "ship.html" ->
        {
            val id = url.searchParams["id"]
            if (id == null)
            {
                val screen = CreateShipScreen(document)
                screen.start()
            }
            else
            {
                val screen = ShipScreen(document, id)
                screen.start()
            }
        }
        "ship_history.html" ->
        {
            val id = url.searchParams["ship"]
            if (id == null)
            {
                goto404()
            }
            else
            {
                val ship = BaseShip.from(id)
                if (ship != null)
                {
                    val screen = ShipHistoryScreen(document, ship)
                    screen.start()
                }
                else
                {
                    goto404()
                }
            }
        }
        "installation.html" ->
        {
            val id = url.searchParams["id"]
            if (id == null)
            {
                val screen = CreateInstallationScreen(document)
                screen.start()
            }
            else
            {
                val screen = InstallationScreen(document, id)
                screen.start()
            }
        }
        "procedures.html" ->
        {
            val id = url.searchParams["handbook"]
            if (id == null)
            {
                goto404()
            }
            else
            {
                val handbook = Handbook.from(id)
                if (handbook == null)
                {
                    goto404(); return
                }
                val screen = ProceduresScreen(document, handbook)
                screen.start()
            }
        }
        "handbook.html" ->
        {
            val id = url.searchParams["handbook"]
            if (id == null)
            {
                val screen = CreateHandbookScreen(document)
                screen.start()
            }
            else
            {
                val handbook = Handbook.from(id)
                if (handbook == null)
                {
                    goto404(); return
                }
                val screen = HandbookScreen(document, handbook)
                screen.start()
            }
        }
        "procedure.html" ->
        {
            val handbookValue = url.searchParams["handbook"]
            if (handbookValue == null) {  goto404(); return }
            val handbook = Handbook.from(handbookValue)
            if (handbook == null) { goto404(); return }

            val id = url.searchParams["id"]
            val trip = url.searchParams["trip"]
            if (id == null && trip != null) { goto404(); return }

            if (id == null)
            {
                val screen = CreateProcedureScreen(document,handbook)
                screen.start()
            }
            else
            {
                val mode = url.searchParams["mode"]
                val screen = ProcedureScreen(document,handbook,id,trip,mode)
                screen.start()
            }
        }
        else -> { println("No route matching (${document.URL}) $pathname"); /*goto404()*/ }
    }
}
