package main.kotlin.data

import fromBase64
import toBase64
import kotlin.js.Json

external fun decodeURIComponent(encodedURI: String): String
external fun encodeURIComponent(encodedURI: String): String

open class Handbook(var id:String,var name:String,val published:Boolean,val description:String="",val icon:HandbookIcon=HandbookIcon.Teekay,val features:Array<String> = arrayOf(),val version:String="")
{
    constructor(dyn:dynamic) : this(
            dyn.id as String,
            dyn.name as String,
            isPublished(dyn.channels as Array<String>),
            dyn.description as? String ?: "",
            HandbookIcon.from(dyn.icon as? Int ?: 0),
            dyn.features as? Array<String> ?: arrayOf(),
            dyn.version as? String ?: ""
    )

    val featureFlags : Array<HandbookFeature> = features.map { HandbookFeature.from(it) }.toTypedArray()

    val descriptiveName : String
    get()
    {
        if (description.isEmpty())
            return name
        return "$name ($description)"
    }

    val normalizedId : String
    get()
    {
        if (id.startsWith("rev__"))
            return id.removePrefix("rev__")
        return id
    }

    var tripId : String? = null
    var shipId : String? = null
    var shipName : String? = null

    val params : String
    get()
    {
        val d = Any().asDynamic()
        d.id = id
        d.name = name
        d.published = published
        if (tripId != null)
            d.tripId = tripId
        if (shipId != null)
            d.shipId = shipId
        if (shipName != null)
            d.shipName = shipName
        val json = JSON.stringify(d)
        val b64 = json.toBase64()
        return encodeURIComponent(b64)
    }

    companion object
    {
        fun isPublished(channels:Array<String>) : Boolean
        {
            if (channels.count() == 1 && channels.contains("cms"))
                return false
            return true
        }

        fun from(params:String) : Handbook?
        {
            val b64 = decodeURIComponent(params)
            val json = b64.fromBase64()
            val obj = JSON.parse<Json>(json)
            val id = obj["id"] as? String ?: return null
            val name = obj["name"] as? String ?: return null
            val published = obj["published"] as? Boolean ?: return null
            val hb = Handbook(id,name,published)
            hb.tripId = obj["tripId"] as? String
            hb.shipId = obj["shipId"] as? String
            hb.shipName = obj["shipName"] as? String
            return hb
        }

        fun tailorProcedureText(text:String,trip:ShipTrip) : String
        {
            return text.replace("{{ship.name}}",trip.ship.name)
                    .replace("{{destination.name}}",trip.destination?.name ?: "")
                    .replace("{{destination.loading_system}}",trip.destination?.loadingSystem?.title ?: "")
                    .replace("{{destination.additional_info.shooting_position}}",trip.destination?.additionalInfo?.shootingPosition ?: "")
        }
    }
}

data class HandbookChapter(var name:String="",var atDestination:Boolean=false,var loadingSystem:String?=null,var visibleInTimeline:Boolean=true,var zone:String?=null)
{
    var sections : MutableList<HandbookSection> = mutableListOf()

    constructor(dyn:dynamic) : this(dyn.name as String,dyn.destination as? Boolean ?:false,dyn.loading_system as? String,dyn.visible_in_timeline as? Boolean ?: false,dyn.zone as? String)
    {
        val list = dyn.sections as? Array<Any>
        if (list != null)
        {
            for (item in list)
            {
                val section = HandbookSection(dyn=item.asDynamic())
                sections.add(section)
            }
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.name = name
        updates.zone = zone
        updates.destination = atDestination
        updates.loading_system = loadingSystem
        updates.visible_in_timeline = visibleInTimeline
        updates.sections = sections.map { it.toDynamic() }.toTypedArray()
        return updates
    }
}

data class HandbookSection(var name:String="")
{
    var header : Blurb? = null
    var conditional : ConditionalRequirements? = null

    constructor(dyn:dynamic) : this(dyn.name as String)
    {
        val hdr = dyn.header

        if (hdr != null)
        {
            header = Blurb(id="handbook-section-header",dyn=hdr)
        }

        val cond = dyn.conditional
        if (cond != null)
        {
            conditional = ConditionalRequirements(dyn=cond)
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.name = name
        val header = header
        if (header != null)
            updates.header = header.toDynamic()
        val cond = conditional
        if (cond != null)
            updates.conditional = cond.toDynamic()
        return updates
    }
}

data class ConditionalRequirements(var type:ConditionalRequirementType=ConditionalRequirementType.Always,var x:Int=0)
{
    constructor(dyn:dynamic) : this(ConditionalRequirementType.from(dyn.type as Int),dyn.x as? Int ?: 0)

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.type = type.value
        updates.x = x
        return updates
    }
}

enum class ConditionalRequirementType(val value:Int)
{
    Always(0),
    EveryXShipLoading(1);

    companion object
    {
        fun from(value:Int) : ConditionalRequirementType
        {
            return when (value)
            {
                0 -> Always
                1 -> EveryXShipLoading
                else -> Always
            }
        }
    }
}
