package main.kotlin.models

import main.kotlin.data.Response
import main.kotlin.ui.Snackbar
import kotlin.browser.document
import kotlin.browser.window

open class BaseScreen
{
    val snackbar = Snackbar(document)

    fun pushTo(name:String)
    {
        pushToHtml("$name.html")
    }

    fun pushToHtml(html:String)
    {
        window.location.href = html
    }

    fun showToast(msg:String)
    {
        snackbar.open(msg)
    }

    fun showToast(response:Response,fallbackMessage:String)
    {
        val msg = response.msg
        if (msg != null && msg.isNotEmpty())
            showToast(msg)
        else
            showToast(fallbackMessage)
    }
}